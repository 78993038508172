import { Component, OnInit } from '@angular/core';
import { AssessmentPricingService } from './../assessment-pricing.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
declare var jQuery:any;
import {Router} from '@angular/router';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import {AccessGroupService} from './../../access-group.service';

@Component({
  selector: 'app-volume-pricing',
  templateUrl: './volume-pricing.component.html',
  styleUrls: ['./volume-pricing.component.scss']
})
export class VolumePricingComponent implements OnInit {

    organization:any;
    Quant:any;
    business_type:string;
    buying_account_type:string;
    quantity:any;
    suggested_price:any;
    facilitator_price:any;
    total_price:any;
    pdt_price:any;
    client_price:any;
    modal1:any;
    modal2:any;
    prior_year_vol:any;
    current_year_vol:any;
    org_index:any;
    client_discount:any;
    retail_discount:any;
    org_tier:any;
    business:any;
    facilitator_current:any;
    facilitator_prior:any;
    OrgName:any;
    organizations:any;
    client_tier:any;
    facilitator_tier:any;
    OrgType:any;
    modal3:any;
    assessment:string;
    language:string;
    guide:string;
    Cart:any;
    variants:any;
    meta:any;
    curPage:number;
    item:any;
    pageLen:number;
    count:number;
    isLoading:boolean;
    itemsByPage:number;
    product:any;
    org_name:string;
    tier_details:boolean;
    account_type:any;
    showOrgType:string='';
    query_string:string;
    url:any;
    Video: number = 0;
    env:any;
    store : any;
    productprice:number;
    suggPrice:any;           // suggest price increase by $14 
    unitPrice:any;
    totalPrice:any;
    details:any;
    img_url:any;
    popup:boolean = false;

    constructor(public accessgroup:AccessGroupService, private sanitizer: DomSanitizer, public price: AssessmentPricingService, public http: HttpClient, public modalService:NgbModal, public router: Router) { }

    ngOnInit() {
        this.store = localStorage.getItem('store');
        jQuery(document).ready(function(){
            $(document).on("wheel", "input[type=number]", function (e) {
                $(this).blur();
            });
        });

        this.env = environment;
        this.itemsByPage = 10;
        this.isLoading = true;
        this.org_name = '';
        this.tier_details = false;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/wZuL1ZticxE?modestbranding=1&amp;rel=0&amp;showinfo=0;autoplay=1');
        this.http.get(`${environment.assessment_admin}api/v1/organizations/volumes?per_page=10`).subscribe(data => {
            console.log('success', data );
            console.log(data['organizations']);
            this.organizations = data['organizations'];
            this.meta = data['meta'];
            this.count = data['meta'].total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.isLoading = false;
            this.item = [];
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            if(this.count > this.itemsByPage){
                this.render( this.curPage, this.item, true );
                console.log(data);
            }
        },
            error => {
                console.log('oops', error);
                this.showError(error);
            }
        );

        //products api
        this.http.get(`${this.env.assessment_admin}api/v1/public/products.json`).subscribe(data => {
            this.details = data['products'];
            this.img_url = this.details[0]?.image_url;
            console.log(this.details)
        })

        this.http.get(`${environment.assessment_admin}api/v1/products/${environment.assessment_pdt_id}`).subscribe(data => {
            console.log(data);
            this.productprice = data['product']['price_range'];
            console.log(this.productprice);
            this.product = data['product'];
            this.variants = data['product']['variants'];
            console.log(this.variants);
        },
            error => {
                console.log('oops', error);
                this.showError(error);
            }
        );

        this.http.get(`${environment.assessment_admin}api/v1/carts`).subscribe(data => {
            this.Cart = data['order']['line_items'];
            if(data['order']['is_buyer'] == false){
                this.router.navigate([`/custom-store/product-details/${environment.assessment_pdt_id}`]);
            }
            console.log(data);
        },
            error => {
                console.log('oops', error);
                this.showError(error);
            }
        );

        // this.organizations = this.orgs.organizations;
        this.organization = '';
        this.business_type = '';
        this.quantity = [];
        this.facilitator_price = [];
        this.total_price = [];
        this.suggested_price = [];
        this.client_discount = [];
        this.assessment = '';
        this.language = '';
        this.guide = '';

    }

      public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
            // first 2 pages
            return true;
        }
        if (index >= maxPages - 2) {
            // last 2 pages
            return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
            return true;
        }
    }

    public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder');
        if(this.meta.total_count > this.itemsByPage){
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.organizations = [];
                    this.isLoading = true;
                    var query = `${environment.assessment_admin}api/v1/organizations/volumes?per_page=${this.itemsByPage}&page=${page_number}`;
                    if(this.org_name){
                        query = query + `&name=${this.org_name}`;
                    }
                    if(this.showOrgType){
                        query = query + `&org_type=${this.showOrgType}`;
                    }
                    this.http.get(query).subscribe(data => {
                        this.organizations = data['organizations'];
                        this.meta = data['meta'];
                        this.count = this.meta.total_count;
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        // this.filterOrgs();
                        // Empty Pricing
                        this.quantity = [];
                        this.facilitator_price = [];
                        this.total_price = [];
                        this.suggested_price = [];
                        this.client_discount = [];
                        this.tier_details = false;
                        
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                    error => {
                        console.log('oops', error);
                        this.showError(error);
                    }
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }

    // Calculate Price
    public getPrice(index){
        console.log(this.quantity[index])
        console.warn(this.organizations[index])
        this.business_type = this.organizations[index].organization_type;
        this.buying_account_type = this.organizations[index].buying_account_type;
        this.facilitator_current = this.organizations[index].buying_account_volume_current_year;
        this.facilitator_prior = this.organizations[index].buying_account_volume_previous_year;

        if(this.business_type == 'Govt/NGO/Edu') {
            this.business_type = 'Govt/Military';
        }

        if(this.buying_account_type == 'reseller'){
            this.prior_year_vol = this.organizations[index].count_previous_year_volume;
            this.current_year_vol = this.organizations[index].count_this_year_volume;
        }else{
            this.prior_year_vol = this.organizations[index].buying_account_volume_previous_year;
            this.current_year_vol = this.organizations[index].buying_account_volume_current_year; 
        }

        for(var j=0; j< this.quantity.length; j++){
            if(j == index){
                if(this.quantity[j] == null || this.quantity[j] == undefined){
                    this.quantity = [];
                    this.facilitator_price = [];
                    this.total_price = [];
                    this.suggested_price = [];
                    this.client_discount = [];
                    this.tier_details = false;
                }else{
                    this.tier_details = true;
                  }
            }else{
                this.quantity[j] = null;
                this.facilitator_price[j] = null;
                this.total_price[j] = null;
                this.suggested_price[j] = null;
                this.client_discount[j] = null;
                this.tier_details = false;
            }
        }

        if(this.organizations[index].organization_type != 'standard'){
            if(this.prior_year_vol > (this.current_year_vol + this.quantity[index])){
                for(var i=0; i<this.price.pricing[this.business_type][this.buying_account_type].length; i++){
                    if(this.prior_year_vol >= this.price.pricing[this.business_type][this.buying_account_type][i].min_vol && (this.prior_year_vol <= this.price.pricing[this.business_type][this.buying_account_type][i].max_vol || this.price.pricing[this.business_type][this.buying_account_type][i].max_vol == '')){
                        if(this.price.pricing[this.business_type][this.buying_account_type][i].client_sr_price){
                            this.suggested_price[index] = this.price.pricing[this.business_type][this.buying_account_type][i].client_sr_price.toFixed(2);
                            this.client_discount[index] = (100-(this.suggested_price[index]*100/this.productprice)).toFixed(2);
                            this.client_tier = this.price.pricing[this.business_type][this.buying_account_type][i].min_vol + '-' + this.price.pricing[this.business_type][this.buying_account_type][i].max_vol;
                        }
                        if(!this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost){
                             this.total_price[index] = this.price.pricing[this.business_type][this.buying_account_type][i].client_sr_price * this.quantity[index].toFixed(2);   
                        }
                    }
                }
            }else{
                console.log(this.price, this.business_type,this.buying_account_type)
                console.log(this.quantity.length, this.quantity,this.suggested_price[index],index);
                for(var i=0; i<this.price?.pricing[this.business_type][this.buying_account_type]?.length; i++){
                    if((this.current_year_vol + this.quantity[index]) >= this.price.pricing[this.business_type][this.buying_account_type][i].min_vol && ((this.current_year_vol + this.quantity[index]) <= this.price.pricing[this.business_type][this.buying_account_type][i].max_vol || this.price.pricing[this.business_type][this.buying_account_type][i].max_vol == '')){
                        if(this.price.pricing[this.business_type][this.buying_account_type][i].client_sr_price){
                            this.suggested_price[index] = this.price.pricing[this.business_type][this.buying_account_type][i].client_sr_price.toFixed(2);
                            this.client_discount[index] = (100-(this.suggested_price[index]*100/this.productprice)).toFixed(2);
                            this.client_tier = this.price.pricing[this.business_type][this.buying_account_type][i].min_vol + '-' + this.price.pricing[this.business_type][this.buying_account_type][i].max_vol;
                        }
                        if(!this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost){
                             this.total_price[index] = this.price.pricing[this.business_type][this.buying_account_type][i].client_sr_price * this.quantity[index].toFixed(2);   
                        }
                    }
                }
            }
            if(this.facilitator_prior > (this.facilitator_current + this.quantity[index])){
                for(var i=0; i<this.price.pricing[this.business_type][this.buying_account_type]?.length; i++){
                    if(this.facilitator_prior >= this.price.pricing[this.business_type][this.buying_account_type][i].min_vol && (this.facilitator_prior <= this.price.pricing[this.business_type][this.buying_account_type][i].max_vol || this.price.pricing[this.business_type][this.buying_account_type][i].max_vol == '')){
                        if(this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost){
                            this.facilitator_price[index] = this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost.toFixed(2);
                            this.total_price[index] = (this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost * this.quantity[index]).toFixed(2);
                            this.retail_discount = (100 - this.price.pricing[this.business_type][this.buying_account_type][i].retail_percent).toFixed(2);
                            this.facilitator_tier = this.price.pricing[this.business_type][this.buying_account_type][i].min_vol + '-' + this.price.pricing[this.business_type][this.buying_account_type][i].max_vol;
                        }
                    }
                }
            }else{
                console.log(this.quantity.length, this.quantity);
                for(var i=0; i<this.price.pricing[this.business_type][this.buying_account_type]?.length; i++){
                    if((this.facilitator_current + this.quantity[index]) >= this.price.pricing[this.business_type][this.buying_account_type][i].min_vol && ((this.facilitator_current + this.quantity[index]) <= this.price.pricing[this.business_type][this.buying_account_type][i].max_vol || this.price.pricing[this.business_type][this.buying_account_type][i].max_vol == '')){
                        if(this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost){
                            this.facilitator_price[index] = this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost.toFixed(2);
                            this.total_price[index] = this.price.pricing[this.business_type][this.buying_account_type][i].reseller_cost * this.quantity[index].toFixed(2);
                            this.retail_discount = (100 - this.price.pricing[this.business_type][this.buying_account_type][i].retail_percent).toFixed(2);
                            this.facilitator_tier = this.price.pricing[this.business_type][this.buying_account_type][i].min_vol + '-' + this.price.pricing[this.business_type][this.buying_account_type][i].max_vol;
                        }
                    }    
                }
            }
        }
        
        // unit price
        let discountPerc = this.retail_discount;
        let discountIndividual = this.productprice *(discountPerc/100);
        console.warn(discountIndividual);

        this.unitPrice = (this.productprice - discountIndividual).toFixed(2);
        this.totalPrice = (this.unitPrice * this.quantity[index]).toFixed(2);

        // suggested price
        let suggested_discount = this.client_discount[index];
        let suggestDisInd = this.productprice *(suggested_discount /100);
        console.warn(suggestDisInd);
        this.suggPrice = (this.productprice - suggestDisInd).toFixed(2);
        console.log(this.unitPrice + '::::'     + this.totalPrice);
    }

    public openAlgo(i,business_type,org_type,org_name){
        console.log('busisadf',business_type)
        this.org_index = i;
        this.business = business_type;
        this.account_type = org_type;
        this.OrgName = org_name;
        this.OrgType = business_type.replace(/_/g,' ');
        // this.modal1 = this.modalService.open(content, { windowClass: 'algo-details in grey' });
        setTimeout(() => {
            $('.cal-price-popup').css('display','block');
        }, 10);
        this.popup = true;
    }

    public showTier(content){
        // this.modal2 = this.modalService.open(content, { windowClass: 'in grey' });
        console.log(this.business_type,this.buying_account_type)
        $('.tierDetails-popup').css('display','block');
    }

    public gotoTier(content){
        this.org_tier = content;
    }

    public selectAssessment(content){
        this.assessment = '';
        this.language = '';
        this.guide = '';
        for(var i=0;i<this.quantity.length;i++){
            if(this.quantity[i] != '' && this.quantity[i] != null){
                if(this.quantity[i] <= 20000){
                    $('.vol-price-modal').css('display','block');
                    // this.modal3 = this.modalService.open(content, { windowClass: 'in grey' });
                }
            }

        }
        
    }

    public updatePerpage(){
        this.organizations = [];
        this.isLoading = true;
        this.http.get(`${environment.assessment_admin}api/v1/organizations/volumes?per_page=${this.itemsByPage}`).subscribe(data => {
            this.isLoading = false;
            this.quantity = [];
            this.facilitator_price = [];
            this.total_price = [];
            this.suggested_price = [];
            this.client_discount = [];
            this.tier_details = false;
            this.organizations = data['organizations'];
            this.meta = data['meta'];
            this.count = this.meta.total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            this.filterOrgs();
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );

        },
            error => {
                console.log('oops', error);
                this.showError(error);
            }
        )
    }

    public cart(assessment, language, guide){
        if(this.assessment && this.language && this.guide){
            for(var j=0; j<this.variants.length; j++){
                console.log(assessment,language, guide)
                if(assessment == this.variants[j].mode && guide == this.variants[j].interpretive_guide_mode && language == this.variants[j].language.name){
                    var id = this.variants[j].id;
                    console.log(id);
                    for(var i=0;i<this.quantity.length;i++){
                        if(this.quantity[i] != '' && this.quantity[i] != null){
                            this.Quant = this.quantity[i];
                            console.log(this.Quant);
                            var Data = {
                                variant_id: parseInt(id),
                                quantity: this.Quant,
                                organization_id: this.organizations[i].id
                            }
                            console.log(Data);
                            this.http.post(`${environment.assessment_admin}api/v1/carts/add_item`,Data).subscribe(data => {
                                console.log(data);
                                // this.modal3.close();
                                $('.vol-price-modal').css('display','none');
                                this.router.navigate(['/custom-store/cart']);
                            },
                                error => {
                                    console.log('oops', error);
                                    this.showError(error);
                                }
                            );
                        }
                    }
                }
            }            
        }
    }

    public getAssessment(){
        this.language = '';
        this.guide = '';
    }

    public getLanguage(){
        this.guide = '';
    }

    public sorting(field, order){
        this.isLoading = true;
        $('.fname-asc').toggleClass('hide');
        $('.fname-desc').toggleClass('hide');
        this.organizations = [];
        this.http.get(`${environment.assessment_admin}api/v1/organizations/volumes?per_page=${this.itemsByPage}&name_order=${order}`).subscribe(data => {
            this.isLoading = false;
            this.quantity = [];
            this.facilitator_price = [];
            this.total_price = [];
            this.suggested_price = [];
            this.client_discount = [];
            this.tier_details = false;
            this.organizations = data['organizations'];
            this.meta = data['meta'];
            this.count = this.meta.total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            this.filterOrgs();
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );

        },
            error => {
                console.log('oops', error);
                this.showError(error);
            }
        )
    }

    public length1(){
        console.log(this.org_name.length);
        console.log(this.org_name);
        if(this.org_name.length >= 3 || this.org_name.length == 0){
            this.isLoading = true;
            this.organizations = [];
            this.http.get(`${environment.assessment_admin}api/v1/organizations/volumes?per_page=${this.itemsByPage}&name=${this.org_name}`).subscribe(data => {
                this.isLoading = false;
                this.quantity = [];
                this.facilitator_price = [];
                this.total_price = [];
                this.suggested_price = [];
                this.client_discount = [];
                this.tier_details = false;
                this.organizations = data['organizations'];
                this.meta = data['meta'];
                this.count = this.meta.total_count;
                this.pageLen = this.meta.total_pages;
                this.curPage = 0;
                this.item = [];
                this.filterOrgs();
                for(var i = 1; i<=this.pageLen;i++){
                    this.item.push(i);
                }
                this.render( this.curPage, this.item, true );

            },
                error => {
                    console.log('oops', error);
                    this.showError(error);
                }
            )
        }
    }

    public resetFirstName(){
        this.org_name = '';
        this.length1();
    }

    public filterOrgs(){
        console.log(this.showOrgType);
        this.isLoading = true;
        this.organizations = [];
        this.query_string = `${environment.assessment_admin}api/v1/organizations/volumes?per_page=${this.itemsByPage}`;
        if(this.org_name){
            this.query_string = this.query_string + `&name=${this.org_name}`;
        }
        if(this.showOrgType){
            this.query_string = this.query_string + `&org_type=${this.showOrgType}`;
        }
        this.http.get(this.query_string).subscribe(data => {
            this.isLoading = false;
            this.quantity = [];
            this.facilitator_price = [];
            this.total_price = [];
            this.suggested_price = [];
            this.client_discount = [];
            this.tier_details = false;
            this.organizations = data['organizations'];
            this.meta = data['meta'];
            this.count = this.meta.total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );

        },
            error => {
                console.log('oops', error);
                this.showError(error);
            }
        )
    }
    public showError(error){
        console.log('oops', error);
        if(error.status == 500){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'Sorry!');
            }, 1000);
        }
        else if(error.status == 422){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 4000
                };
                if(!error.error.errors.base[0]){
                    this.toastr.error(error.error.errors, 'oops!');
                }else{
                    this.toastr.error(error.error.errors.base[0], 'oops!');
                }
            }, 1000);
        }
        else{
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'oops!');
            }, 1000);
        }

    }

    public video(){
        this.Video = 1;
    }
}
