import { Routes, CanActivate } from '@angular/router';

// Guard
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

// Import the authentication guard
// import { AuthGuard } from '@auth0/auth0-angular';

import {LoginComponent} from "./components/login/login.component";
import {LoginCallbackComponent} from "./components/login-callback/login-callback.component";
import {BlankLayoutComponent} from "./components/common/layouts/blankLayout.component";
import {BasicLayoutComponent} from "./components/common/layouts/basicLayout.component";
import {TopNavigationLayoutComponent} from "./components/common/layouts/topNavigationLayout.component";

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

// Components
import {CollaborationProfileComponent} from "./components/collaboration-profile/collaboration-profile.component";
import { ShareProfileComponent } from './components/share-profile/share-profile.component';
import {FsProfileComponent} from "./components/fs-profile/fs-profile.component";
import { CustomProfileComponent } from './components/custom-profile/custom-profile.component';
import {CigComponent} from "./components/cig/cig.component";
import { LearnerMapsListingComponent } from './components/learner-maps-listing/learner-maps-listing.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import {LearnerMapsComponent} from "./components/learner-maps/learner-maps.component";
import { DynamicLearnerMapComponent } from './components/learner-maps/dynamic-learner-map/dynamic-learner-map.component';
import { ToolsetComponent } from './components/learner-maps/toolset/toolset.component';
import { ToolsetGuideComponent } from './components/toolset-guide/toolset-guide.component';
import { TsCardSingleComponent } from './components/learner-maps/toolset/ts-card-single/ts-card-single.component';
import { NewPricingModelComponent } from './components/learner-maps/new-pricing-model/new-pricing-model.component';
import { NewPricingCardSingleComponent } from './components/learner-maps/new-pricing-model/new-pricing-card-single/new-pricing-card-single.component';
import { DynamicCardSingleComponent } from './components/learner-maps/dynamic-learner-map/dynamic-card-single/dynamic-card-single.component';
import { YourCertificateComponent } from './components/learner-maps/your-certificate/your-certificate.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MyBrandingComponent } from './components/my-branding/my-branding.component';
import { FoursightFrameworkComponent } from './components/foursight-framework/foursight-framework.component';
import { InnovationStationComponent } from './components/innovation-station/innovation-station.component';
import { InnovationIntroductionComponent } from './components/innovation-station/innovation-introduction/innovation-introduction.component';
import { InnovationClarifyComponent } from './components/innovation-station/innovation-clarify/innovation-clarify.component';
import { InnovationIdeateComponent } from './components/innovation-station/innovation-ideate/innovation-ideate.component';
import { InnovationDevelopComponent } from './components/innovation-station/innovation-develop/innovation-develop.component';
import { InnovationImplementerComponent } from './components/innovation-station/innovation-implementer/innovation-implementer.component';
import { ToolcardsComponent } from "./components/toolcards/toolcards.component";
import { AccountEmailsComponent } from './components/account-emails/account-emails.component';
import { AssociatedEmailsComponent } from './components/associated-emails/associated-emails.component';
import { ToolsComponent } from './components/tools/tools.component';

// Sub Components for Toolcards
import {ToolcardsIndexComponent} from "./components/toolcards/toolcards-index/toolcards-index.component";
import {ToolcardsOverviewComponent} from "./components/toolcards/toolcards-overview/toolcards-overview.component";
import {ToolcardsActionStepsComponent} from "./components/toolcards/toolcards-action-steps/toolcards-action-steps.component";
import {ToolcardsAssistersResistersComponent} from "./components/toolcards/toolcards-assisters-resisters/toolcards-assisters-resisters.component";
import {ToolcardsBrainwritingComponent} from "./components/toolcards/toolcards-brainwriting/toolcards-brainwriting.component";
import {ToolcardsCardSortComponent} from "./components/toolcards/toolcards-card-sort/toolcards-card-sort.component";
import {ToolcardsDataQuestionsComponent} from "./components/toolcards/toolcards-data-questions/toolcards-data-questions.component";
import {ToolcardsEvaluationMatrixComponent} from "./components/toolcards/toolcards-evaluation-matrix/toolcards-evaluation-matrix.component";
import {ToolcardsExcursionsComponent} from "./components/toolcards/toolcards-excursions/toolcards-excursions.component";
import {ToolcardsForcedConnectionsComponent} from "./components/toolcards/toolcards-forced-connections/toolcards-forced-connections.component";
import {ToolcardsLearningCycleComponent} from "./components/toolcards/toolcards-learning-cycle/toolcards-learning-cycle.component";
import {ToolcardsManagingRiskComponent} from "./components/toolcards/toolcards-managing-risk/toolcards-managing-risk.component";
import {ToolcardsMindMappingComponent} from "./components/toolcards/toolcards-mind-mapping/toolcards-mind-mapping.component";
import {ToolcardsPerformanceDashboardComponent} from "./components/toolcards/toolcards-performance-dashboard/toolcards-performance-dashboard.component";
import {ToolcardsPhraseChallengesComponent} from "./components/toolcards/toolcards-phrase-challenges/toolcards-phrase-challenges.component";
import {ToolcardsPraiseFirstComponent} from "./components/toolcards/toolcards-praise-first/toolcards-praise-first.component";
import {ToolcardsScamperComponent} from "./components/toolcards/toolcards-scamper/toolcards-scamper.component";
import {ToolcardsStakeholderAnalysisComponent} from "./components/toolcards/toolcards-stakeholder-analysis/toolcards-stakeholder-analysis.component";
import {ToolcardsStickBrainstormingComponent} from "./components/toolcards/toolcards-stick-brainstorming/toolcards-stick-brainstorming.component";
import {ToolcardsStoppingYouComponent} from "./components/toolcards/toolcards-stopping-you/toolcards-stopping-you.component";
import {ToolcardsStoryboardingComponent} from "./components/toolcards/toolcards-storyboarding/toolcards-storyboarding.component";
import {ToolcardsTargetingComponent} from "./components/toolcards/toolcards-targeting/toolcards-targeting.component";

import { ManageProfilesComponent } from './components/manage-profiles/manage-profiles.component';
import { MemberComponent } from './components/member/member.component';
import { AdviceComponent } from './components/advice/advice.component'
import { OrgsComponent } from './components/orgs/orgs.component'
import { OrgsIndividualComponent } from './components/orgs-individual/orgs-individual.component';
import { ManageMembersComponent } from './components/manage-members/manage-members.component';

import { TeamsComponent } from './components/teams/teams.component';
import { MyteamsIndvidualComponent } from './components/teams/myteams-indvidual/myteams-indvidual.component';
import { TeamProfileComponent } from './components/team-profile/team-profile.component';
import { SessionProfileComponent } from './components/orgs-individual/session-profile/session-profile.component';
import { TeamReportComponent } from './components/team-report/team-report.component';
import { UserComponent } from './components/user/user.component';
import { AddressesComponent } from './components/addresses/addresses.component';
import { TrainingSessionsComponent } from './components/orgs-individual/training-sessions/training-sessions.component';
import { AccountDownloadsComponent } from './components/account-downloads/account-downloads.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { OrgManageMembersComponent } from './components/org-manage-members/org-manage-members.component';
import { OrgIndividualMembersComponent } from './components/org-individual-members/org-individual-members.component';
import { OrgIndividualTeamsComponent } from './components/org-individual-teams/org-individual-teams.component';
import { OrgIndividualSessionsComponent } from './components/org-individual-sessions/org-individual-sessions.component';

import { StoreComponent } from './components/store/store.component';
import { PublicStoreComponent } from './components/store/public-store/public-store.component';
import { PublicProductDetailsComponent } from './components/store/public-store/public-product-details/public-product-details.component';
import { PublicStoreProductsComponent } from './components/store/public-store/public-store-products/public-store-products.component';
import { PublicStoreCartComponent } from './components/store/public-store/public-store-cart/public-store-cart.component';
import { StoreCartComponent } from './components/store/store-cart/store-cart.component';
import { StoreOrdersComponent } from './components/store/store-orders/store-orders.component';
import { StoreProductsComponent } from './components/store/store-products/store-products.component';
import { AcceptPaypalComponent } from './components/store/accept-paypal/accept-paypal.component';
import { StoreProductDetailsComponent } from './components/store/store-product-details/store-product-details.component';
import { OrderDetailsComponent } from './components/store/store-orders/order-details/order-details.component';
import { OrderStatusComponent } from './components/store/store-orders/order-status/order-status.component';
import { VolumePricingComponent } from './components/store/volume-pricing/volume-pricing.component';
import { OrderVerifyComponent } from './components/store/order-verify/order-verify.component';
import { AdminSingleLearnerMapComponent } from './components/admin-single-learner-map/admin-single-learner-map.component';
import { AdminLearnerMapsListComponent } from './components/admin-learner-maps-list/admin-learner-maps-list.component';

import { ImpersonateComponent } from './components/impersonate/impersonate.component';

import { ChallengesComponent } from './components/challenges/challenges.component';
import { ClarifyChallengeComponent } from './components/challenges/clarify-challenge/clarify-challenge.component';
import { IdeateChallengeComponent } from './components/challenges/ideate-challenge/ideate-challenge.component';
import { DevelopChallengeComponent } from './components/challenges/develop-challenge/develop-challenge.component';
import { ImplementChallengeComponent } from './components/challenges/implement-challenge/implement-challenge.component';
import { IntroductionChallengeComponent } from './components/challenges/introduction-challenge/introduction-challenge.component';
import { SummaryChallengeComponent } from './components/challenges/summary-challenge/summary-challenge.component';
import { WarmUpComponent } from './components/challenges/warm-up/warm-up.component';
import { AdminPdfComponent } from './components/admin-pdf/admin-pdf.component';
import { GuideFlipbookComponent } from './components/custom-profile/guide-flipbook/guide-flipbook.component';
import { CustomTpDashboardComponent } from './components/custom-profile/custom-tp-dashboard/custom-tp-dashboard.component';
import { FsRevealDashboardComponent } from './components/custom-profile/fs-reveal-dashboard/fs-reveal-dashboard.component';
import { FsSlideshowComponent } from './components/fs-slideshow/fs-slideshow.component';
import { FsAccountComponent } from './components/fs-account/fs-account.component';
import { ChallengeNavigatorPrintComponent } from './components/challenge-navigator-print/challenge-navigator-print.component';
import { CnPrintComponent } from './components/cn-print/cn-print.component';
import { CnPrintClarifyComponent } from './components/cn-print/cn-print-clarify/cn-print-clarify.component';
import { CnPrintImplementComponent } from './components/cn-print/cn-print-implement/cn-print-implement.component';
import { CnPrintDevelopComponent } from './components/cn-print/cn-print-develop/cn-print-develop.component';
import { CnPrintIdeateComponent } from './components/cn-print/cn-print-ideate/cn-print-ideate.component';
import { CnPrintIntroComponent } from './components/cn-print/cn-print-intro/cn-print-intro.component';
import { CnFrameworkComponent } from './components/cn-print/cn-framework/cn-framework.component';
import { CnPrintSummaryComponent } from './components/cn-print/cn-print-summary/cn-print-summary.component';
import { CnPrintNotesComponent } from './components/cn-print/cn-print-notes/cn-print-notes.component';
import { ToolcardsPerspectivesComponent } from './components/toolcards/toolcards-perspectives/toolcards-perspectives.component';
import { ToolcardAssumptionReversalComponent } from './components/toolcards/toolcard-assumption-reversal/toolcard-assumption-reversal.component';
import { ParticipantsViewComponent } from './components/teams/participants-view/participants-view.component';
import { MyteamsOne2oneComponent } from './components/teams/myteams-one2one/myteams-one2one.component';
import { TeamsDashboardComponent } from './components/teams-dashboard/teams-dashboard.component';
import { AllteammatesComponent } from './components/teams-dashboard/allteammates/allteammates.component';
import { ThinkingProfileIdComponent } from './components/teams/participants-view/thinking-profile-id/thinking-profile-id.component';
import { PresenterResourcesDashboardComponent } from './components/presenter-resources-dashboard/presenter-resources-dashboard.component';
import { PrCertificationComponent } from './components/presenter-resources-dashboard/pr-certification/pr-certification.component';
import { PrTrainingComponent } from './components/presenter-resources-dashboard/pr-training/pr-training.component';
import { PrAdditionalResourcesComponent } from './components/presenter-resources-dashboard/pr-additional-resources/pr-additional-resources.component';
import { PrVideoBasedWorkshopsComponent } from './components/presenter-resources-dashboard/pr-video-based-workshops/pr-video-based-workshops.component';

export const ROUTES:Routes = [
    // Main redirect
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},

    // App views
    {
        path: '', component: BasicLayoutComponent, canActivate: [AuthGuard],
        children: [
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
            { path: 'admin-pdf', component: AdminPdfComponent, canActivate: [AuthGuard]},
            { path: 'branding', component: MyBrandingComponent, canActivate: [AuthGuard]},
            { path: 'learner-maps-admin', component: AdminLearnerMapsListComponent, canActivate: [AuthGuard]},
            { path: 'learner-maps-admin/:id', component: AdminSingleLearnerMapComponent, canActivate: [AuthGuard]},
            { path: 'user' , component: UserComponent, canActivate: [AuthGuard]},
            { path: 'manage-profiles', component: ManageProfilesComponent, canActivate: [AuthGuard] },
            { path: 'account-downloads', component: AccountDownloadsComponent, canActivate: [AuthGuard] },
            { path: 'certificate', component: CertificateComponent, canActivate: [AuthGuard] },
            { path: 'collaboration-profile', component: CollaborationProfileComponent, canActivate: [AuthGuard] },
            { path: 'share-profile', component: ShareProfileComponent, canActivate: [AuthGuard] },
            { path: 'fs-profile/:id', component: FsProfileComponent, canActivate: [AuthGuard] },
            { path: 'fs-profiles', component: FsProfileComponent, canActivate: [AuthGuard] },
            { path: 'profile-slideshow', component: FsSlideshowComponent },
            { path: 'fs-profile', component: CustomTpDashboardComponent },
            { path: 'fs-reveal', component: FsRevealDashboardComponent},
            // { path: 'fs-reveal/:id/card/:id', component: DynamicCardSingleComponent, canActivate: [AuthGuard] },
            { path: 'fs-reveal/:id', component: FsSlideshowComponent,
                children: [
                    { path: 'card/:id', component: DynamicCardSingleComponent, canActivate: [AuthGuard] }
                ] 
            },
            { path: 'interpretative-guide', component: GuideFlipbookComponent},
            { path: 'my-account', component: FsAccountComponent, canActivate: [AuthGuard]},
            { path: 'fs-admin', component: FsAccountComponent, canActivate: [AuthGuard]},
            { path: 'teams-admin', component: FsAccountComponent, canActivate: [AuthGuard]},
            { path: 'profiles', component: AccountEmailsComponent, canActivate: [AuthGuard] },
            { path: 'settings', component: AssociatedEmailsComponent, canActivate: [AuthGuard] },
            { path: 'challenges', component: ChallengesComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'introduction', pathMatch: 'full' },
                    { path: 'introduction', component: IntroductionChallengeComponent, canActivate: [AuthGuard] },
                    { path: 'clarify', component: ClarifyChallengeComponent, canActivate: [AuthGuard] },
                    { path: 'ideate', component: IdeateChallengeComponent, canActivate: [AuthGuard] },
                    { path: 'develop', component: DevelopChallengeComponent, canActivate: [AuthGuard] },
                    { path: 'implementer', component: ImplementChallengeComponent, canActivate: [AuthGuard] },
                    { path: 'summary', component: SummaryChallengeComponent, canActivate: [AuthGuard] },
                    { path: 'warm-up', component: WarmUpComponent, canActivate: [AuthGuard] }
                 ]
            },
            { path: 'presenter-resources', component: PresenterResourcesDashboardComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', component: PresenterResourcesDashboardComponent, canActivate: [AuthGuard] },
                    { path: 'certification', component: PrCertificationComponent, canActivate: [AuthGuard],
                        children: [
                            { path: '', component: PrCertificationComponent, canActivate: [AuthGuard] },
                            { path: 'reveal', component: PrCertificationComponent, canActivate: [AuthGuard] },
                            { path: 'team-insight', component: PrCertificationComponent, canActivate: [AuthGuard] },
                            { path: 'ch-sprint', component: PrCertificationComponent, canActivate: [AuthGuard] },
                        ]
                     },
                    { path: 'training', component: PrTrainingComponent, canActivate: [AuthGuard] },
                    { path: 'video-based-workshops', component: PrVideoBasedWorkshopsComponent, canActivate: [AuthGuard] },
                    { path: 'additional-resources', component: PrAdditionalResourcesComponent, canActivate: [AuthGuard],
                        children: [
                            { path: '', component: PrCertificationComponent, canActivate: [AuthGuard] },
                            { path: 'reveal', component: PrCertificationComponent, canActivate: [AuthGuard] },
                            { path: 'team-insight', component: PrCertificationComponent, canActivate: [AuthGuard] },
                            { path: 'ch-sprint', component: PrCertificationComponent, canActivate: [AuthGuard] },
                        ]
                     },
                     {path: ':id', component: DynamicLearnerMapComponent, canActivate: [AuthGuard],
                         children: [
                             { path: 'card/:id', component: DynamicCardSingleComponent, canActivate: [AuthGuard] }
                         ]
                     }
                ],
            },
            { path: 'learner-maps', component: LearnerMapsComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', component: LearnerMapsListingComponent, canActivate: [AuthGuard] },
                    { path: 'your-certificate/cmf', component: YourCertificateComponent, canActivate: [AuthGuard] },
                    { path: 'your-certificate/qmp', component: YourCertificateComponent, canActivate: [AuthGuard] },
                    { path: 'your-certificate/toolset', component: YourCertificateComponent, canActivate: [AuthGuard] },
                    { path: 'toolset', component: ToolsetComponent, canActivate: [AuthGuard],
                        children: [
                            { path: 'card/:id', component: TsCardSingleComponent, canActivate: [AuthGuard] }
                        ]
                    },
                    { path: 'new-price-organizational', component: NewPricingModelComponent, canActivate: [AuthGuard],
                        children: [
                            { path: 'card/:id', component: NewPricingCardSingleComponent, canActivate: [AuthGuard] }
                        ]
                    },
                    { path: 'new-price-independent', component: NewPricingModelComponent, canActivate: [AuthGuard],
                        children: [
                            { path: 'card/:id', component: NewPricingCardSingleComponent, canActivate: [AuthGuard] }
                        ]
                    },
                    { path: 'new-price-non-profit', component: NewPricingModelComponent, canActivate: [AuthGuard],
                        children: [
                            { path: 'card/:id', component: NewPricingCardSingleComponent, canActivate: [AuthGuard] }
                        ]
                    },
                    {path: ':id', component: DynamicLearnerMapComponent, canActivate: [AuthGuard],
                        children: [
                            { path: 'card/:id', component: DynamicCardSingleComponent, canActivate: [AuthGuard] }
                        ]
                    },  // presenter routes
                    {
                        path:'48031191-9217-4a53-8085-d206c0ebdfe9/reveal', component:  NewPricingModelComponent
                    },
                    {
                        path:'48031191-9217-4a53-8085-d206c0ebdfe9/team-insight', component:  NewPricingModelComponent
                    },
                    {
                        path:'48031191-9217-4a53-8085-d206c0ebdfe9/ch-sprint', component:  NewPricingModelComponent
                    },
                    {
                        path:'841db1c0-e266-462c-b564-10576f7a42dd/reveal', component:  NewPricingModelComponent
                    },
                    {
                        path:'841db1c0-e266-462c-b564-10576f7a42dd/team-insight', component:  NewPricingModelComponent
                    },
                    {
                        path:'841db1c0-e266-462c-b564-10576f7a42dd/ch-sprint', component:  NewPricingModelComponent
                    },
                    {
                        path:'new-price-organizational/presentation', component:  NewPricingModelComponent
                    },
                    {
                        path:'new-price-organizational/reveal', component:  NewPricingModelComponent
                    },
                    {
                        path:'new-price-organizational/team-insight', component:  NewPricingModelComponent
                    },
                    {
                        path:'new-price-organizational/ch-sprint', component:  NewPricingModelComponent
                    },
                    {
                        path:'new-price-independent/fs-u', component:  NewPricingModelComponent
                    },
                    {
                        path:'new-price-independent/research-library', component:  NewPricingModelComponent
                    },
                    {
                        path:'new-price-independent/materials', component:  NewPricingModelComponent
                    }
                ]
            },
            { path: 'tools',
                children: [
                    { path: '', component: ToolsComponent, canActivate: [AuthGuard] },
                    { path: 'foursight-framework', component: FoursightFrameworkComponent, canActivate: [AuthGuard] },
                    { path: 'toolset-guide', component: ToolsetGuideComponent, canActivate: [AuthGuard] },
                    { path: 'toolcards', component: ToolcardsComponent, canActivate: [AuthGuard],
                        children: [
                            { path: '', redirectTo: 'index', pathMatch: 'full' },
                            { path: 'overview', component: ToolcardsOverviewComponent, canActivate: [AuthGuard] },
                            { path: 'index', component: ToolcardsIndexComponent, canActivate: [AuthGuard] },
                            { path: 'action-steps', component: ToolcardsActionStepsComponent, canActivate: [AuthGuard] },
                            { path: 'assisters-and-resisters', component: ToolcardsAssistersResistersComponent, canActivate: [AuthGuard] },
                            { path: 'brainwriting', component: ToolcardsBrainwritingComponent, canActivate: [AuthGuard] },
                            { path: 'card-sort', component: ToolcardsCardSortComponent, canActivate: [AuthGuard] },
                            { path: 'data-questions', component: ToolcardsDataQuestionsComponent, canActivate: [AuthGuard] },
                            { path: 'evaluation-matrix', component: ToolcardsEvaluationMatrixComponent, canActivate: [AuthGuard] },
                            { path: 'excursions', component: ToolcardsExcursionsComponent, canActivate: [AuthGuard] },
                            { path: 'forced-connections', component: ToolcardsForcedConnectionsComponent, canActivate: [AuthGuard] },
                            { path: 'the-learning-cycle', component: ToolcardsLearningCycleComponent, canActivate: [AuthGuard] },
                            { path: 'managing-risk', component: ToolcardsManagingRiskComponent, canActivate: [AuthGuard] },
                            { path: 'mind-mapping', component: ToolcardsMindMappingComponent, canActivate: [AuthGuard] },
                            { path: 'perspectives', component: ToolcardsPerspectivesComponent, canActivate: [AuthGuard] },
                            { path: 'performance-dashboard', component: ToolcardsPerformanceDashboardComponent, canActivate: [AuthGuard] },
                            { path: 'phrase-challenges-as-questions', component: ToolcardsPhraseChallengesComponent, canActivate: [AuthGuard] },
                            { path: 'praise-first', component: ToolcardsPraiseFirstComponent, canActivate: [AuthGuard] },
                            { path: 'scamper', component: ToolcardsScamperComponent, canActivate: [AuthGuard] },
                            { path: 'stakeholder-analysis', component: ToolcardsStakeholderAnalysisComponent, canActivate: [AuthGuard] },
                            { path: 'brainstorming', component: ToolcardsStickBrainstormingComponent, canActivate: [AuthGuard] },
                            { path: 'stopping-you', component: ToolcardsStoppingYouComponent, canActivate: [AuthGuard] },
                            { path: 'storyboarding', component: ToolcardsStoryboardingComponent, canActivate: [AuthGuard] },
                            { path: 'targeting', component: ToolcardsTargetingComponent, canActivate: [AuthGuard] },
                            { path: 'assumption', component: ToolcardAssumptionReversalComponent, canActivate: [AuthGuard] },
                        ]
                    },
                    { path: 'innovation-station', component: InnovationStationComponent, canActivate: [AuthGuard],
                        children: [
                            { path: '', redirectTo: 'introduction', pathMatch: 'full' },
                            { path: 'introduction', component: InnovationIntroductionComponent, canActivate: [AuthGuard] },
                            { path: 'clarify', component: InnovationClarifyComponent, canActivate: [AuthGuard] },
                            { path: 'ideate', component: InnovationIdeateComponent, canActivate: [AuthGuard] },
                            { path: 'develop', component: InnovationDevelopComponent, canActivate: [AuthGuard] },
                            { path: 'implementer', component: InnovationImplementerComponent, canActivate: [AuthGuard] }
                         ]
                    },
                    { path: 'cn-print', component: CnPrintComponent , canActivate: [AuthGuard],
                        children: [
                            { path: '', component:CnPrintIntroComponent, canActivate: [AuthGuard]},
                            { path: 'intro', component:CnPrintIntroComponent, canActivate: [AuthGuard]},
                            { path: 'clarify', component: CnPrintClarifyComponent, canActivate:[AuthGuard]},
                            { path: 'framework', component: CnFrameworkComponent, canActivate:[AuthGuard]},
                            { path: 'ideate', component: CnPrintIdeateComponent, canActivate:[AuthGuard]},
                            { path: 'develop', component: CnPrintDevelopComponent, canActivate:[AuthGuard]},
                            { path: 'implementer', component: CnPrintImplementComponent, canActivate:[AuthGuard]},
                            { path: 'notes', component: CnPrintNotesComponent, canActivate:[AuthGuard]},
                            { path: 'summary', component: CnPrintSummaryComponent, canActivate:[AuthGuard]},
                        ]
                    }
                ]
            },
            { path: 'custom-store', canActivate: [AuthGuard],
                children: [
                    { path: '', component: StoreComponent, canActivate: [AuthGuard] },
                    { path: 'products', component: StoreProductsComponent, canActivate: [AuthGuard] },
                    { path: 'product-details/:id', component: StoreProductDetailsComponent, canActivate: [AuthGuard]},
                    { path: 'pricing', component: VolumePricingComponent, canActivate: [AuthGuard]},
                    { path: 'cart', component: StoreCartComponent, canActivate: [AuthGuard] },
                    { path: 'cart/:id', component: StoreCartComponent, canActivate: [AuthGuard] },
                    { path: 'orders', component: StoreOrdersComponent, canActivate: [AuthGuard] },
                    { path: 'order-details/:id', component: OrderDetailsComponent, canActivate: [AuthGuard]},
                    { path: 'order-verify', component: OrderVerifyComponent, canActivate: [AuthGuard]},
                    { path: 'order-status', component: OrderStatusComponent, canActivate: [AuthGuard]},
                    { path: 'accept-paypal', component: AcceptPaypalComponent, canActivate: [AuthGuard]},
                    { path: 'addresses', component: AddressesComponent, canActivate: [AuthGuard] },
                ]
            },
            { path: 'member/:id', component: MemberComponent, canActivate: [AuthGuard] },
            { path: 'advice/:id', component: AdviceComponent, canActivate: [AuthGuard] },
            { path: 'orgs', component: OrgsComponent, canActivate: [AuthGuard] },
            { path: 'orgs/:id', component: OrgsIndividualComponent, canActivate: [AuthGuard],
                children:[
                    { path: '', redirectTo: 'teams', pathMatch: 'full' },
                    { path: 'teams', component: OrgIndividualTeamsComponent, canActivate: [AuthGuard] },
                    { path: 'sessions', component: OrgIndividualSessionsComponent, canActivate: [AuthGuard] },
                    { path: 'members', component: OrgIndividualMembersComponent, canActivate: [AuthGuard] },
                    { path: 'training-sessions/:id', component: TrainingSessionsComponent, canActivate: [AuthGuard]},
                    { path: 'session-profile/:id', component: SessionProfileComponent, canActivate: [AuthGuard] },
                ]
            },
            { path: 'manage-members', component: ManageMembersComponent, canActivate: [AuthGuard] },
            { path: 'orgs/:id/manage-members', component: OrgManageMembersComponent, canActivate: [AuthGuard] },
            { path: 'teams', component: TeamsDashboardComponent, canActivate: [AuthGuard]},
            { path: 'all-team-members', component: AllteammatesComponent, canActivate: [AuthGuard]},
            { path: 'all-team-members/:id', component: MyteamsOne2oneComponent, canActivate: [AuthGuard]},
            { path: 'myteams', component: TeamsComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', component: TeamsComponent, canActivate: [AuthGuard] },
                    { path: 'ind/:id', component: MyteamsIndvidualComponent, canActivate: [AuthGuard] }
                ] 
            },
            { path: 'team-profile/:id', component: TeamProfileComponent, canActivate: [AuthGuard] },
            { path: 'org-profile/:id', component: TeamProfileComponent, canActivate: [AuthGuard] },
            { path: 'team-report/:id', component: TeamReportComponent, canActivate: [AuthGuard] },
            { path: 'permissions', component: PermissionsComponent, canActivate: [AuthGuard] },
            { path: 'myteams/thinking-profile/:id', component: ThinkingProfileIdComponent, canActivate: [AuthGuard] },
            { path: 'myteams/:id/participant/:id', component: ParticipantsViewComponent, canActivate: [AuthGuard]},
            { path: 'myteams/:id', component: ParticipantsViewComponent, canActivate: [AuthGuard], 
                children: [
                    {path: 'member/:id', component: MyteamsOne2oneComponent, canActivate: [AuthGuard]}
                ]
            },   
        ]
    },
    {
        path: '', component: BlankLayoutComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'logout', component: LoginComponent },
            { path: 'signup', component: LoginComponent }
        ]
    },

    { path: 'challenge-navigator-print',
        children: [
            { path: '' ,component: ChallengeNavigatorPrintComponent},
            { path: 'coach' ,component: ChallengeNavigatorPrintComponent,
                children: [
                    { path: 'card/:id', component: DynamicCardSingleComponent }
                ]
            }
        ]
    },

    { path: 'challenge-navigator-print-coach',
        children: [
            { path: '' ,component: ChallengeNavigatorPrintComponent},
            { path: 'card' ,component: ChallengeNavigatorPrintComponent,
                children: [
                    { path: ':id', component: DynamicCardSingleComponent }
                ]
            }
        ]
    },

    {
        path: '',
        children: [
            { path: 'thinking-coach/:id', component: CustomProfileComponent},
            { path: 'slideshow/:id', component: CustomProfileComponent},
            { path: 'impersonation/:id', component: ImpersonateComponent},
            { path: 'store', component: PublicStoreComponent,
                children: [
                    { path: '', component: PublicStoreProductsComponent },
                    { path:':slug', component: PublicProductDetailsComponent},
                    { path: 'cart', component: PublicStoreCartComponent },
                ]
            }
        ]
    },
    // Login Callback
    { path: 'callback', component: LoginCallbackComponent },
    // Handle all other routes
    { path: '**',  component: PageNotFoundComponent}
];