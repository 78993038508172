import { Component, Renderer2 } from '@angular/core';
import { environment } from '../../../../../environments/environment';
declare var hbspt: any
@Component({
  selector: 'app-book-demo-popup',
  templateUrl: './book-demo-popup.component.html',
  styleUrls: ['./book-demo-popup.component.scss']
})


export class BookDemoPopupComponent {
  env = environment;
  constructor(private renderer: Renderer2) {}

  ngAfterContentInit() { 
    hbspt.forms.create({ 
      portalId: "4424590",
      formId: "70944c12-aad5-4f96-9cde-2c3bd2da8656",
      target: '#hubspot-form'
    }); 
   } 

   closePopup() {
    $('.book-demo').addClass('f-d-none');
   }
}
