import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-cig-thankyou',
    templateUrl: './cig-thankyou.component.html',
    styleUrls: ['./cig-thankyou.component.scss']
})
export class CigThankyouComponent implements OnInit {

    @Input() profile:Array<Object>;
    @Input() slides;
    @Input() author;
    public env;
    public profiles : any;
    public description:any;
    appNoli:string= 'false';

    constructor() { }

    ngOnInit() {
        this.env=environment;
        this.appNoli = localStorage.getItem("appNoli");
        this.profiles = this.profile;
    }

    public ngOnChanges( changes: SimpleChanges ) : void {

        if ( changes.profile && ! changes.profile.isFirstChange() ) {
        	this.profiles = changes.profile.currentValue;
            this.description=this.profiles?.profile_description;
            this.description=this.description?.replace("<span class='profile-desc'>",'');
            this.description=this.description?.replace('</span>','');

        }

    }

    // calling appnoli profile
    public custom_pf() {
        $('.fa.fa-dashboard').click();
        setTimeout(() => {
            $('.dashcheck__image')[0].click();
            $(".pf-download").click();
            setTimeout(() => {
                $(".pf-download").click();
            }, 100);
        }, 100);
    }

}
