import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Auth } from '../../auth/auth.service';

// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TeamReportServiceService } from '../team-report/team-report-service.service';
import { LambdaFunctionService } from './../../service/lambda-function.service';

@Component({
  selector: 'app-group-members-profile',
  templateUrl: './group-members-profile.component.html',
  styleUrls: ['./group-members-profile.component.scss']
})
export class GroupMembersProfileComponent implements OnInit {
	@Input() group:any;
	// public neutral: number;
	// public height:number;
	// @Input() small;
	// @Input() activated;
	clarifier_low:number;
	clarifier_high:number = 0;
	ideator_low:number = 0;
	ideator_high:number = 0;
	developer_low:number = 0;
	developer_high:number = 0;
	implementor_low:number =0;
	implementor_high:number= 0;
	integrator:number = 0;
	highest_score:number = 0;
	download_profile:boolean;
	app_type:string;
	pdf_type:string;
	today_date:Date;
	Email:any;
	profile_type:any;
	args:any;
  	constructor( public router: Router, public http: HttpClient, public auth: Auth,public teamReportService: TeamReportServiceService, public lmda_service: LambdaFunctionService) { }
  	
	ngOnInit() {
		this.today_date = new Date();
		this.auth.getProfile((err, profile) => {
            this.Email = profile.email;
        })
		// this.auth.getUser().subscribe(user => {
		// 	this.Email = user.email;
		// })
		this.download_profile = false;
		this.clarifier_low = 0;
	    this.clarifier_high = 0;
	    this.ideator_high = 0;
	    this.ideator_low = 0;
	    this.developer_high = 0;
	    this.developer_low = 0;
	    this.implementor_high = 0;
	    this.implementor_low = 0;
	    this.integrator = 0;
	    this.highest_score = 0;
	    console.log(this.group)
		this.group.forEach(list => {

	        if(list?.default_profile?.clarifier_score == 'Low'){
	          this.clarifier_low = this.clarifier_low + 1;
	          if( this.clarifier_low > this.highest_score ){
				this.highest_score = this.clarifier_low;
		    }
	        }

	        if(list.default_profile?.clarifier_score == 'High'){
	          this.clarifier_high = this.clarifier_high + 1;
	          if( this.clarifier_high > this.highest_score ){
				this.highest_score = this.clarifier_high;
		    }
	        }

	        if(list.default_profile?.ideator_score == 'Low'){
	          this.ideator_low = this.ideator_low + 1;
	          if( this.ideator_low > this.highest_score ){
				this.highest_score = this.ideator_low;
		    }
	        }

	        if(list.default_profile?.ideator_score == 'High'){
	          this.ideator_high = this.ideator_high + 1;
	          if( this.ideator_high > this.highest_score ){
				this.highest_score = this.ideator_high;
		    }
	        }

	        if(list.default_profile?.developer_score == 'Low'){
	          this.developer_low = this.developer_low + 1;
	          if( this.developer_low > this.highest_score ){
				this.highest_score = this.developer_low;
		    }
	        }

	        if(list.default_profile?.developer_score == 'High'){
	          this.developer_high = this.developer_high + 1;
	          if( this.developer_high > this.highest_score ){
				this.highest_score = this.developer_high;
		    }
	        }

	        if(list.default_profile?.implementer_score == 'Low'){
	          this.implementor_low = this.implementor_low + 1;
	          if( this.implementor_low > this.highest_score ){
				this.highest_score = this.implementor_low;
		    }
	        }

	        if(list.default_profile?.implementer_score == 'High'){
	          this.implementor_high = this.implementor_high + 1;
	          if( this.implementor_high > this.highest_score ){
				this.highest_score = this.implementor_high;
		    }
	        }

	        if(list.default_profile?.clarifier_score == 'Neutral' && list.default_profile.ideator_score == 'Neutral' && list.default_profile.developer_score == 'Neutral' && list.default_profile.implementer_score == 'Neutral'){
	          this.integrator += 1;
	        }

		});
	}

	public showGroup(content_type){
        this.download_profile = false;
        $('.pdf-modal').css('display','block');
		var that = this;
            this.download_profile = false;
            var counter = 0;
            var groupMembers = [];
            this.profile_type = 'group profile'
            var attrib = {kind: 'group_profile', member_ids: groupMembers};
            console.log(attrib);
            console.log(this.teamReportService.teamSelectedArr)
            var len= this.group.length;
			var date  = new Date();
            this.args = {
                "id": this.group[0]?.team_id,
                "template": "group_pf.html",
                "filename": "group_pf_org",
                "memberships_count": len,
                "name": this.group[0].default_profile?.team_name,
                "organization_title": "Organization",
                "organization_id": this.group[0].default_profile?.organization_id,
                "organization_name": this.group[0].default_profile?.organization_name,
                "clarifier_low": this.clarifier_low,
                "clarifier_high": this.clarifier_high,
                "ideator_low": this.ideator_low,
                "ideator_high": this.ideator_high,
                "developer_low": this.developer_low,
                "developer_high": this.developer_high,
                "implementor_low": this.implementor_low,
                "implementor_high": this.implementor_high,
                "integrator": this.integrator,
                "highest_score": this.highest_score,
                "app_type": "Group Profile",
                "pdf_type": "letter",
                "email": "testownerfoursightonline.com",
                "today_date": date,
                "content_type": content_type,
                "profile_type": "Ask",
                "description": "Look at your group graph. What do you notice? What, if anything, is surprising? Where does this group need to paydeliberate attention so they can succeed? Think of a project orchallenge this group has recently been engaged in. Describehow the group approached the project using the group profileas a guide.",
                "footer_imptext": "FourSightÂ® Group Profile",
                "footer_text": " The chart above shows the high and low preference expressed byyour group. Note that the numbers in each bar of the chart will generally not add up to the totalnumber of people on your group. The chart only reflects group members with high or low preferencesin each area.",
                "version": "v17.3",
                }
                console.warn(this.args)
				this.lmda_service.lambda_invoke('plus-GroupProfileFunction-6y5p4TgXuqZA',this.args);
        // var that = this;
        // var counter = 0;
        // var groupMembers = [];
        // this.group.forEach(list =>{
        //     groupMembers[counter] = list.id;
        //     counter++;
        // });
        // var attrib = {kind: 'group_profile', member_ids: groupMembers};
		// console.log(this.teamReportService.teamSelectedArr)
		// console.log(attrib)
        // this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
        //     console.log(data);
        //     that.app_type = 'Group Profile';
        //     that.pdf_type = 'profile';
        //     that.download_profile = true;
        // },
        // err =>{
        //     console.log(err);
        //     setTimeout(function() {
        //         this.toastr.options = {
        //             closeButton: true,
        //             progressBar: true,
        //             timeOut: 2000
        //         };
        //         this.toastr.error(err.error.message, 'Sorry!');
        //     }, 1000);
        // })
    }

    public accDownload() {
        this.router.navigate(['/account-downloads']);
    }

    public showGraph(){
        this.download_profile = false;
        var that = this;
        var counter = 0;
        var groupMembers = [];
        this.group.forEach(list =>{
            groupMembers[counter] = list.id;
            counter++;
        });
        var attrib = {kind: 'graph', member_ids: groupMembers};
        this.http.post(`${environment.profileURL}/api/v1/pdfs/generate.json`,attrib).subscribe(data => {
            console.log(data);
            that.app_type = 'Group Graph';
            that.pdf_type = 'graph';
            that.download_profile = true;
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.error.message, 'Sorry!');
            }, 1000);
        })
    }

}
