// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version {
  display: block;
  text-align: right;
  margin-top: -15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cn-print/cn-print-intro/cn-print-intro.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;EACA,iBAAA;AACJ","sourcesContent":[".version {\n    display: block;\n    text-align: right;\n    margin-top: -15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
