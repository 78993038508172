import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import {CommonDataService} from './../common-data.service';
import { HttpClient } from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss';
import {AccessGroupService} from './../access-group.service';
import { Store } from '@ngrx/store';
import { TeamReportServiceService } from '../team-report/team-report-service.service';

@Component({
  selector: 'app-orgs',
  templateUrl: './orgs.component.html',
  styleUrls: ['./orgs.component.scss']
})
export class OrgsComponent implements OnInit {
	organizations:any;
	meta:any;
	curPage:any;
	item:any;
	pageLen:any;
	query_string:any;
	itemsByPage:any;
	isLoading:any;
	Query_String:any;
	name:any;
	modal:any;
	orgName:any;
	editOrgs:any;
	savedOrg:any;
	count:any;
    ngrxAccessGroup:any;
    prevRecordName:string;
    delRoster:boolean= false;
    org;
    deleteOrg;
    deleteIndex;
    tableHierarView:any= 'table';

    constructor(public accessgroup:AccessGroupService, public http: HttpClient, private modalService: NgbModal, private router: Router,public store:Store<any>, public teamReportService: TeamReportServiceService) { }

  	async ngOnInit() {
        localStorage.getItem('rostertableHierView') ? this.tableHierarView = localStorage.getItem('rostertableHierView') : 'table'
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
  		this.ngrxAccessGroup.subscribe(message => { 
            if(message && message.length > 0){                
                if(!message['main_menu'].orgs){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })
  		this.editOrgs = -1;
  		this.itemsByPage = 20;
  		this.query_string = environment.profileURL+`/api/v1/roster_organizations.json?name_order=asc`;
  		this.Query_String = this.query_string;
  		this.isLoading = true;
	  	// this.http.get(`${environment.profileURL}/api/v1/organizations.json?name_order=asc&info_only=true`).subscribe(data => {
	  	await this.http.get(`${environment.profileURL}/api/v1/roster_organizations.json`).subscribe(async data => {
            console.log(data['organizations']);
            this.organizations = data['organizations'];
            this.meta = data['meta'];
            this.count = data['meta'].total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.isLoading = false;
            this.item = [];
            for(var i = 1; i<=this.pageLen;i++){
			    this.item.push(i);
			}
			if(this.count > this.itemsByPage){
				this.render( this.curPage, this.item, true );
	            console.log(data);
	        }
            // await data['organizations'].forEach(async (rstr)=> {
            //     await this.getRosterMem(rstr)
            // })
            console.warn(this.organizations)
        },
            error => console.log('oops', error)
        )

	}


    // return roster member
    // getRosterMem(rstr) {
    //     console.log('=====>', rstr)
    //     if(rstr.members_count != 0) {
    //         this.http.get(`${environment.profileURL}/api/v1/organizations/${rstr.id}/members.json?per_page=${rstr.members_count}&last_name_order=asc`).subscribe((res:any)=> {
    //             // this.teamsData = res;
    //             // this.loading = false;
    //             console.log('teamData',res)
    //             rstr['memberships'] = res.members
    //             // this.pageNumber(1)
    //         })
    //     }
    // }

	public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}

	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder = document.querySelector('#holder');
        if(this.meta.total_count > this.itemsByPage){
            holder.innerHTML = html;
            if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
            if ( first ) {
                holder.addEventListener('click', e => {
                    console.log(e.target['attributes']['data-page'].value);
                    if (!e.target['attributes']['data-page'].value) {
                        // no relevant item clicked (you could however offer expand here )
                        return;
                    }

                    curPage = parseInt(e.target['attributes']['data-page'].value);
                    var page_number = curPage+1;
                    this.isLoading = true;
                    this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                        this.organizations = data['organizations'];
                        this.meta = data['meta'];
                        this.count = this.meta.total_count;
                        this.pageLen = this.meta.total_pages;
                        this.isLoading = false;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                            this.item.push(i);
                        }

                    },
                    error => console.log('oops', error)
                    )
                    this.render( curPage, item );
                });
            }
        }else{
            holder.innerHTML = null;
        }
    }
    public updatePerpage(){
    	this.isLoading = true;
    	this.query_string = this.Query_String+'&per_page=' + this.itemsByPage;
    	if(this.name){this.query_string = this.query_string+'&name=' + this.name;}
    	this.http.get(`${this.query_string}`).subscribe(data => {
    		this.isLoading = false;
            this.organizations = data['organizations'];
            this.meta = data['meta'];
    		this.count = this.meta.total_count;
            this.pageLen = this.meta.total_pages;
            this.curPage = 0;
            this.item = [];
            for(var i = 1; i<=this.pageLen;i++){
                this.item.push(i);
            }
            this.render( this.curPage, this.item, true );

        },
            error => console.log('oops', error)
        )
    }

    // org search filter function
    public updateName(){
    	this.name = jQuery('#name').val();
        if(this.name.length == 0 || this.name.length >= 3){
            this.organizations = [];
            this.isLoading = true;
            console.log(this.name);
            this.query_string = this.Query_String+'&per_page=' + this.itemsByPage;
            if(this.name){this.query_string = this.query_string+'&name=' + this.name;}
            this.http.get(`${this.query_string}`).subscribe(data => {
                this.isLoading = false;
                this.organizations = data['organizations'];
                this.meta = data['meta'];
                this.count = this.meta.total_count;
                this.pageLen = this.meta.total_pages;
                this.curPage = 0;
                this.item = [];
                for(var i = 1; i<=this.pageLen;i++){
                    this.item.push(i);
                }
                this.render( this.curPage, this.item, true );
    
            },
                error => console.log('oops', error)
            )
        }
    }

    public open(){
        $('.orgs-modal').css('display','block');
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
    }
    
    public addOrg(){
        if(this.orgName){
        	var data = {
        		name:this.orgName,
                roster:true
        	};
        	// this.http.post(`${environment.profileURL}/api/v1/organizations.json`,data).subscribe(result => {
        	this.http.post(`${environment.profileURL}/api/v1/organizations.json`,data).subscribe(result => {
                console.log(result);
                $('.modal-dialog').addClass('hide');
                $('body').removeClass('modal-open');
                // this.modal.close();
                $('.orgs-modal').css('display','none');
                this.organizations.push(result['organization']);
                this.count += 1;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.orgName = '';
                    this.toastr.success('The Roster was added successfully!', 'Nice!');
                }, 1000);

            },
            error => {
                console.log('oops', error)
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(error?.error?.errors, 'Oops!');
                }, 1000);
            })
            // this.modal.close();
            $('.orgs-modal').css('display','none');
        }
    }
    
    public delOrg(org, index){
        this.deleteOrg = org;
        this.deleteIndex = index;
        console.log('myorgs',org)
        if(org.members_count >= 2 ) {
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 5000
                };
                this.toastr.error('Please delete all members from the roster. Then you can delete the roster itself.', 'Oops!');
            }, 500);
        } else {
            $('.orgs-modal-del').css('display','block');
            if(this.delRoster) {
                this.http.delete(`${environment.profileURL}/api/v1/organizations/`+org.id+'.json').subscribe(result => {
                    console.log(result);
                    this.organizations.splice(index,1);
                    this.count -= 1;
                    this.delRoster = false;
                    this.deleteOrg = undefined;
                    this.deleteIndex = undefined
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('The Roster was deleted successfully!', 'Nice!');
                    }, 1000);
                    $('.orgs-modal-del').css('display','none');
                },
                err =>{
                    console.log(err);
                    $('.orgs-modal-del').css('display','none');
                    this.delRoster = false;
                    this.deleteOrg = undefined;
                    this.deleteIndex = undefined;
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error(err?.error?.errors, 'Sorry!');
                    }, 1000);
                })
            }
        }
    }
    public openEditOrg(record,index){
    	console.log(index);
        this.editOrgs = index;
        this.savedOrg = record;
        console.log(record)
        this.prevRecordName = record.name;
    }
    public cancelSave(index){
    	console.log(index);
        this.organizations[this.editOrgs].name = this.prevRecordName;
        this.editOrgs = -1;
        this.savedOrg = null;
    }
    public saveOrg(record){
    	var data = {
    		name:record?.name
    	};
        console.log(this.savedOrg,this.organizations)
    	this.http.put(`${environment.profileURL}/api/v1/organizations/`+record?.id+'.json',data).subscribe(result => {
            console.log(result);
            this.editOrgs = -1;
            this.savedOrg = null;
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Roster was updated successfully!', 'Nice!');
            }, 1000);
            
        },err=> {
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err?.error?.errors, 'Oops!');
            }, 100);
            this.organizations[this.editOrgs].name = this.prevRecordName;
            this.cancelSave(this.editOrgs)
        })          
        
    }

    public resetOrgName() {
        jQuery('#name').val('');
        this.updateName();
    }

    public updateMembers(members,route,recName) {
        console.warn("update counter")
        this.teamReportService.totalOrgMembers.next(members);
        localStorage.setItem('orgGraph',JSON.stringify({members_count:members,orgName: recName}))
        this.router.navigate([route]);
    }

    public currentOrg(currentOrg) {
        localStorage.setItem('orgTotalMember',currentOrg.members_count)
        localStorage.setItem('currentOrgName',currentOrg.name)
        this.teamReportService.totalOrgMembers.next(currentOrg.members_count)
        this.teamReportService.orgData.next(currentOrg);
    }

    public openRosterPopup(name) {
        this.teamReportService.rosterName.next(name);
        $('.roster_popup').css('display','block');
    }

    public back() {
        window.history.back();
    }

    public changeView(view = 'table') {
        localStorage.setItem('rostertableHierView', view)
        this.tableHierarView = view;
    }

}
