import { Component, OnInit } from '@angular/core';
import {AccessGroupService} from './../access-group.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
  env;
  constructor(public accessgroup: AccessGroupService, public router: Router) { }

  ngOnInit() {
    this.env = environment;
	}

}
