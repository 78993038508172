import { Component, Input, OnInit } from "@angular/core";
import { LambdaFunctionService } from "../../service/lambda-function.service";
import { environment } from "../../../environments/environment";
import { Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { CommonDataService } from "../common-data.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-dialogbox",
  templateUrl: "./dialogbox.component.html",
  styleUrls: ["./dialogbox.component.scss"],
})
export class DialogboxComponent implements OnInit {
  @Input() args: any;
  @Input() usingProfileId: boolean = false;
  public profiles: any;
  guidesLang:any;
  selected_lang: string = "en";
  selected_format: string = "letter";
  loader: boolean = false;
  downloadingPdf: boolean = false;
  letter_format: string = "Download Profile PDF (US Letter)";
  a4_format: string = "Download Profile PDF (International A4)";
  image_content: string = "Download Profile PNG";
  gen_pdf: any = "";
  file_type: string = "PDF";
  env: any;
  pf_description: string = "";
  pf_eqn: string = "";
  pf_title = "";
  pf_email_hd = "Profile Email:";
  fspf: any;
  date_of_profile: any;
  organization_title: any;
  thinking_profile_heading: any;
  thinking_profile_message: any;
  download: any;
  share_my_profile: any;
  explain_my_profile: any;
  low_preference: any;
  high_preference: any;
  contrast: any;
  clarify: any;
  ideate: any;
  develop: any;
  implement: any;
  prefers_to: any;
  get_report: any;
  sign_up_with: any;
  storeNgrx: any;
  ngrxTcwaProfile: any;
  ngrxAccessGroup: any;

  constructor(
    public lmda_service: LambdaFunctionService,
    public store: Store<any>,
    public http: HttpClient,
    public service: CommonDataService,
    public router: Router
  ) {}

  ngOnInit(): void {
    if(this.usingProfileId) {
      
    }else {
      this.storeNgrx = this.store.select<any>(
        (state) => state.profile?.account.default_profile
      );
      this.ngrxTcwaProfile = this.store.select<any>((state) => state.profile.tcwaProfile)
      this.ngrxAccessGroup = this.store.select<any>(
        (state) => state.profile.accessGroupData
      );
      console.warn(this.args)
      this.service.pf_selected_language.subscribe((val)=> {
        console.log('updated')
        this.selected_lang = val;
        this.selectFormat();
        this.change_language(false)
        console.log('value updated')
        console.warn(this.selected_lang)
      });
      console.log(this.args);
      this.env = environment;
      this.profileLangList();
    }
  }

  // get profile language from guide api
  public profileLangList() {
    this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
        console.log(data);
        // Sort by Language name
        this.guidesLang = data['interpretive_guides'].sort(function(a, b){
            return a.language_name.localeCompare(b.language_name);
        });
    },
        error => console.log('oops', error)
    )
  }

  public selectFormat(fileType:string = "PDF", format:any = "letter") {
    this.gen_pdf = "";
    this.file_type = fileType;
    console.log("gen pdf is ....", this.gen_pdf);
    this.selected_format = format;
  }

  public async change_language(lang_update: boolean = true) {
    this.loader = true;
    if(lang_update) {
      console.log('language update')
      this.selectFormat(this.file_type,this.selected_format);
      this.service.pf_selected_language.next(this.selected_lang)
    }
    console.log(this.selected_lang);
    console.log(this.profiles);
    this.gen_pdf = "";

    if(this.router.url.includes("thinking-coach")) {
      console.log('include thinking-coach')
      this.ngrxTcwaProfile.subscribe((message) => {
        this.profiles = message['profile']
        this.http
          .get(
            `${environment.profileURL}/api/v1/translations.json?language=` +
              this.selected_lang
          )
          .subscribe((data) => {
            console.log(data);
            this.pf_email_hd = data["profile_email"];
            this.date_of_profile = data["date_of_profile"];
            this.organization_title = data["organization_title"];
            this.thinking_profile_heading = data["thinking_profile_heading"];
            this.thinking_profile_message = data["thinking_profile_message"];
            this.download = data["download"];
            this.share_my_profile = data["share_my_profile"];
            this.explain_my_profile = data["explain_my_profile"];
            this.low_preference = data["low_preference"];
            this.high_preference = data["high_preference"];
            this.contrast = data["contrast"];
            this.clarify = data["clarify"];
            this.ideate = data["ideate"];
            this.develop = data["develop"];
            this.implement = data["implement"];
            this.prefers_to = data["prefers_to"];
            this.get_report = data["get_report"];
            this.sign_up_with = data["sign_up_with"];
          });
      });
    } else {
      this.storeNgrx.subscribe((message) => {
        this.profiles = message
        this.http
          .get(
            `${environment.profileURL}/api/v1/translations.json?language=` +
              this.selected_lang
          )
          .subscribe((data) => {
            console.log(data);
            this.pf_email_hd = data["profile_email"];
            this.date_of_profile = data["date_of_profile"];
            this.organization_title = data["organization_title"];
            this.thinking_profile_heading = data["thinking_profile_heading"];
            this.thinking_profile_message = data["thinking_profile_message"];
            this.download = data["download"];
            this.share_my_profile = data["share_my_profile"];
            this.explain_my_profile = data["explain_my_profile"];
            this.low_preference = data["low_preference"];
            this.high_preference = data["high_preference"];
            this.contrast = data["contrast"];
            this.clarify = data["clarify"];
            this.ideate = data["ideate"];
            this.develop = data["develop"];
            this.implement = data["implement"];
            this.prefers_to = data["prefers_to"];
            this.get_report = data["get_report"];
            this.sign_up_with = data["sign_up_with"];
          });
      });
    }
    await this.pfDesAnyLang(this.selected_lang);
  }

  public async pfDesAnyLang(lang_code) {
    // fetch collaboration data
    console.log(lang_code);
      this.http
      .get(`${environment.profileURL}/api/v1/profile_types.json`, {
        params: { profile: "custom", language: lang_code },
      })
      .subscribe((res) => {
        for (let i = 0; i < res["profile_types"].length; i++) {
          if (this.profiles?.profile_type_id === res["profile_types"][i]["id"]) {
            console.log(res["profile_types"][i]["individual_description"]);
            this.pf_description = res["profile_types"][i]["individual_description"];
            this.pf_eqn = res["profile_types"][i]["profile_equation"];
            this.pf_title = res["profile_types"][i]["profile_title"];
          }
        }
      });
      await this.changePdflang();
  }

  // change pdf language in selected lang
  public changePdflang() {
    var pf_desc;
    var pf_eqn;
    var pf_title;
    this.http
      .get(`${environment.profileURL}/api/v1/profile_types.json`, {
        params: { profile: "custom", language: this.selected_lang },
      })
      .subscribe((res) => {
        console.warn(res["profile_types"]);
        for (let i = 0; i < res["profile_types"].length; i++) {
          console.log((this.profiles?.profile_type_id === res["profile_types"][i]["id"]))
          if (
            (this.profiles?.profile_type_id === res["profile_types"][i]["id"])
          ) {
            console.log(res["profile_types"][i]["individual_description"]);
            pf_desc = res["profile_types"][i]["individual_description"];
            pf_eqn = res["profile_types"][i]["profile_equation"];
            pf_title = res["profile_types"][i]["profile_title"];
            if(this.router.url.includes("thinking-coach")) {
              this.ngrxTcwaProfile.subscribe((message) => {
                message = message["profile"]
                const newPromise = new Promise((resolve,error)=> {
                  this.http
                    .get(
                      `${environment.profileURL}/api/v1/translations.json?language=` +
                        this.selected_lang
                    )
                    .subscribe((data) => {
                      resolve(data);
                    });
                })
                newPromise.then((data)=> {
                  this.args = {
                    date_title: data["date_of_profile"],
                    email_title: data["profile_email"],
                    id: message["id"],
                    content_type: "pdf",
                    personalized_note: `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${message["email"]}`,
                    template: "multi-profile.html",
                    email: message["email"],
                    date_of_profile: data["date_of_profile"],
                    organization_title: data["organization_title"],
                    first_name: message["first_name"],
                    last_name: message["last_name"],
                    low_preference: data["low_preference"],
                    high_preference: data["high_preference"],
                    contrast: data["contrast"],
                    clarify: data["clarify"],
                    ideate: data["ideate"],
                    develop: data["develop"],
                    implement: data["implement"],
                    prefers_to: data["prefers_to"],
                    get_report: data["get_report"],
                    sign_up_with: data["sign_up_with"],
                    thinking_profile_heading: data["thinking_profile_heading"],
                    thinking_profile_message: data["thinking_profile_message"],
                    organization: message["organization_name"],
                    clarifier_sum: message["clarifier_sum"],
                    developer_sum: message["developer_sum"],
                    ideator_sum: message["ideator_sum"],
                    implementer_sum: message["implementer_sum"],
                    low_contrast: message["low_contrast"],
                    high_contrast: message["high_contrast"],
                    generated_at: message["generated_at"],
                    profile_title: pf_title,
                    profile_equation: pf_eqn,
                    profile_description: pf_desc,
                  };
                })
              });
            } else {
              this.storeNgrx.subscribe((message) => {
                this.http
                  .get(
                    `${environment.profileURL}/api/v1/translations.json?language=` +
                      this.selected_lang
                  )
                  .subscribe((data) => {
                    console.log(data);
                    this.args = {
                      date_title: data["date_of_profile"],
                      email_title: data["profile_email"],
                      id: message["id"],
                      content_type: "pdf",
                      personalized_note: `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${message["email"]}`,
                      template: "multi-profile.html",
                      email: message["email"],
                      date_of_profile: data["date_of_profile"],
                      organization_title: data["organization_title"],
                      first_name: message["first_name"],
                      last_name: message["last_name"],
                      low_preference: data["low_preference"],
                      high_preference: data["high_preference"],
                      contrast: data["contrast"],
                      clarify: data["clarify"],
                      ideate: data["ideate"],
                      develop: data["develop"],
                      implement: data["implement"],
                      prefers_to: data["prefers_to"],
                      get_report: data["get_report"],
                      sign_up_with: data["sign_up_with"],
                      thinking_profile_heading: data["thinking_profile_heading"],
                      thinking_profile_message: data["thinking_profile_message"],
                      organization: message["organization_name"],
                      clarifier_sum: message["clarifier_sum"],
                      developer_sum: message["developer_sum"],
                      ideator_sum: message["ideator_sum"],
                      implementer_sum: message["implementer_sum"],
                      low_contrast: message["low_contrast"],
                      high_contrast: message["high_contrast"],
                      generated_at: message["generated_at"],
                      profile_title: pf_title,
                      profile_equation: pf_eqn,
                      profile_description: pf_desc,
                    };
                  });
              });
            }
          }
        }
        this.loader = false;
      });
  }

  // create profile pdf or png based on type
  public async lambdaPDF(format, title, type) {
    console.log(title, type);
    console.log(this.args)
    this.loader = true;
    // remove members from already using args
    let date = new Date();
    var argument = this.args;
    this.downloadingPdf = true;
    let pdfFilename =
      "foursight-profile-" +
      this.args?.first_name +
      "-" +
      this.args?.last_name +
      "-" +
      date.getFullYear().toString().substr(-2) +
      date.getMonth() +
      date.getDate();

    if (type == "pdf") {
      this.args.content_type = "pdf";
      if (format == "A4") {
        this.a4_format = "Generating Profile PDF...";
        this.args.format = format;
      } else {
        this.letter_format = "Generating Profile PDF...";
        this.args.format = format;
      }
      argument = {
        template: "multi-profile.html",
        format: "A4",
        organization: "Organization",
        content_type: "pdf",
        filename: pdfFilename,
        members: {
          "0": this.args,
        },
      };
    } else {
      this.image_content = "Generating Profile PNG...";
      argument = {
        template: "multi-profile.html",
        format: "A4",
        organization: "Organization",
        content_type: "png",
        filename: pdfFilename,
        members: {
          "0": this.args,
        },
      };
      this.args.content_type = "png";
    }
    this.args.template = "multi-profile.html";
    console.log(this.args);
    await this.lmda_service.lambda_invoke(
      "thinking-ProfileFunction-pxVk1VnOrZ3r",
      argument
    );
    this.lmda_service.lambda_res.subscribe((val) => {
      console.log(val);
      if (val.length !=0) {
        this.gen_pdf = "";
        this.gen_pdf = val;
        console.log(this.gen_pdf);
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      }
    });
    // res.subscribe(output => {
    //     let newWin = window.open(
    //         output['response'].body,
    //         '_blank' // <- This is what makes it open in a new window.
    //     );
    // });
    this.letter_format = "Download Profile PDF (US Letter)";
    this.a4_format = "Download Profile PDF (International A4)";
    this.image_content = "Download Profile PNG";
    argument = null;
    this.downloadingPdf = false;
  }

  public async profile_downloader() {
    console.log(this.selected_format);
    this.lmda_service.lambda_res.next([])
    if (this.selected_format == "A4") {
      // this.selectFormat("A4","pdf");
      await this.lambdaPDF("A4", "Download Profile PDF (International A4)", "pdf");
    } else if (this.selected_format == "letter") {
      // this.selectFormat("Letter","pdf");
      await this.lambdaPDF("Letter", "Download Profile PDF (US Letter)", "pdf");
    } else {
      // this.selectFormat("Letter","png");
      await this.lambdaPDF("Letter", "Download Profile PNG", "png");
    }
  }

  public close() {
    $('.pdf-modal').css('display', 'none');
  }
}