import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {AccessGroupService} from './../access-group.service';
import { environment } from '../../../environments/environment';
import { APIService } from '../../API.service';
import { Store } from '@ngrx/store';
import { LambdaFunctionService } from '../../service/lambda-function.service';

@Component({
  selector: 'app-learner-maps-listing',
  templateUrl: './learner-maps-listing.component.html',
  styleUrls: ['./learner-maps-listing.component.scss']
})
export class LearnerMapsListingComponent implements OnInit {

  public env;
  public lm;
  public admin_lm;
  public allmaps: any = [];
  public currentLM_id: string;
  learnermap_data = [];
  learnermap_data_img = [];
  ngrxAccessGroup:any;

  constructor(public http: HttpClient, public accessgroup:AccessGroupService, private api: APIService, public router: Router,public store:Store<any>) { }

    async ngOnInit() {
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        this.env = environment;
        // it re-directs url to the dashboard page if access to this page is not permitted.
        var that = this;
      	await this.ngrxAccessGroup.subscribe(message => {
            if(message){
                this.lm = message['main_menu']?.learner_maps;
                this.admin_lm = message['main_menu'];
                // if(!message['main_menu'].learner_maps){
                //         this.router.navigate(['/dashboard']);
                //         return false;
                // }
                if(message['main_menu']?.learner_maps != null){
                    this.allmaps = []; 
                    for(var i in message['main_menu'].learner_maps){
                        if(message['main_menu'].learner_maps[i].title){
                            this.allmaps.push([i, message['main_menu'].learner_maps[i]]);
                            this.api.GetLearnerMap(message['main_menu'].learner_maps[i].value).then(function (map) {
                                console.log(that.learnermap_data)
                                that.learnermap_data.push(map); 
                              })
                        }
                    }
                    this.allmaps.sort(function (a, b) {
                        // Use toUpperCase() to ignore character casing
                        const titleA = a[1].title.toUpperCase();
                        const titleB = b[1].title.toUpperCase();

                        let comparison = 0;
                        if (titleA > titleB) {
                            comparison = 1;
                        } else if (titleA < titleB) {
                            comparison = -1;
                        }
                        return comparison;
                        
                    })
                }
            }
        },
        err => {
            // Log errors if any
            console.log(err);
        })
        setTimeout(()=> {
            console.log(this.learnermap_data)
        },3000)
    }

}
