import { Component, OnInit, Input } from '@angular/core';
import { TeamReportServiceService } from './../team-report-service.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {
	teammembers:any;
	record:any;
	@Input() members:any;

  	constructor(public service: TeamReportServiceService,public http: HttpClient) { }

  	ngOnInit() {
  		this.teammembers = this.service.teammembers;
  	}

	public updateCollab(data) {
		console.log(data);
		this.http.post(`${environment.profileURL}/api/users/update_collaboration_identity.json`,{params:{id: data.id,works_for_me: 'test data'}}).subscribe(data => {
			console.log(data);
		});
	}

}
