import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssessmentPricingService {

	pricing = {
	  	"business":{
	  		"reseller":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": 100,
		  			"tier": "RB1",
		  			"retail_percent": 75,
		  			"reseller_cost": 52.50,
		  			"client_sr_price": 52.50
		  		},
		  		{
		  			"min_vol": 101,
		  			"max_vol": 500,
		  			"tier": "RB2",
		  			"retail_percent": 70,
		  			"reseller_cost": 49.00,
		  			"client_sr_price": 49.00
		  		},
		  		{
		  			"min_vol": 501,
		  			"max_vol": 1000,
		  			"tier": "RB3",
		  			"retail_percent": 65,
		  			"reseller_cost": 45.50,
		  			"client_sr_price": 45.50
		  		},
		  		{
		  			"min_vol": 1001,
		  			"max_vol": 2500,
		  			"tier": "RB4",
		  			"retail_percent": 60,
		  			"reseller_cost": 42.00,
		  			"client_sr_price": 42.00
		  		},
		  		{
		  			"min_vol": 2501,
		  			"max_vol": 5000,
		  			"tier": "RB5",
		  			"retail_percent": 55,
		  			"reseller_cost": 38.50,
		  			"client_sr_price": 38.50
		  		},
		  		{
		  			"min_vol": 5001,
		  			"max_vol": '',
		  			"tier": "RB6",
		  			"retail_percent": 50,
		  			"reseller_cost": 35.00,
		  			"client_sr_price": 35.00
		  		}
	  		],
	  		"direct":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": 100,
		  			"tier": "DB1",
		  			"retail_percent": 90,
		  			"reseller_cost": 63.00,
		  			"client_sr_price": 63.00
		  		},
		  		{
		  			"min_vol": 101,
		  			"max_vol": 500,
		  			"tier": "DB2",
		  			"retail_percent": 85,
		  			"reseller_cost": 59.50,
		  			"client_sr_price": 59.50
		  		},
		  		{
		  			"min_vol": 501,
		  			"max_vol": 1000,
		  			"tier": "DB3",
		  			"retail_percent": 80,
		  			"reseller_cost": 56.00,
		  			"client_sr_price": 56.00
		  		},
		  		{
		  			"min_vol": 1001,
		  			"max_vol": 2500,
		  			"tier": "DB4",
		  			"retail_percent": 75,
		  			"reseller_cost": 52.50,
		  			"client_sr_price": 52.50
		  		},
		  		{
		  			"min_vol": 2501,
		  			"max_vol": 5000,
		  			"tier": "DB5",
		  			"retail_percent": 70,
		  			"reseller_cost": 49.00,
		  			"client_sr_price": 49.00
		  		},
		  		{
		  			"min_vol": 5001,
		  			"max_vol": '',
		  			"tier": "DB6",
		  			"retail_percent": 65,
		  			"reseller_cost": 42.25,
		  			"client_sr_price": 45.50
		  		}
		  	],
	  	},
	  	"Edu-NP":{
	  		"reseller":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": 100,
		  			"tier": "RE1",
		  			"retail_percent": 40,
		  			"reseller_cost": 26.00,
		  			"client_sr_price": 26.00
		  		},
		  		{
		  			"min_vol": 101,
		  			"max_vol": 500,
		  			"tier": "RE2",
		  			"retail_percent": 36,
		  			"reseller_cost": 23.40,
		  			"client_sr_price": 23.40
		  		},
		  		{
		  			"min_vol": 501,
		  			"max_vol": 1000,
		  			"tier": "RE3",
		  			"retail_percent": 32,
		  			"reseller_cost": 20.80,
		  			"client_sr_price": 20.80
		  		},
		  		{
		  			"min_vol": 1001,
		  			"max_vol": 2500,
		  			"tier": "RE4",
		  			"retail_percent": 28,
		  			"reseller_cost": 18.20,
		  			"client_sr_price": 18.20
		  		},
		  		{
		  			"min_vol": 2501,
		  			"max_vol": 5000,
		  			"tier": "RE5",
		  			"retail_percent": 24,
		  			"reseller_cost": 15.60,
		  			"client_sr_price": 15.60
		  		},
		  		{
		  			"min_vol": 5001,
		  			"max_vol": '',
		  			"tier": "RE6",
		  			"retail_percent": 20,
		  			"reseller_cost": 13.00,
		  			"client_sr_price": 13.00
		  		}
	  		],
	  		"direct":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": 100,
		  			"tier": "DE1",
		  			"retail_percent": 40,
		  			"reseller_cost": 26.00,
		  			"client_sr_price": 26.00
		  		},
		  		{
		  			"min_vol": 101,
		  			"max_vol": 500,
		  			"tier": "DE2",
		  			"retail_percent": 36,
		  			"reseller_cost": 23.40,
		  			"client_sr_price": 23.40
		  		},
		  		{
		  			"min_vol": 501,
		  			"max_vol": 1000,
		  			"tier": "DE3",
		  			"retail_percent": 32,
		  			"reseller_cost": 20.80,
		  			"client_sr_price": 20.80
		  		},
		  		{
		  			"min_vol": 1001,
		  			"max_vol": 2500,
		  			"tier": "DE4",
		  			"retail_percent": 28,
		  			"reseller_cost": 18.20,
		  			"client_sr_price": 18.20
		  		},
		  		{
		  			"min_vol": 2501,
		  			"max_vol": 5000,
		  			"tier": "DE5",
		  			"retail_percent": 24,
		  			"reseller_cost": 15.60,
		  			"client_sr_price": 15.60
		  		},
		  		{
		  			"min_vol": 5001,
		  			"max_vol": '',
		  			"tier": "DE6",
		  			"retail_percent": 20,
		  			"reseller_cost": 13.00,
		  			"client_sr_price": 13.00
		  		}
	  		]
	  	},
  		"Govt/Military":{
  			"reseller":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": 100,
		  			"tier": "RG1",
		  			"retail_percent": 70,
		  			"reseller_cost": 49.00,
		  			"client_sr_price": 49.00
		  		},
		  		{
		  			"min_vol": 101,
		  			"max_vol": 500,
		  			"tier": "RG2",
		  			"retail_percent": 65,
		  			"reseller_cost": 45.50,
		  			"client_sr_price": 45.50
		  		},
		  		{
		  			"min_vol": 501,
		  			"max_vol": 1000,
		  			"tier": "RG3",
		  			"retail_percent": 60,
		  			"reseller_cost": 42.00,
		  			"client_sr_price": 42.00
		  		},
		  		{
		  			"min_vol": 1001,
		  			"max_vol": 2500,
		  			"tier": "RG4",
		  			"retail_percent": 65,
		  			"reseller_cost": 38.50,
		  			"client_sr_price": 38.50
		  		},
		  		{
		  			"min_vol": 2501,
		  			"max_vol": 5000,
		  			"tier": "RG5",
		  			"retail_percent": 50,
		  			"reseller_cost": 35.00,
		  			"client_sr_price": 35.00
		  		},
		  		{
		  			"min_vol": 5001,
		  			"max_vol": '',
		  			"tier": "RG6",
		  			"retail_percent": 45,
		  			"reseller_cost": 31.50,
		  			"client_sr_price": 31.50
		  		}
	  		],
	  		"direct":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": 100,
		  			"tier": "DG1",
		  			"retail_percent": 70,
		  			"reseller_cost": 45.50,
		  			"client_sr_price": 45.50
		  		},
		  		{
		  			"min_vol": 101,
		  			"max_vol": 500,
		  			"tier": "DG2",
		  			"retail_percent": 65,
		  			"reseller_cost": 42.25,
		  			"client_sr_price": 42.25
		  		},
		  		{
		  			"min_vol": 501,
		  			"max_vol": 1000,
		  			"tier": "DG3",
		  			"retail_percent": 60,
		  			"reseller_cost": 39.00,
		  			"client_sr_price": 39.00
		  		},
		  		{
		  			"min_vol": 1001,
		  			"max_vol": 2500,
		  			"tier": "DG4",
		  			"retail_percent": 55,
		  			"reseller_cost": 35.75,
		  			"client_sr_price": 35.75
		  		},
		  		{
		  			"min_vol": 2501,
		  			"max_vol": 5000,
		  			"tier": "DG5",
		  			"retail_percent": 50,
		  			"reseller_cost": 32.50,
		  			"client_sr_price": 32.50
		  		},
		  		{
		  			"min_vol": 5001,
		  			"max_vol": '',
		  			"tier": "DG6",
		  			"retail_percent": 45,
		  			"reseller_cost": 29.25,
		  			"client_sr_price": 29.25
		  		}
	  		]
	  	},
	  	"Edu-NPQ":{
	  		"direct":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": '',
		  			"tier": "DEQ1",
		  			"retail_percent": 40,
		  			"reseller_cost": 26.00,
		  			"client_sr_price": 26.00
		  		}
	  		]
	  	},
		"Student":{
	  		"reseller":[
		  		{
		  			"min_vol": 1,
		  			"max_vol": 100,
		  			"tier": "RS1",
		  			"retail_percent": 40,
		  			"reseller_cost": 28.00,
		  			"client_sr_price": 28.00
		  		},
		  		{
		  			"min_vol": 101,
		  			"max_vol": 500,
		  			"tier": "RS2",
		  			"retail_percent": 36,
		  			"reseller_cost": 25.20,
		  			"client_sr_price": 25.20
		  		},
		  		{
		  			"min_vol": 501,
		  			"max_vol": 1000,
		  			"tier": "RS3",
		  			"retail_percent": 32,
		  			"reseller_cost": 22.40,
		  			"client_sr_price": 22.40
		  		},
		  		{
		  			"min_vol": 1001,
		  			"max_vol": 2500,
		  			"tier": "RS4",
		  			"retail_percent": 28,
		  			"reseller_cost": 19.60,
		  			"client_sr_price": 19.60
		  		},
		  		{
		  			"min_vol": 2501,
		  			"max_vol": 5000,
		  			"tier": "RS5",
		  			"retail_percent": 24,
		  			"reseller_cost": 16.80,
		  			"client_sr_price": 16.80
		  		},
		  		{
		  			"min_vol": 5001,
		  			"max_vol": '',
		  			"tier": "RS6",
		  			"retail_percent": 20,
		  			"reseller_cost": 14.00,
		  			"client_sr_price": 14.00
		  		}
	  		],
	  		"direct":[
				{
					"min_vol": 1,
					"max_vol": 100,
					"tier": "RS1",
					"retail_percent": 40,
					"reseller_cost": 28.00,
					"client_sr_price": 28.00
				},
				{
					"min_vol": 101,
					"max_vol": 500,
					"tier": "RS2",
					"retail_percent": 36,
					"reseller_cost": 25.20,
					"client_sr_price": 25.20
				},
				{
					"min_vol": 501,
					"max_vol": 1000,
					"tier": "RS3",
					"retail_percent": 32,
					"reseller_cost": 22.40,
					"client_sr_price": 22.40
				},
				{
					"min_vol": 1001,
					"max_vol": 2500,
					"tier": "RS4",
					"retail_percent": 38,
					"reseller_cost": 19.60,
					"client_sr_price": 19.60
				},
				{
					"min_vol": 2501,
					"max_vol": 5000,
					"tier": "RS5",
					"retail_percent": 24,
					"reseller_cost": 16.80,
					"client_sr_price": 16.80
				},
				{
					"min_vol": 5001,
					"max_vol": '',
					"tier": "RS6",
					"retail_percent": 20,
					"reseller_cost": 14.00,
					"client_sr_price": 14.00
				}
	  		]
	  	}	

    }

  constructor() { }
}
