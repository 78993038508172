import { Component, Input } from '@angular/core';
import {MyteamsTeammatesTableComponent} from './../../teams/myteams-teammates-table/myteams-teammates-table.component'
@Component({
  selector: 'app-profile-desciption-popup',
  templateUrl: './profile-desciption-popup.component.html',
  styleUrls: ['./profile-desciption-popup.component.scss']
})
export class ProfileDesciptionPopupComponent {

  constructor(private teammatesCompo:MyteamsTeammatesTableComponent) { }

  @Input() profile: string;

  ngOnInit(): void {
    console.log(this.profile);
  }

  public close() {
    this.teammatesCompo.iconPfPopup = false;
  }

}
