import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss';
import { OrgsIndividualComponent } from './../orgs-individual/orgs-individual.component';
import { TeamReportServiceService } from '../team-report/team-report-service.service';
import { HttpService } from './../../http.service';
import { Store } from '@ngrx/store';
import FuzzySearch from 'fuzzy-search';
import { CommonDataService } from '../common-data.service';

@Component({
  selector: 'app-org-individual-teams',
  templateUrl: './org-individual-teams.component.html',
  styleUrls: ['./org-individual-teams.component.scss']
})
export class OrgIndividualTeamsComponent implements OnInit {

	id:any;
	teams:any;
	org_name:string;
	currentTeam:any;
	team_name:any;
	team_members:any;
	team_id:any;
	eTeam:any;
	modal:any;
	loading:any;
	teamName:any;
    sessions:any;
    modal1:any;
    sessionName:any;
    kind:any;
    temp:any;
    temp1:any;
    temp_name:any;
    temp_kind:any;
    temp2:any;
    temp3:any;
    teamWizard:any;
    globalGroup:any;
    teamID:any;
    unassignedTeam:any;
    teamMembers:any =[];
    teamsData;
    roasterData;
    roasterDataCounter:number = 0;
    allTeamIdsTtl:Array<object>= [];
    defaultShowingMembers = 5;
    filterData:any = "";
    filterInput:boolean = true;
    filterByTeam:string = 'tested';
    teamPopup = false;
    updatedTeamId:string = "";
    prevTeamName:string = "";
    membersDetails4NewTeam:any;
    popupType:string = 'team';
    newUseremail;
    collapse_expand_All:boolean = false;
    searchby:string = "search"
    @Input() totalMembers;
    filterRoster;
    clear:boolean= false;
    addTeamPopup:boolean= false;
    currentPg = 1;
    roasterdefaultCounter = 20
    memOnPg:number = 5;
    addPopupSaveBtnTxt = "Add Members";
    ngrxAccount:any;
    loginMemTeams;
    loadingTeams:boolean = true;
    selectAll = true;
    invite = false;
    invitationList:any;
    selectedInvitation = 'Select Invitation';
    inviteTeamId;
    invitePopup:boolean = false;
    addToTeamBtn:boolean = false;
    createNewTeamBtn:boolean = false;
    checkChanges = -1;
    @Input() rosterName;
    env;
    rosterSortType = 'asc';
    removeRosterMemType = 0;    // 1 for individual user and 2 for multiple user
    removeRosterMemDetails:any;
    remvoeTeamOrMem = 'member'
    removeTeamId;
    removeTeamIndex;
    loggedInUserEamil;
    rosterMemberCheckedCounter=0;
    gridHirView = 'grid';
    hirarchicalgridData=[];
    selectedFilterMbr = [];
    rosterSearch = false;
    selectedRosterMembers:any = []
    sortEmailIcon = 'asc';
    sortLastNameIcon = 'asc';

    // Sorting Defaults
    sortType: string = "asc";
    queryType: string = "first_name_order";

  	constructor(public activatedRoute:ActivatedRoute, public orgs: OrgsIndividualComponent, public http:HttpClient, public modalService:NgbModal, public route: Router,public teamReportService: TeamReportServiceService, private httpService: HttpService,public store:Store<any>, public CommonDataService:CommonDataService) { }

      ngOnDestroy() {
        // localStorage.removeItem("participantTeamData")
        // localStorage.removeItem("participantData")
      }    

  	async ngOnInit() {
        var that = this;
        this.env = environment
        this.id = this.orgs.id;
        this.teamWizard = false;
        this.loading = true;
        this.totalMembers = localStorage.getItem('orgTotalMember')
        this.rosterName = localStorage.getItem('currentOrgName')
        this.loggedInUserEamil = localStorage.getItem('email')
        this.ngrxAccount = this.store.select<any>((state) => state.profile.account);
        this.ngrxAccount.subscribe(async (res)=> {
            this.loginMemTeams = res["member"]?.teams_names
        })

        this.teamReportService.team_members.subscribe((res)=>{
            // console.warn(res)
            if(res.length > 0) {
                this.teamMembers= res;
            }
        })
        // getting teams api 
        await this.getTeamsData();
        this.org_name = this.route.url;
        this.org_name = this.org_name.replace('/teams', '');
        this.org_name = this.org_name.replace('/orgs/','')
        this.roasterMembers()
        this.getAllInvitation();
  	}

    // making hierarchical data
    hirerachicalData() {
        console.log('hirerachicalData')
        this.hirarchicalgridData = []
        this.gridHirView = 'hierar';
        localStorage.setItem('rosterDetailView','hierar')
        let firstChar = this.teams[0]['name'][0];
        let counter = 0
        let tempTeamArrNotIncludedNum = []
        if(parseInt(firstChar)) {
            console.log(' if parseInt')
            this.teams.forEach((team,ind) => {
                console.log('team', team, parseInt(team.name))
                if(parseInt(team.name)) {
                    if(this.hirarchicalgridData[team.name[0] - 1]) {
                        this.hirarchicalgridData[team.name[0] - 1].push(team)
                    } else {
                        console.log('else if', this.hirarchicalgridData)
                        // this.hirarchicalgridData[this.hirarchicalgridData.length ? this.hirarchicalgridData.length : 0] = []
                        // this.hirarchicalgridData[this.hirarchicalgridData.length - 1].push(team)
                        this.hirarchicalgridData[team.name[0] - 1] = []
                        this.hirarchicalgridData[team.name[0] - 1].push(team)
                    }
                } else {
                    tempTeamArrNotIncludedNum.push(team)
                }

            });
            console.log('tempTeamArrNotIncludedNum', tempTeamArrNotIncludedNum)
            this.hirarchicalgridData.push(tempTeamArrNotIncludedNum)
            console.log('hirarchicalgridData', this.hirarchicalgridData)
        } else {
            console.log('else')
            this.hirarchicalgridData[counter] = []
            this.teams.forEach((team,ind) => {
                this.hirarchicalgridData[counter].push(team)
                // counter += 1;
                // console.log(!this.hirarchicalgridData[counter])
                // if(!this.hirarchicalgridData[counter]) {
                // }
                // console.log(firstChar == team.name[0], team.name[0], firstChar)
                // // if(firstChar == team.name[0]) {
                // //     console.log('if',ind)
                // //     this.hirarchicalgridData[counter].push(team)
                // // } else {
                // //     console.log('else',ind)
                //     counter += 1;
                //     // if(!this.hirarchicalgridData[counter]) {
                //     //     this.hirarchicalgridData[counter] = []
                //     // }
                //     firstChar = team.name[0];
                // // }
            });
        }
        console.log(this.hirarchicalgridData)
    }

    gridView() {
        this.gridHirView = 'grid';
        localStorage.setItem('rosterDetailView','grid')
    }

    public getTeamsData() {
        let team_member_arr:any = [];
        let orgTeamData:any = this.httpService.getRequest(`${environment.profileURL}/api/v1/organizations/`+this.id+`/teams.json?${this.queryType}={this.sortType}`)
    	// await this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`/teams.json`)
        orgTeamData.subscribe(data => {
            // setTimeout(() => {
            //     this.accord('orgs_team__members_body', '' ,true)
            // }, 100);
            data["teams"].forEach(element => {
                team_member_arr.push({id:element.id,memberships_count:element?.memberships_count});
            });
            this.teamReportService.team_members.next(team_member_arr)
            // console.log('teams',data)
            // console.log('teams',team_member_arr)
           	this.teams = data['teams'];
           	this.team_members = this.teams[0].memberships;
          	this.team_name = this.teams[0].name;
          	this.team_id = this.teams[0].id;
          	this.currentTeam = 0;
            this.loadingTeams = false;
            this.teams.forEach((team)=> {
                this.allTeamIdsTtl.push({teamId: team.id, showMember: this.defaultShowingMembers, teamName: team.name, memberships_count: team.memberships_count}) 
            })

            // console.warn('allteam data',this.allTeamIdsTtl)

            // console.log(data);
            for(var i=0; i<this.teams.length; i++){
                if(this.teams[i].name == 'Unassigned'){
                    this.unassignedTeam = this.teams[i].id;
                } else {
                    this.teams[i].memberships = this.sortTeamMembers(this.teams[i].memberships)
                }
            }

            localStorage.getItem('rosterDetailView') == 'hierar' ? this.hirerachicalData() : this.gridView(); 

        },
            error => this.showError(error)
        )
    }

    // Get All Roster Members with pagination & Default Sort
    public roasterMembers(membersCount = 20, pageNo = 1) {
        this.currentPg = pageNo;
        this.loading = true;
        this.roasterData = this.httpService.getRequest(`${environment.profileURL}/api/v1/organizations/${this.id}/members.json?per_page=${membersCount}&page=${pageNo}&${this.queryType}=${this.sortType}`);
        this.roasterData.subscribe((res)=> {
            let item  = document.querySelectorAll('.teammates__head th input[type="checkbox"]')[0] as HTMLInputElement;
            // console.warn(item.checked)
            document.querySelectorAll('.teammates__head th input[type="checkbox"]')[0]
            if(item.checked) {
                $('.teammates__head th input[type="checkbox"]').click();
            }
            this.teamsData = res;
            this.loading = false;
            // console.log('teamData',this.teamsData)
            // console.log('teamData',this.teamsData.members)
            if(res?.meta?.total_count > this.totalMembers) {
                this.totalMembers = res?.meta?.total_count;
                localStorage.setItem('orgTotalMember', res.meta.total_count)
            }

            // console.log(this.selectedRosterMembers)
            // console.log(this.teamsData)
            if(this.selectedRosterMembers) {
                this.teamsData.members.map(item => {
                    const match = this.selectedRosterMembers.find(obj => obj.id === item.id);
                    if (match) {
                        item["checked"] = true;
                    }
                });
            }
            // this.pageNumber(1)
        })
    }

    public sortTeamMembers(memberships)  {
        function compare( a, b ) {
            let a_frst_name =  "no name";
            let b_frst_name =  "no name";
            if(a?.last_name) {
                a_frst_name = a.last_name.toLowerCase();
            } else if (a?.default_identity?.last_name) {
                a_frst_name = a.default_identity.last_name.toLowerCase();
            } else if (a?.default_profile?.last_name) {
                a_frst_name = a.default_profile.last_name.toLowerCase();
            }
            if(b?.last_name) {
                b_frst_name = b?.last_name?.toLowerCase();
            } else if (b?.default_identity?.last_name) {
                b_frst_name = b.default_identity.last_name.toLowerCase();
            } else if (b?.default_profile?.last_name) {
                b_frst_name = b.default_profile.last_name.toLowerCase();
            }
            // sort logic
            if ( a_frst_name < b_frst_name){
                return -1;
            }
            if ( a_frst_name > b_frst_name ){
                return 1;
            }
            return 0;
        }
        return memberships.sort( compare );
    }

  	public openTeam(record){ 
    //   console.log(record); 
  		if(record.open){
          record.open = false;
        }else{
          record.open = true;
        }
  	}
  	public openAll(){
  		if(this.currentTeam == 11111){
          this.currentTeam = 0;
        }else {
          this.currentTeam = 11111;
        }
  	}
  	public cancelUpdate(){
      this.eTeam  = -1;
      this.temp = this.temp1;
      this.temp_name = this.temp2;
      this.temp_kind = this.temp3;
    };
    public editTeam(record){
        this.temp = record.name;
        this.temp1 = record.name;
        this.eTeam  = record.id;
    };

    // Update Filter for Roster Member Search
    public updateFilter(event) {
        const val = event.target.value.toLowerCase();
        this.searchby = val;
        // console.log(this.filterData)
        // this.filterInput = false;
    }

    // open and close team popup
    public openCloseTeamPopup(open= false,teamId='',teamName='') {
        this.teamPopup = open;
        this.updatedTeamId = teamId;
        if(open) {
            this.temp = teamName;
        } else {
            this.teams.forEach((team)=> {
                if(this.updatedTeamId == team.id) {
                    team.name = this.temp;
                }
            })
        }
    }

    // update team name 
    public async UpdateTeam(){
        this.teams.forEach(team => {
            if(team.id == this.updatedTeamId) {
                team.name = this.temp
            }
        });
        if(this.updatedTeamId) {
            this.loading = true;
            let updateTeamNameRes:any = await this.httpService.putRequest(`${environment.profileURL}/api/v1/organizations/`+this.id+'/teams/'+this.updatedTeamId+'.json',{name:this.temp})
            updateTeamNameRes.subscribe(result => {
                // console.log(result);
                this.eTeam = -1;
                this.selectAllRoaster(false)
                this.openCloseTeamPopup()
                function compare( a, b ) {
                    if ( a?.name.toLowerCase() < b?.name.toLowerCase() ){
                        return -1;
                    }
                    if ( a?.name.toLowerCase() > b?.name.toLowerCase() ){
                        return 1;
                    }
                    return 0;
                }
                this.teams.sort( compare )
                this.allTeamIdsTtl = []
                this.teams.forEach((team,index)=> {
                    this.allTeamIdsTtl.push({teamId: team.id, showMember: this.defaultShowingMembers, teamName: team.name, memberships_count: team.memberships_count}) 
                })
                this.hirerachicalData()
                this.loading = false;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('The Team Name was updated successfully!', 'Nice!');
                }, 1000);
            }, err=> {
                console.log(err)
                this.loading = false;
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(err?.error?.errors, 'Oops!');
                }, 1000);
            })
        }
    }

    // open add new team popup
    public open(type, teamId = '', createTeamBtn:boolean = false){ 
        this.createNewTeamBtn = createTeamBtn;       
        this.popupType = type;
        this.teamName = '';
        this.updatedTeamId = teamId;
        // this.modal = this.modalService.open(content, { windowClass: 'in' });
        if(type) {
            $('.team-modal').css('display','block');
        } else {
            $('.team-modal').css('display', 'none');
        }
        this.loading = false;
    }

    // add member to team
    public async addTeam(){
        let newTeamId;
        let ind;
        if(this.teamName){
        	this.loading = true;
            let addTeamMemb:any = await this.httpService.postRequest(`${environment.profileURL}/api/v1/organizations/`+this.id+'/teams.json',{ name: this.teamName, organization_id: this.id })
        	addTeamMemb.subscribe(result => {
    	        // console.log(result);
    	        this.loading = false;
                newTeamId = result.team.id
                if(this.createNewTeamBtn) {
                    this.inviteTeamId = result.team.id
                }
                this.teams.push(result['team']);
                // console.warn('adfasdf',this.teams)
                function compare( a, b ) {
                    if ( a?.name.toLowerCase() < b?.name.toLowerCase() ){
                        return -1;
                    }
                    if ( a?.name.toLowerCase() > b?.name.toLowerCase() ){
                        return 1;
                    }
                    return 0;
                }
                this.teams.sort( compare )
                this.allTeamIdsTtl = []
                this.teams.forEach((team,index)=> {
                    if(team.id == newTeamId && this.createNewTeamBtn) {
                        // console.log(index)
                        team["checked"] = true;
                        let teamInd:any = this.teams.length / 2;
                        if(index > teamInd) {
                            ind = index - 1;
                        }else {
                            ind = index
                        }
                    }
                    this.allTeamIdsTtl.push({teamId: team.id, showMember: this.defaultShowingMembers, teamName: team.name, memberships_count: team.memberships_count}) 
                })
                if(this.gridHirView == 'hierar') {
                    this.hirerachicalData()
                }
                this.selectedRosterMembers = []
                // let scroller = document.querySelector(".orgs_team")
                // window.scrollTo(0,scroller.scrollHeight)
                $('.team-modal').css('display','none')
                // setTimeout(() => {
                //     $('.teams_popup__team_item').eq(ind).click()
                // }, 1000);
                setTimeout(() => {
                    // console.warn(this.teams)
                }, 5000);
                this.team_name = this.teamName;

    	    },
            error => {
                this.showError(error)
                this.loading = false;
                this.teamName = '';
            }
            )
        }
    }

    // delete team
    public async deleteTeam(id,index){
    	// console.log(index);
    	let delTeam:any = await this.httpService.deleteRequest(`${environment.profileURL}/api/v1/organizations/`+this.id+'/teams/'+id+'.json');
        delTeam.subscribe(result => {
            // console.log(result);
            $('.roster-del-mem').css('display','none')
            this.teams.forEach((tm,ind) => {
                if(tm.id == id) {
                    // console.log("tm.id id",ind)
                    index = ind
                    tm.memberships.forEach(mbr => {
                        this.teamsData.members.forEach((mem,rInd)=> {
                            if(mbr.member_id == mem.id) {
                                // console.warn("counter updated",mem)    
                                delete this.teamsData.members[rInd].teams_names[id]
                                this.teamsData.members[rInd].non_admin_unassgined_teams_count -= 1;
                            } 
                        })
                    });
                }
            });
            this.teams.splice(index,1);
            this.allTeamIdsTtl = []
            this.teams.forEach((team)=> {
                this.allTeamIdsTtl.push({teamId: team.id, showMember: this.defaultShowingMembers, teamName: team.name, memberships_count: team.memberships_count}) 
            })
            console.log('hirerachicalData view',this.gridHirView)
            if(this.gridHirView == 'hierar') {
                this.hirerachicalData()
            }
            // setTimeout(()=> {
            //     this.teamReportService.team_members.next([]);
            //     this.ngOnInit();
            // },1000)
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('The Team was deleted successfully!', 'Nice!');
            }, 1000);
            $('.roster-del-mem').css('display','none')
            this.selectAllRoaster(false)
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err, 'Sorry!');
            }, 1000);
        })
    }

    public showError(error){
        console.log('oops', error);
        if(error.status == 500){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'Sorry!');
            }, 1000);
        }
        else if(error.status == 422){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 4000
                };
                this.toastr.error(error.error.errors, 'oops!');

            }, 1000);
        }
        else if(error.status == 406){
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 4000
                };
                this.toastr.error(error.error.errors, 'oops!');

            }, 1000);
        }
        else{
            setTimeout(function() {
                this.toastr.options = {
                  closeButton: true,
                  timeOut: 2000
                };
                this.toastr.error('Something went wrong!', 'oops!');
            }, 1000);
        }

    }

    // open close add member to additional team
    public additionalTeamPopup(memberDetail:any = null, invitepop = false) {
        // console.log('invtitation', invitepop)
        if(invitepop) {
            this.addToTeamBtn = true;
        } else {
            this.selectAllRoaster(false)
            this.rosterMemberCheckedCounter = 0
            this.addToTeamBtn = false;
        }
        this.invitePopup = invitepop;
        // console.warn("set data for new team member",memberDetail)
        this.membersDetails4NewTeam = memberDetail;
        // console.log('',this.membersDetails4NewTeam)
        this.addTeamPopup = true;
        // if(memberDetail == null) {
        //     this.teams.forEach((team,index)=> {
        //         team.checked = false;
        //     })
        // }
    }

    // close additional team popup
    public additionalTeamPopupClose(checkChangesPopup:boolean = true) {
        // console.log(this.teams)
        if(!checkChangesPopup) {
            this.checkChanges = -1;
        }
        if(this.checkChanges == 0) {
            this.checkChanges = 1
        } else {
            this.addTeamPopup = false;
            this.membersDetails4NewTeam = null
            this.invite = false;
            this.inviteTeamId = null;
            this.teams.forEach((team,index)=> {
                team.checked = false;
            })
            this.selectedRosterMembers = []
        }
        this.selectAllRoaster(false)
    }

    // load more
    private loadMore(teamId='',roaster=0, allMembers: false) {
        // console.log('loadMore');
        if(roaster > 0) {
            // console.log('roster data',this.teamsData?.members)
            if(allMembers) {
                this.roasterDataCounter = this.teamsData?.members.length;
            } else {
                this.roasterDataCounter = 20
            }
        } else {
            // console.warn('allteamttlbefore',this.allTeamIdsTtl)
            this.allTeamIdsTtl.forEach((data:any,index)=> {
                // console.warn('teamID',data.teamId,"==>",teamId)
                if(data["teamId"] == teamId) {
                    // console.log('load more',data)
                    if(data.memberships_count > 20) {
                        if(data.memberships_count > 20 && this.teams[index].memberships.length < data.memberships_count) {
                            $(`.${teamId}_loader`).css('display','block');
                            let allTeamMem:any = this.httpService.getRequest(`${environment.profileURL}/api/v1/teams/`+teamId+`/memberships.json?name_order=asc&per_page=${data.memberships_count}`)
                            allTeamMem.subscribe((res)=> {
                                this.teams[index].memberships = res.memberships;
                                $(`.${teamId}_loader`).css('display','none');
                                // console.log("showing all members",this.teams);
                            })
                        }
                    }
                    if(allMembers) {
                        this.allTeamIdsTtl[index]["showMember"] = this.defaultShowingMembers;
                    }else {
                        this.allTeamIdsTtl[index]["showMember"] = data.memberships_count;
                    }
                }
            })
            // console.warn('allteamttlafter',this.allTeamIdsTtl)
        }
    }

    // promote to lead (make team leader)
    private async mkTeamLead(member, role_id) {
        // console.log('members', member);
        let attr = {membership_role_id: role_id};
        // console.log(this.teams);
        
         // Hit API for Making Lead
         let leadResponse:any = await this.httpService.putRequest(`${environment.profileURL}/api/v1/identities/${member.identity_id}/memberships/${member.id
         }.json`, attr)
        leadResponse.subscribe((res)=> {
            // console.log(res["membership"])
            this.teams.forEach((team)=> {
                if(team.id == member.team_id) {
                    team.memberships.forEach((teamMem) => {
                        if(member.id == teamMem.id) {
                            // console.log(res)
                            teamMem.membership_role_id = res["membership"]?.membership_role_id
                            // console.log('id match');
                            
                        }
                    });
                }
            })
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success("Member's role was updated successfully!", 'Nice!');
            }, 500);
        })
    }

    // sort raoster member  [sort type will be asc and dsc]
    public async sortRoaster(sType:string, qType="last_name_order") {
        this.rosterSearch = true;
        // if(sortType == 'asc') {
        //     sortType = 'desc'
        // } else {
        //     sortType = 'asc'
        // }
        this.sortType = sType;
        this.queryType = qType;
        

        let ascDesc:any = await this.httpService.getRequest(`${environment.profileURL}/api/v1/organizations/${this.id}/members.json?per_page=${this.roasterdefaultCounter}&${this.queryType}=${this.sortType}`);
        ascDesc.subscribe((sortedList:any)=> {
            // console.log(sortedList)
            setTimeout(() => {
                this.rosterSearch = false;
            }, 1000);
            this.teamsData.members = sortedList.members
            if(this.queryType == 'email_order') {
                this.sortEmailIcon = this.sortType
            } else if(this.queryType == 'last_name_order') {
                this.sortLastNameIcon = this.sortType
            } else {
                this.rosterSortType = this.sortType
            }
        })
        // console.warn('sorted' + this.sortType,)
    }

    // fuzzy search when search type is search
    searchFuzzyRes = (res:any) => {
        let resultMembers:any = res?.members
        let resultArr = []
        const searchParams = ['email','first_name', 'last_name']
        const searchFunc = (type)=> {
            let searchBasedOn = type
            const searcher = new FuzzySearch(resultMembers, [searchBasedOn], {
                caseSensitive: false,
            });
            resultArr = searcher.search(this.filterRoster)
            resultMembers= resultMembers.filter(item1 => 
                !resultArr.some((item2:any) => item1.id === item2.id)
            );
        }
        searchParams.forEach((item)=> {
            if(resultMembers.length > 0) {
                searchFunc('default_identity.' + item)
            }
            console.log(resultArr)
            console.log(resultMembers)
        })
        return resultArr
    }


    // roaster filter function
    public async searchRoaster(value, showHide, clear=false) {
        // console.log("VALUE", value);
        this.filterInput = showHide;
        if(value !== "") this.clear = true;
        if(!value) this.filterData = "";
        // console.log(this.filterRoster)
        if(clear) {
            setTimeout(() => {
                this.filterData = "";
                this.filterRoster = "";
                this.clear = false;
            }, 100);
        }
        // console.warn('Filter Data ->', this.filterData)
        if(value.length > 2 && !clear) {
            this.rosterSearch = true;
            this.clear = false;
            let searchRes:any = await this.httpService.getRequest(`${environment.profileURL}/api/v1/organizations/${this.id}/members.json?per_page=${this.roasterdefaultCounter}&${this.searchby}=${value}`);
            searchRes.subscribe((res:any)=> {
                // console.log(res)
                if(res?.members?.length) {
                    this.filterData = res.members;
                    let searchBasedOn = this.searchby
                    if(this.searchby == 'search') {
                        this.filterData =this.searchFuzzyRes(res)
                    } else {
                        // console.log(searchBasedOn)
                        const searcher = new FuzzySearch(res.members, [searchBasedOn], {
                            caseSensitive: false,
                        });
                        this.filterData = searcher.search(this.filterRoster)
                        this.filterData = res.members;
                    }
                    // console.log(searcher.search(this.filterRoster))
                } else {
                    this.filterData = "Member not Found"
                    this.rosterSearch= false
                }
                this.clear = true;

                // console.warn('your filter data', this.filterData)
                setTimeout(() => {
                    if(this.selectedFilterMbr) {
                        this.filterData?.map(item => {
                            const match = this.selectedFilterMbr.find(obj => obj.id === item.id);
                            if (match) {
                                item["checked"] = true;
                            }
                        });
                    }
                    this.rosterSearch = false;
                    // console.log(this.selectedFilterMbr)
                    // console.log(this.filterData)
                }, 500);
            })

        }else{
            // console.log('clear');
            this.clear = true;
            this.rosterSearch = false;
        }
        // this.http.get(`${environment.profileURL}/api/v1/organizations/${this.id}/members.json?per_page=20&${queryType}=${value}`)
        
    }

    // add new teammate or new roaster member
    public async addNewTeamMate() {
        let emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if(this.newUseremail.match(emailReg)) {
            this.teams.forEach(async (team,index) => {
                if(team.name == "Unassigned") {
                    let newTeammate:any = await this.httpService.postRequest(`${environment.profileURL}/api/v1/teams/${team.id}/add_members.json`,{
                        members_attributes: {
                          "0": {
                            email: this.newUseremail,
                            role_id: 3
                          }
                        }
                    })
                    newTeammate.subscribe((res:any)=> {
                        // console.warn('result',res,'===>',this.newUseremail)
                            res.team.memberships.forEach(mem => {
                                if(this.newUseremail == mem.email) {
                                    // console.log(mem)
                                    mem["non_admin_unassgined_teams_count"] = 0;
                                    this.teamsData.members.push(mem)
                                }
                            });
                        // this.teams.forEach((team:any,index) => {
                            // if(team.id == this.updatedTeamId) {
                                this.teams[index].memberships = res?.team?.memberships;
                                this.teams[index].memberships_count = res?.team?.memberships_count;
                                // this.teams[index].memberships = this.sortTeamMembers(this.teams[index].memberships)
                            // }
                        // });
                        this.open('')
                        this.newUseremail = "";
                    })
                    // this.newUseremail = "";
                }
            })
        }
    }


    // add roster member to team 
    async addRosterMemberInTeam() {
        var that = this;
        let data:any;
        let members_attributes = {}
        let checkedMemberEmail = [];
        // console.log("filterData", this.filterData)
        // console.warn("if block", (this.searchby == 'invitation' && this.selectedInvitation != 'Select Invitation') || this.filterData)
        if((this.searchby == 'invitation' && this.selectedInvitation != 'Select Invitation') || this.filterData) {
            // console.warn("if block")
            data = this.filterData
            this.selectedFilterMbr.forEach((mbr,index)=> {
                let itemExist = false;
                this.filterData.forEach((fmbr)=> {
                    if(mbr.id == fmbr.id) {
                        itemExist = true;
                    }
                })
                if(!itemExist) {
                    data.push(mbr)
                }
            })
        } else if(this.selectedRosterMembers.length > 0) {
            data = this.selectedRosterMembers
        }
         else {
            data = this.teamsData.members
        }

        await data.forEach((mbr,mbrIndex)=> {
            if(mbr.checked) {
                // console.log(mbr)
                checkedMemberEmail.push(mbr?.default_identity.email ? mbr?.default_identity.email : mbr?.default_profile.email)
                mbr.checked = false;
                members_attributes[mbrIndex] = {
                    email: mbr?.default_identity.email ? mbr?.default_identity.email : mbr?.default_profile.email,
                    role_id: 3
                }
            }
        })
        // adding list of members in team
        let teamCheckedCounter = 0;
        await new Promise((resolve, reject) => {
            setTimeout(async () => {
                    resolve(
                        await this.teams.forEach(async (team,index)=> {
                            if(team.checked) {
                                teamCheckedCounter++;
                                // console.warn("inc",teamCheckedCounter)
                                let newTeammates:any = await this.httpService.postRequest(`${environment.profileURL}/api/v1/teams/${team.id}/add_members.json`,{
                                    members_attributes
                                })
                                $(`.${team.id}_loader`).css('display','block');
                                newTeammates.subscribe((res:any)=> {
                                    // console.log(res.team.memberships)
                                    this.teams[index].memberships = res?.team?.memberships;
                                    this.teams[index].memberships_count = res?.team?.memberships_count;
                                    $(`.${team.id}_loader`).css('display','none');
                                    teamCheckedCounter= teamCheckedCounter - 1;
                                    if(teamCheckedCounter == 0) {
                                        this.roasterMembers(this.roasterdefaultCounter, this.currentPg)
                                    }
                                    // console.warn("dec",teamCheckedCounter)
                                }, err => {
                                    $(`.${team.id}_loader`).css('display','none');
                                    setTimeout(function() {
                                        this.toastr.options = {
                                            closeButton: true,
                                            progressBar: true,
                                            timeOut: 2000
                                        };
                                        this.toastr.error(err?.error?.errors, 'Oops!');
                                        that.addTeamPopup = false;
                                        this.membersDetails4NewTeam = null;
                                        // that.membersDetails4NewTeam.non_admin_unassgined_teams_count += 1; 
                                    }, 1000);
                                })
                
                            }
                        })
                    )
                }, 100)
        }).then((res)=> {
            // console.log('teamcheckedcounter',teamCheckedCounter)
            this.allTeamIdsTtl = [];
            this.teams.forEach((team,tmInd) => {
                if(team.checked) {
                    team.checked = false;
                    data.forEach(mbr => {
                        let email = mbr?.default_identity.email ? mbr?.default_identity.email : mbr?.default_profile.email;
                        if(checkedMemberEmail.includes(email)) {
                            // console.log(checkedMemberEmail.filter(String).length) // count string in array
                            this.teams[tmInd].memberships.push({email: mbr.email})
                            // console.log(this.teams[tmInd].memberships_count)
                            mbr.teams_names[team.id] = team.name
                        }
                        mbr.checked = false;  
                    })
                    this.teams[tmInd].memberships_count = this.teams[tmInd].memberships_count + checkedMemberEmail.filter(String).length
                }
                $(`.${team.id}_loader`).css('display','none');
                this.allTeamIdsTtl.push({teamId: team.id, showMember: this.defaultShowingMembers, teamName: team.name, memberships_count: team.memberships_count})
            });

            if(this.gridHirView == 'hierar') {
                this.hirerachicalData()
            }

            // remove selected filter member from data
            this.selectedFilterMbr.forEach((mbr,index)=> {
                data.pop(mbr)
            })
            
            if(checkedMemberEmail.filter(String).length <= 1) {
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Member is added successfully!', 'Great!');
                    that.addTeamPopup = false;
                    this.membersDetails4NewTeam = null;
                    this.selectedFilterMbr = []
                    that.selectedRosterMembers = [];
                    // that.membersDetails4NewTeam.non_admin_unassgined_teams_count += 1; 
                }, 1000);
            } else {
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.success('Members are added successfully!', 'Great!');
                    that.addTeamPopup = false;
                    this.selectedFilterMbr = []
                    that.selectedRosterMembers = [];
                    this.membersDetails4NewTeam = null;
                    // that.membersDetails4NewTeam.non_admin_unassgined_teams_count += 1; 
                }, 1000);
            }
        })
        this.selectAllRoaster(false)
    }

    // add member from inivitation
    public async addInvitationMem() {
        // console.log('addInvitationMem',this.teamsData.members, this.teams)
        let data:any;
        if(this.searchby == 'invitation' && this.selectedInvitation != 'Select Invitation') {
            data = this.filterData
        } else {
            data = this.teamsData.members
        }
        data?.forEach(async (mem)=> {
            if(mem?.checked) {
                mem.checked = false;
                this.membersDetails4NewTeam = mem;
                await this.teams.forEach(async (team,index)=> {
                    // console.log(team)
                    let listMembers = { 'team_id': team.id, 'membership_role_id': 3 };
                    let identity_id = this.membersDetails4NewTeam?.identity_id ? this.membersDetails4NewTeam?.identity_id : this.membersDetails4NewTeam?.default_identity?.id 
                    // identity_id = this.membersDetails4NewTeam?.default_identity?.id ? this.membersDetails4NewTeam?.default_identity?.id : this.membersDetails4NewTeam?.default_profile?.identity_id
                    if(team?.checked == true) {
                        // console.log('index',index)
                        let addMembersToTeam:any = await this.httpService.postRequest(`${environment.profileURL}/api/v1/identities/`+identity_id+'/memberships.json',listMembers)
                        addMembersToTeam.subscribe((result:any) => {
                            result.membership["teams_names"] = this.membersDetails4NewTeam?.teams_names;
                            Object?.assign(result?.membership?.teams_names,{ [team.id] :team.name});
                            this.teams[index]?.memberships?.push(result.membership);
                            // console.warn(this.teams[index]?.memberships)
                            this.teams[index].memberships_count += 1;
        
                            // add teamname in roaster member teams_names
                            this.teamsData.members.forEach((mem,rInd)=> {
                                if(this.membersDetails4NewTeam?.id == mem?.id || this.membersDetails4NewTeam.member_id == mem.id || identity_id == mem?.default_identity?.id) {
                                    // console.warn("added item",mem)    
                                    this.teamsData.members[rInd].teams_names[team.id] = team.name;
                                    this.teamsData.members[rInd].non_admin_unassgined_teams_count += 1;
                                    if(this.filterData) {
                                        this.filterData.forEach((fltrData,fltrInd) => {
                                            // console.warn('filter data',fltrData.default_identity.id == mem.default_identity.id)
                                            if(fltrData.default_identity.id == mem.default_identity.id) {
                                                this.filterData[fltrInd] = mem;
                                            }
                                        });
                                    }
                                } 
                            })
                            this.allTeamIdsTtl = [];
                            this.teams.forEach((tm) => {
                                tm.memberships.forEach(mbr => {
                                    if(this.membersDetails4NewTeam?.id == mbr?.id || this.membersDetails4NewTeam.id == mbr.member_id || this.membersDetails4NewTeam.member_id == mbr.member_id) {
                                        mbr.teams_names = result.membership.teams_names
                                    }
                                });
                                this.allTeamIdsTtl.push({teamId: tm.id, showMember: this.defaultShowingMembers, teamName: tm.name, memberships_count: tm.memberships_count})
                            });
        
                            // update filter data
                            if(this.filterData != "") {
                                this.filterData.forEach((mem)=> {
                                    // console.log(mem)
                                    if(identity_id == mem?.default_identity?.id) {
                                        mem.non_admin_unassgined_teams_count += 1;
                                        mem.teams_names[team.id] = team.name
                                    }
                                })
                            }
        
                            
                
                            var that = this;
                            // this.loading = 2;
                            setTimeout(function() {
                                this.toastr.options = {
                                    closeButton: true,
                                    progressBar: true,
                                    timeOut: 2000
                                };
                                this.toastr.success('Members were added successfully!', 'Great!');
                                that.addTeamPopup = false;
                                this.membersDetails4NewTeam = null;
                                // that.membersDetails4NewTeam.non_admin_unassgined_teams_count += 1; 
                                team.checked = false;
                            }, 1000);
                            this.loading = false;
                        },(error)=> {
                            console.log('error message', error)
                            if(error.error.errors) {
                              setTimeout(function() {
                                this.toastr.options = {
                                    closeButton: true,
                                    progressBar: true,
                                    timeOut: 2000
                                };
                                this.toastr.error(`${error.error.errors}`, 'Oops!');
                              }, 1000);
                            }
                            this.loading = false;
                          })
                    } else if(team?.checked == -1) {
                        team.memberships.forEach(async (element) => {
                            // console.log(element, identity_id)
                            if(element.identity_id == identity_id) {
                                this.membersDetails4NewTeam.tempId = this.membersDetails4NewTeam.id;
                                this.membersDetails4NewTeam.id = element.id;
                                await this.removedMember(this.membersDetails4NewTeam,team.id)
                                team.checked = false;
                            }
                        });
                    }
                })
            }
        })
        this.selectAllRoaster(false);
    }

     // add members to team
     public async addTeamMembers() {
        // console.warn('teamID==>',this.teams)
        // console.warn('team mem detail==>',this.membersDetails4NewTeam)
        // console.warn('roasterData',this.teamsData)
        var that = this;
        // console.log('invite',this.invitePopup, 'addtoteambtn', this.addToTeamBtn)
        if(this.invitePopup || this.addToTeamBtn) {
            this.addInvitationMem();
        } else {
            this.checkChanges = -1;
            await this.teams.forEach(async (team,index)=> {
                // console.log('checked value',team.checked)
                let listMembers = { 'team_id': team.id, 'membership_role_id': 3 };
                let identity_id = this.membersDetails4NewTeam?.identity_id ? this.membersDetails4NewTeam?.identity_id : this.membersDetails4NewTeam?.default_identity?.id 
                // identity_id = this.membersDetails4NewTeam?.default_identity?.id ? this.membersDetails4NewTeam?.default_identity?.id : this.membersDetails4NewTeam?.default_profile?.identity_id
                if(team?.checked == true) {
                    // console.log('index',index)
                    let addMembersToTeam:any = await this.httpService.postRequest(`${environment.profileURL}/api/v1/identities/`+identity_id+'/memberships.json',listMembers)
                    addMembersToTeam.subscribe((result:any) => {
                        result.membership["teams_names"] = this.membersDetails4NewTeam.teams_names;
                        Object.assign(result.membership.teams_names,{ [team.id] :team.name});
                        this.teams[index].memberships.push(result.membership);
                        // console.warn(this.teams[index].memberships)
                        this.teams[index].memberships_count += 1;
    
                        // add teamname in roaster member teams_names
                        this.teamsData.members.forEach((mem,rInd)=> {
                            if(this.membersDetails4NewTeam?.id == mem?.id || this.membersDetails4NewTeam.member_id == mem.id || identity_id == mem?.default_identity?.id) {
                                // console.warn("added item",mem)    
                                this.teamsData.members[rInd].teams_names[team.id] = team.name;
                                this.teamsData.members[rInd].non_admin_unassgined_teams_count += 1;
                                if(this.filterData) {
                                    this.filterData.forEach((fltrData,fltrInd) => {
                                        // console.warn('filter data',fltrData.default_identity.id == mem.default_identity.id)
                                        if(fltrData.default_identity.id == mem.default_identity.id) {
                                            this.filterData[fltrInd] = mem;
                                        }
                                    });
                                }
                            } 
                        })
                        this.allTeamIdsTtl = [];
                        this.teams.forEach((tm) => {
                            tm.memberships.forEach(mbr => {
                                if(this.membersDetails4NewTeam?.id == mbr?.id || this.membersDetails4NewTeam.id == mbr.member_id || this.membersDetails4NewTeam.member_id == mbr.member_id) {
                                    mbr.teams_names = result.membership.teams_names
                                }
                            });
                            this.allTeamIdsTtl.push({teamId: tm.id, showMember: this.defaultShowingMembers, teamName: tm.name, memberships_count: tm.memberships_count})
                        });
    
                        // update filter data
                        if(this.filterData != "") {
                            this.filterData.forEach((mem)=> {
                                // console.log(mem)
                                if(identity_id == mem?.default_identity?.id) {
                                    mem.non_admin_unassgined_teams_count += 1;
                                    mem.teams_names[team.id] = team.name
                                }
                            })
                        }
    
                        
            
                        var that = this;
                        // this.loading = 2;
                        setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.success('Members were added successfully!', 'Great!');
                            that.addTeamPopup = false;
                            // that.membersDetails4NewTeam.non_admin_unassgined_teams_count += 1; 
                            team.checked = false;
                            this.addToTeamBtn = false
                        }, 1000);
                        this.loading = false;
                    },(error)=> {
                        console.log('error message', error)
                        if(error.error.errors) {
                          setTimeout(function() {
                            this.toastr.options = {
                                closeButton: true,
                                progressBar: true,
                                timeOut: 2000
                            };
                            this.toastr.error(`${error.error.errors}`, 'Oops!');
                          }, 1000);
                        }
                        this.loading = false;
                      })
                } else if(team?.checked == -1) {
                    team.memberships.forEach(async (element) => {
                        // console.log(element, identity_id)
                        if(element.identity_id == identity_id) {
                            this.membersDetails4NewTeam.tempId = this.membersDetails4NewTeam.id;
                            this.membersDetails4NewTeam.id = element.id;
                            await this.removedMember(this.membersDetails4NewTeam,team.id)
                            team.checked = false;
                        }
                    });
                    this.addToTeamBtn = false
                }
            })
        }
        this.loading = false;
        // this.additionalTeamPopup();
    }


    public async removedMember(member,teamId = "") {
        // console.warn('mymember details', member)
        var that = this;
        let identity_id = member?.identity_id ? member?.identity_id : member.default_identity.id ? member.default_identity.id : member.default_profile.identity_id;
        // let delMem = await this.httpService.deleteRequest(`${environment.profileURL}/api/v1/identities/${identity_id}/memberships/${member.id}.json`);
        this.allTeamIdsTtl = []
        this.teams.forEach((team:any,index) => {
            team.memberships.forEach((mem:any,memIndex) => {
                if(identity_id == mem?.identity_id) {
                    // console.warn('entered===============>',teamId)
                    if(teamId) {
                        delete mem?.teams_names[teamId]
                    } else if(member?.team_id && member?.teams_names) {
                        // console.log('mem details::::',member?.teams_names,mem)
                        delete mem?.teams_names[member?.team_id]
                    }
                }
                // console.warn("====================>",mem.id == member.id)
                if(mem.id == member.id) {
                    if(teamId && mem?.team_id == teamId) {
                        this.teams[index].memberships.splice(memIndex, 1);
                        if(this.teams[index].memberships_count) {
                            this.teams[index].memberships_count -= 1;
                            // that.membersDetails4NewTeam.non_admin_unassgined_teams_count -= 1; 
                        }
                    } else if(!teamId &&  member?.team_id == team.id) {
                        this.teams[index].memberships.splice(memIndex, 1);
                        if(this.teams[index].memberships_count) {
                            this.teams[index].memberships_count -= 1;
                            // that.membersDetails4NewTeam.non_admin_unassgined_teams_count -= 1; 
                        }
                    }
                }
            })
            this.allTeamIdsTtl.push({teamId: team.id, showMember: this.defaultShowingMembers, teamName: team.name, memberships_count: team.memberships_count}) 
        });

        if(teamId) {
            // console.warn('not blanked')
            delete that.membersDetails4NewTeam?.teams_names[teamId]
            // that.membersDetails4NewTeam.non_admin_unassgined_teams_count -= 1; 
            that.teamsData.members.forEach((mem,ind)=> {
                if(member?.member_id == mem?.id || member?.id == mem?.id || mem?.default_identity?.id == identity_id) {
                    that.teamsData.members[ind].non_admin_unassgined_teams_count -= 1; 
                    delete that.teamsData.members[ind].teams_names[teamId] 
                    // console.log('removed here')
                }
            })

            // update filter data
            if(this.filterData != "") {
                this.filterData.forEach((mem)=> {
                    // console.log(mem)
                    if(identity_id == mem?.default_identity?.id) {
                        mem.non_admin_unassgined_teams_count -= 1;
                        delete mem.teams_names[teamId]
                    }
                })
            }

        } else if(teamId == "") {
            // console.warn('blank id')
            // console.log(that.teamsData);
            // console.warn(member)
            that.teamsData.members.forEach((mem,ind)=> {
                if(identity_id == mem?.default_identity?.id) {
                    let tm_id = member?.team_id
                    delete that.teamsData.members[ind]?.teams_names[tm_id];
                    // console.log('minus from here')
                    that.teamsData.members[ind].non_admin_unassgined_teams_count -= 1; 
                }
            })
            // update filter data
            if(this.filterData != "") {
                this.filterData.forEach((mem)=> {
                    // console.log(mem)
                    if(identity_id == mem?.default_identity?.id) {
                        let tm_id = member?.team_id
                        mem.non_admin_unassgined_teams_count -= 1;
                        delete mem.teams_names[tm_id]
                    }
                })
            }
        }
        
        await this.http.delete(`${environment.profileURL}/api/v1/identities/${identity_id}/memberships/${member.id}.json`).subscribe(async (result) => {
            // console.log(result);
            // this.teams.forEach((team:any,index) => {
            //     team.memberships.forEach((mem:any,memIndex) => {
            //         if(mem.id == member.id) {
            //             this.teams[index].memberships.splice(memIndex, 1);
            //             if(this.teams[index].memberships_count) {
            //                 this.teams[index].memberships_count -= 1;
            //             }
            //         }
            //     })
            // });
            // let teamInd = this.membersDetails4NewTeam?.teams_names?.indexOf(teamName)
            // this.membersDetails4NewTeam?.teams_names?.splice(teamInd, 1);

            // this.teamsData.members.forEach((mem,index)=> {
            //     if(mem.id == member.member_id) {
            //         console.log("Removed")
            //         this.teamsData.members[index].non_admin_unassgined_teams_count -= 1
            //     }
            //     // console.warn(mem)
            // })
            
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('The Member was removed successfully!', 'Nice!');
                that.addTeamPopup = false;
                identity_id = null;
                that.membersDetails4NewTeam = null;
            }, 1000);
        },
        err =>{
            console.log(err);
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(err.errors, 'Sorry!');
            }, 1000);
            this.loading = false;
        })
    }

    // remove team member from team
    public async removeTeamMember(member) {
        // console.log('remove team member',member)
        // console.log('remove team member',member?.default_profile?.id)
        let f_name = 'No Name';
        if(member.first_name) {
            f_name = member?.first_name[0]?.toUpperCase() + member.first_name?.slice(1);
        }
        let team_name = member?.team_name[0]?.toUpperCase() + member.team_name?.slice(1);
        Swal.fire({
	        title: "Remove Member!",
	        text: `Are you sure, you want to remove ${f_name} ${member?.last_name ? member.last_name : ''} from ${team_name}?`,
	        type: "warning",
	        showCancelButton: true,
	        confirmButtonColor: "#DD6B55",
	        cancelButtonColor: '##D0D0D0',
	        confirmButtonText: "Yes, Remove it!"
    	}).then(async (result) => {
	        if (result['value'] == true) {
	        	jQuery('.swal2-container').addClass('hide');
	    		jQuery('body').removeClass('swal2-shown');
	    		jQuery('html').removeClass('swal2-shown');
                await this.removedMember(member)
                this.selectAllRoaster(false)
	        }else{
                jQuery('.swal2-container').addClass('hide');
                jQuery('body').removeClass('swal2-shown');
                jQuery('html').removeClass('swal2-shown');
            }
    	},function(dismiss){
    		jQuery('.swal2-container').addClass('hide');
    		jQuery('body').removeClass('swal2-shown');
    		jQuery('html').removeClass('swal2-shown');
    	});
    }

    public accord(toggleClass,e, collapseAll:boolean = false){
        if(collapseAll) {
            let allItems = document.querySelectorAll(`.${toggleClass}`)
            // console.warn(allItems)
            if(!this.collapse_expand_All) {
                allItems.forEach((item,index)=> {
                    // console.log('item',item,index)
                    $(`.${toggleClass}`).eq(index).slideUp()
                    item.classList.remove('active')
                })
            } else {
                allItems.forEach((item,index)=> {
                    // console.warn("itemdown",item,index)
                    $(`.${toggleClass}`).eq(index).slideDown()
                    item.classList.add('active')
                })
            }
            this.collapse_expand_All = !this.collapse_expand_All;
        } else {
            if(e) {
                e.target.classList.toggle('active')
            }
            $(`.${toggleClass}`).slideToggle()
        }
    }

    // made array for pagination
    pageCounter(i: number) {
        let totalPg:any = i/this.roasterdefaultCounter;
        if(i % this.roasterdefaultCounter !==0 ) {
            totalPg = totalPg + 1
            totalPg = parseInt(totalPg)
        }
        // if((totalPg* 20) <= i) {
        //     totalPg += 1
        // }
        return new Array(totalPg);
    }

    participantView(from,team,mbr:any="") {
        // console.log(team)
        let updateRoute = ()=> {
            if(from == "team") {
                this.route.navigate(['/myteams',`${team.id}`])
                localStorage.setItem("participantsTeamsAdmin", 'true')
            } else if(from == "member") {
                localStorage.setItem("participantsTeamsAdmin", 'false')
                this.route.navigate([`/myteams/${team.id}/participant`,`${mbr?.member_id}`])
            }
        }
        if(team?.memberships_count > 20) {
            let allTeamMem:any = this.httpService.getRequest(`${environment.profileURL}/api/v1/teams/`+team.id+`/memberships.json?&last_name_order=asc&per_page=${team.memberships_count}`)
            allTeamMem.subscribe((res)=> {
                team["allMembers"] =  res.memberships;
                team["memberships"] = res.memberships;
                // console.warn(mbr)
                this.teamReportService.participantTeamData = team;
                localStorage.setItem("participantTeamData",JSON.stringify(team))
                localStorage.setItem("participantData", JSON.stringify(mbr))
                updateRoute();
            })
        } else {
            team["allMembers"] = team.memberships;
            this.teamReportService.participantTeamData = team;
            localStorage.setItem("participantTeamData",JSON.stringify(team))
            localStorage.setItem("participantData", JSON.stringify(mbr))
            updateRoute()
        }
    }

    public addToTeam() {
        // console.log('add to team')
    }

    // check all roster members
    selectAllRoaster(checked) {
        this.selectAll = !this.selectAll
        var checkboxes = document.querySelectorAll('.orgs_team__branch_title_name input');
        if(checked) {
            checkboxes.forEach((item:any)=> {
                item.checked = true;   
            })
            this.membersDetails4NewTeam = this.teamsData?.members[this.teamsData?.members.length-1]
            this.membersDetails4NewTeam.teams_names = {}
            // console.log(this.membersDetails4NewTeam)
            let data;
            if(this.searchby == 'invitation' && this.selectedInvitation != 'Select Invitation') {
                data = this.filterData
            } else {
                data = this.teamsData.members
            }
            data.forEach((mem)=> {
                mem["checked"] = true
            })
        } else {
            checkboxes.forEach((item:any)=> {
                item.checked = false;   
            })
            let data;
            // this.membersDetails4NewTeam = this.teamsData?.members[this.teamsData?.members.length-1]
            if(this.searchby == 'invitation' && this.selectedInvitation != 'Select Invitation') {
                data = this.filterData
            } else {
                data = this.teamsData?.members
            }
            data?.forEach((mem)=> {
                mem["checked"] = false
            })
        }
    }

    public async getAllInvitation() {
        // console.log('input value',this.filterRoster)
        if(this.filterRoster) {
            this.searchRoaster(this.filterRoster, true)
        } else {
            let getAllInvit = await this.httpService.getRequest(`${environment.profileURL}/api/v1/organizations/${this.id}/invitations?per_page=100`);
            getAllInvit.subscribe((res:any)=> {
                // console.log(res)
                this.invitationList = res?.invitations;
                // console.log("all invitation",this.invitationList)
            })
            if(this.searchby !== 'invitation') {
                this.filterData = '';
            }
        }
    }

    public async getSelInvMem() {
        if(this.selectedInvitation == 'Select Invitation') {
            this.filterData = '';
        } else {
            this.loading = true;
            for(let i = 0; i<this.invitationList?.length; i++) {
                if(this.invitationList[i]?.members_count == 0 && this.invitationList[i].id == this.selectedInvitation) {
                    this.filterData = "Member not Found";
                    this.loading = false;
                    break;
                } else if(this.invitationList[i].id == this.selectedInvitation ){
                    let roasterData1 = this.httpService.getRequest(`${environment.profileURL}/api/v1/organizations/${this.id}/members.json?invitation_ids[]=${this.selectedInvitation}&per_page=${this.invitationList[i].members_count}`);
                    roasterData1.subscribe((res:any)=> {
                        this.filterData = res?.members
                        if(res.members.length == 0) {
                            this.filterData = "Member not Found"
                        }
                        this.loading = false;
                    })
                    break;
                }
            }
        }
    }

    async addAllMembers(team) {
        this.invite = true;
        this.team_name = team?.name;
        this.inviteTeamId = team.id;
        // console.log(team)
        // { 'email' => 'aaa@gmail.com', 'role_id' => 3, 'first_name' => 'John', 'last_name' => 'Doe' }
        let data = {
            members_attributes :{}
        }
        this.teamsData.members.forEach((el,index) => {
            let memDetails = {}
            if(el?.default_identity?.email || el?.default_profile?.email) {
                memDetails = { 
                    "email":  el?.default_identity?.email ? el?.default_identity?.email : el?.default_profile?.email ,
                    "first_name": el?.default_identity?.first_name ? el?.default_identity?.first_name : el?.default_profile?.first_name,
                    "last_name":  el?.default_identity?.last_name ? el?.default_identity?.last_name : el?.default_profile?.last_name,
                    "role_id": 3
                }
            }
            data["members_attributes"][index] = memDetails
        });
        // console.log(data)
    }

    // checked or unchecked member for team as well as invitation 
    memberChecked(record:any) {
        // console.log(record)
        let checkedCounter = 0
        let getTotalChecked = ()=> {
            var checkboxes = document.querySelectorAll('.orgs_team__branch_title_name input');
            checkedCounter = 0
            checkboxes.forEach((checkbox:any)=> {
                // console.log(checkbox.checked)
                if(checkbox.checked) checkedCounter += 1
            })
            if(checkedCounter != 0) {
                // console.log('enter in if block', checkedCounter)
                this.membersDetails4NewTeam = record
            }else {
                this.membersDetails4NewTeam = null
            }
        }
        if(record["checked"]) {
            record.checked = !record.checked
            this.selectedFilterMbr.forEach((mbr:any,index)=> {
                if(mbr.id == record.id) {
                    this.selectedFilterMbr.splice(index, 1)
                }
            })
            this.selectedRosterMembers.forEach((mbr:any,index)=> {
                if(mbr.id == record.id) {
                    this.selectedRosterMembers.splice(index, 1)
                }
            })
            getTotalChecked()
            
        } else {
            getTotalChecked()
            record["checked"] =  true;
            this.selectedRosterMembers.push(record)
        }
        this.rosterMemberCheckedCounter = checkedCounter;
        if(this.filterData) {
            this.selectedFilterMbr.push(record)
        }
        // if(this.rosterMemberCheckedCounter > 1) {
        //     this.membersDetails4NewTeam.teams_names = {}
        // }
        // console.log(this.membersDetails4NewTeam, checkedCounter)
        // console.log(this.selectedRosterMembers)
    }

    public secretEmail(usrEmail, length = 8) {
        return usrEmail?.substring(0,length) + '...';
    }

    // 1 for single member and 2 for multiple member
    public removeRosterMem(record:any='',oneOrMulti=0, type='member',index= undefined) {
        // console.log(oneOrMulti,index)
        this.remvoeTeamOrMem = type;
        this.removeRosterMemType = oneOrMulti;
        this.removeRosterMemDetails = record;
        this.teamReportService.rosterName.next(record?.default_identity?.first_name ? record?.default_identity?.first_name : record?.default_profile?.first_name ? record?.default_profile?.first_name : "No name")
        // console.log(this.removeRosterMemDetails)
        if(type === 'team') {
            this.removeTeamId = record.id;
            this.removeTeamIndex = index;
        }
        if(oneOrMulti == 1) {
            $('.roster_addmem_popup').css('display','block');
        } else {
            $('.roster-del-mem').css('display','block')
        }
    }

    public rosterAddMember() {
        this.removeRosterMemType = 0;
        setTimeout(() => {
            $('.roster_addmem_popup').css('display','block')
        }, 100);
    }

}
