import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeamReportServiceService } from '../../team-report/team-report-service.service';

@Component({
  selector: 'app-myteams-gpp',
  templateUrl: './myteams-gpp.component.html',
  styleUrls: ['./myteams-gpp.component.scss']
})
export class MyteamsGPPComponent {

  @Input() teams:any;
  @Input() indivId:any;
  allProfiles = ['','Clarifier','Ideator','Developer','Implementer','Early-bird','Analyst','Accelerator','Theorist','Driver','Finisher','Hare','Idea-broker','Optimist','Integrator','Realist'];
  gppData:any = {
    clLow : [],
    clNeu : [],
    clHigh : [],
    idNeu : [],
    idLow : [],
    idHigh : [],
    deNeu : [],
    deLow : [],
    deHigh : [],
    imNeu : [],
    imLow : [],
    imHigh : [],
    integrator : []
  }
  params;

  constructor(public route:ActivatedRoute, public teamReport: TeamReportServiceService) {}

  async ngOnInit() {
    var that = this;
      let currentTeam;
      this.route.params.subscribe((params)=> {
        if(params['id']) {
            console.warn('if block')
            this.params = params.id
            this.setGppData(this.teamReport.participantTeamData)
        } else {
            console.warn('else block')
            this.teams?.forEach(el => {
                console.warn(el.id == this.indivId)
                if(el.id == this.indivId) {
                currentTeam = el
                }      
            });
            if(currentTeam) {
                this.setGppData(currentTeam)
            }
        }
    })
    console.log(this.params)
    // this.teamReport.participantTeamData.subscribe((currentTeam1)=> {
    //     console.log('item===>',currentTeam)
    //     if(currentTeam1) {
    //         this.gppData = this.setGppData(currentTeam1)
    //     }
    //     // if(team) {
    //     //     dataPromise = new Promise((resolve, error)=> {
    //     //         resolve(that.setGppData(team))
    //     //     })
    //     //     dataPromise.then(res=> {
    //     //         this.updateData = res;
    //     //     })
    //         console.warn(this.gppData)
    //     // }
    // })
    // setTimeout(()=> {
    //     console.log(this.gppData)
    // },5000)
  }

  public setGppData(currentTeam) {
    var that = this;
    currentTeam?.allMembers?.forEach(list => {
        if(list?.default_profile){
            if(list?.default_profile?.clarifier_score == 'Low'){
                if(list?.first_name) {
                    this.gppData.clLow.push(list?.first_name)
                } else if(list.default_profile?.first_name) {
                    this.gppData.clLow.push(list?.default_profile?.first_name)
                } else {
                    this.gppData.clLow.push("No name")
                }
            }

            if(list?.default_profile?.clarifier_score == 'High'){
                if(list?.first_name) {
                    this.gppData.clHigh.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.clHigh.push(list.default_profile.first_name);
                } else {
                    this.gppData.clHigh.push("No name");
                }
            }

            if(list?.default_profile.ideator_score == 'Low'){
                if(list?.first_name) {
                    this.gppData.idLow.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.idLow.push(list.default_profile.first_name);
                } else {
                    this.gppData.idLow.push("No name");
                }
            }

            if(list?.default_profile?.ideator_score == 'High'){
                if(list?.first_name) {
                    this.gppData.idHigh.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.idHigh.push(list.default_profile.first_name);
                } else {
                    this.gppData.idHigh.push("No name");
                }
            }

            if(list?.default_profile.developer_score == 'Low'){
                if(list?.first_name) {
                    this.gppData.deLow.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.deLow.push(list.default_profile.first_name);
                } else {
                    this.gppData.deLow.push("No name");
                }
            }

            if(list?.default_profile?.developer_score == 'High'){
                if(list?.first_name) {
                    this.gppData.deHigh.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.deHigh.push(list.default_profile.first_name);
                } else {
                    this.gppData.deHigh.push("No name");
                }
            }

            if(list?.default_profile?.implementer_score == 'Low'){
                if(list?.first_name) {
                    this.gppData.imLow.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.imLow.push(list.default_profile.first_name);
                } else {
                    this.gppData.imLow.push("No name");
                }
            }

            if(list?.default_profile?.implementer_score == 'High'){
                if(list?.first_name) {
                    this.gppData.imHigh.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.imHigh.push(list.default_profile.first_name);
                } else {
                    this.gppData.imHigh.push("No name");
                }
            }

            //   neutral profiles
            if(list?.default_profile?.clarifier_score == 'Neutral') {
                if(list?.first_name) {
                    this.gppData.clNeu.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.clNeu.push(list.default_profile.first_name);
                } else {
                    this.gppData.clNeu.push("No name");
                }
            }
            if(list.default_profile.ideator_score == 'Neutral') {
                if(list?.first_name) {
                    this.gppData.idNeu.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.idNeu.push(list.default_profile.first_name);
                } else {
                    this.gppData.idNeu.push("No name");
                }
            }
            if(list.default_profile.developer_score == 'Neutral') {
                if(list?.first_name) {
                    this.gppData.deNeu.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.deNeu.push(list.default_profile.first_name);
                } else {
                    this.gppData.deNeu.push("No name");
                }
            }
            if(list.default_profile.implementer_score == 'Neutral') {
                if(list?.first_name) {
                    this.gppData.imNeu.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.imNeu.push(list.default_profile.first_name);
                } else {
                    this.gppData.imNeu.push("No name");
                }
            }
            if(list?.default_profile?.clarifier_score == 'Neutral' && list.default_profile.ideator_score == 'Neutral' && list.default_profile.developer_score == 'Neutral' && list.default_profile.implementer_score == 'Neutral'){
                let clNeuIndex = this.gppData.clNeu.indexOf(list.default_profile.clarifier_score)
                this.gppData.clNeu = this.array_move(this.gppData.clNeu,clNeuIndex,0)
                let idNeuIndex = this.gppData.idNeu.indexOf(list.default_profile.ideator_score)
                this.array_move(this.gppData.idNeu,idNeuIndex,0)
                let deNeuIndex = this.gppData.deNeu.indexOf(list.default_profile.developer_score)
                this.array_move(this.gppData.deNeu,deNeuIndex,0)
                let imNeuIndex = this.gppData.imNeu.indexOf(list.default_profile.implementer_score)
                this.array_move(this.gppData.imNeu,imNeuIndex,0)
                if(list?.first_name) {
                    this.gppData.integrator.push(list?.first_name)
                } else if(list.default_profile.first_name) {
                    this.gppData.integrator.push(list.default_profile.first_name);
                } else {
                    this.gppData.integrator.push("No name");
                }
            }

        }

    });
    console.log(this.gppData)
    this.teamReport.gppData.next(this.gppData)
    if(!this.teamReport.args) {
        this.teamReport.args = {}
    }
    this.teamReport.args["gppData"] = this.gppData
    return this.gppData;

  }

    // change item index
    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };
}
