import { Component, Injector, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Auth } from '../../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LambdaFunctionService } from '../../../service/lambda-function.service';
import { TeamReportServiceService } from '../../team-report/team-report-service.service';
import { TeamsComponent } from '../teams.component';

@Component({
  selector: 'app-individual-graph',
  templateUrl: './individual-graph.component.html',
  styleUrls: ['./individual-graph.component.scss']
})
export class IndividualGraphComponent {

  // id:any;
  // members:any;
  // teamName:any;
  // orgName:any;
  clarifier_low:number = 0;
  clarifier_high:number = 0;
  ideator_low:number = 0;
  ideator_high:number = 0;
  developer_low:number = 0;
  developer_high:number = 0;
  implementor_low:number = 0;
  implementor_high:number = 0;
  clarifier_neutral:number = 0;
  ideator_neutral:number = 0;
  developer_neutral:number = 0;
  implementor_neutral:number =0;
  integrator:number = 0;
  highest_score:number = 0;
  total_members:number = 0;
  @Input() forTeamsCompo:number;
  @Input() teams;
  @Input() profileValues:boolean = false;
  teamsCompo:any = {};
  params;
  date;
  allProfiles = ['','Clarifier','Ideator','Developer','Implementer','Early-bird','Analyst','Accelerator','Theorist','Driver','Finisher','Hare','Idea-broker','Optimist','Integrator','Realist'];
  
  constructor(private injector: Injector,public activatedRoute:ActivatedRoute, public router: Router, public http: HttpClient,public auth:Auth,public lmda_service: LambdaFunctionService,public teamReportService: TeamReportServiceService) {
    this.activatedRoute.params.subscribe((params)=> {
      this.params = params.id;
      if(!params.id) {
        this.teamsCompo = this.injector.get(TeamsComponent);
      }
    })
  }

  ngOnInit() {
    this.date = new Date();
    $('.graph-loader').css('display','block');
    let currentTeam;
    this.activatedRoute.params.subscribe( (params)=> {
      if(params['id']) {
        console.warn('if block')
        this.profileValues = true;
          this.setGraphData(this.teamReportService.participantTeamData)
      } else {
        console.warn('else block')
        this.teams?.forEach((el) => {
            if(el.id == this.forTeamsCompo) {
            currentTeam = el
            }      
        });
        if(currentTeam) {
            this.setGraphData(currentTeam)
        }
      }
    })
    //   this.teams.forEach(el => {
    //     if(el.id == this.forTeamsCompo) {
    //       currentTeam = el
    //     }      
    // });
    $('.graph-loader').css('display','none');  
  }


  public async setGraphData(currentTeam) {
    this.total_members = currentTeam["allMembers"]?.length;
    currentTeam?.allMembers?.forEach(list => {
        if(list?.default_profile){
            if(list?.default_profile?.clarifier_score == 'Low'){
                this.clarifier_low = this.clarifier_low + 1;
                if( this.clarifier_low > this.highest_score ){
                    this.highest_score = this.clarifier_low;
                }
            }
  
            if(list?.default_profile?.clarifier_score == 'High'){
                this.clarifier_high = this.clarifier_high + 1;
                if( this.clarifier_high > this.highest_score ){
                    this.highest_score = this.clarifier_high;
                }
            }
  
            if(list?.default_profile.ideator_score == 'Low'){
                this.ideator_low = this.ideator_low + 1;
                if( this.ideator_low > this.highest_score ){
                    this.highest_score = this.ideator_low;
                }
            }
  
            if(list?.default_profile?.ideator_score == 'High'){
                this.ideator_high = this.ideator_high + 1;
                if( this.ideator_high > this.highest_score ){
                    this.highest_score = this.ideator_high;
                }
            }
  
            if(list?.default_profile.developer_score == 'Low'){
                this.developer_low = this.developer_low + 1;
                if( this.developer_low > this.highest_score ){
                    this.highest_score = this.developer_low;
                }
            }
  
            if(list?.default_profile?.developer_score == 'High'){
                this.developer_high = this.developer_high + 1;
                if( this.developer_high > this.highest_score ){
                    this.highest_score = this.developer_high;
                }
            }
  
            if(list?.default_profile?.implementer_score == 'Low'){
                this.implementor_low = this.implementor_low + 1;
                if( this.implementor_low > this.highest_score ){
                    this.highest_score = this.implementor_low;
                }
            }
  
            if(list?.default_profile?.implementer_score == 'High'){
                this.implementor_high = this.implementor_high + 1;
                if( this.implementor_high > this.highest_score ){
                    this.highest_score = this.implementor_high;
                }
            }
  
            //   neutral profiles
            if(list?.default_profile?.clarifier_score == 'Neutral') {
              this.clarifier_neutral += 1; 
            }
            if(list.default_profile.ideator_score == 'Neutral') {
              this.ideator_neutral += 1; 
            }
            if(list.default_profile.developer_score == 'Neutral') {
              this.developer_neutral += 1; 
            }
            if(list.default_profile.implementer_score == 'Neutral') {
              this.implementor_neutral += 1; 
            }
            if(list?.default_profile?.clarifier_score == 'Neutral' && list.default_profile.ideator_score == 'Neutral' && list.default_profile.developer_score == 'Neutral' && list.default_profile.implementer_score == 'Neutral'){
              this.integrator += 1;
              }
  
        }
  
    });
    $('.graph-loader').css('display','none')
    await this.setArgs()
  }

  public setArgs() {
    let participantData = JSON.parse(localStorage.getItem('participantTeamData'))
    let name = this.teamsCompo?.individualTeamDetails?.name ? this.teamsCompo?.individualTeamDetails?.name : participantData?.name;
    let membershipcount = this.teamsCompo?.individualTeamDetails?.memberships_without_profile_count ? this.teamsCompo?.individualTeamDetails?.memberships_without_profile_count : participantData?.memberships_count;
    let org_id = this.teamsCompo?.individualTeamDetails?.memberships_count ? this.teamsCompo?.individualTeamDetails?.organization_id : participantData?.organization_id;
    let org_name = this.teamsCompo?.individualTeamDetails?.memberships_count ? this.teamsCompo?.individualTeamDetails?.organization_name : participantData?.organization_name;;
    let date = String(this.date.getFullYear()) + String((this.date.getMonth() + 1)) + String(this.date.getDate());
    let time = String(this.date.getHours()) + String(this.date.getMinutes());
    this.teamReportService.args = {
      "function_name": "plus-GroupProfileFunction-6y5p4TgXuqZA",
      "invocation_type": "RequestResponse",
      "log_type": "None",
      // "id": currentTeam.all_members[0]?.default_profile?.team_id,
      "template": "team_pf.html",
      "filename": org_name+ '-' + date + time,
      "memberships_count": membershipcount,
      "name": name,
      "organization_title": "Organization",
      "organization_id": org_id,
      "organization_name": org_name,
      "clarifier_low": this.clarifier_low ? this.clarifier_low : 0,
      "clarifier_neutral": this.clarifier_neutral ? this.clarifier_neutral : 0,
      "clarifier_high": this.clarifier_high ? this.clarifier_high : 0,
      "ideator_low": this.ideator_low ? this.ideator_low : 0,
      "ideator_neutral": this.ideator_neutral ? this.ideator_neutral : 0,
      "ideator_high": this.ideator_high ?  this.ideator_high : 0,
      "developer_low": this.developer_low ? this.developer_low : 0,
      "developer_neutral": this.developer_neutral ? this.developer_neutral : 0,
      "developer_high": this.developer_high ? this.developer_high : 0,
      "implementor_low": this.implementor_low ? this.implementor_low : 0,
      "implementor_neutral": this.implementor_neutral ? this.implementor_neutral : 0,
      "implementor_high": this.implementor_high ? this.implementor_high : 0,
      "integrator": this.integrator ?  this.integrator : 0,
      "highest_score": this.highest_score ? this.highest_score : 0,
      "app_type": "Group Profile Plus",
      "pdf_type": "letter",
      "email": "sarah@blairmiller.com",
      // "today_date": this.date,
      "content_type": 'pdf',
      "profile_type": "Ask",
      "description": "Look at your graph. What do you notice? What, if anything, is surprising? Where does this group need to pay deliberate attention so they can succeed? Think of a project or a challenge this group has recently been engaged in. Describe how the group approached the project using the group profile as a guide.",
      "footer_imptext": "FourSight® Group Profile",
      "footer_text": "provides a snapshot of how a group's energy will likely rise and fall as people work together through the four stages of the universal problem solving process. The numbers indicate how many individuals in the group have a high, neutral, or low thinking preference for each stage.",
      "remember": "Preference <span>=</span> Energy",
      "team_des": "FourSight is a measure of thinking preference, not ability. A preference is a measure of energy, not talent. People with a high preference tend to gravitate to that stage of the problem-solving process. People with a low preference tend to move quickly through it. People with a neutral preference tend to do the work and move on. All three approaches can lead to problem-solving success. Teams are more likely to succeed when people are both self-aware and process aware.",
      "footer_des": null,
      "team_profiles": {
          "clarifier_high": this.clarifier_high,
          "clarifier_neutral": this.clarifier_neutral,
          "clarifier_low": this.clarifier_low,
          "ideator_high": this.ideator_high,
          "ideator_neutral": this.ideator_neutral,
          "ideator_low": this.ideator_low,
          "developer_high": this.developer_high,
          "developer_neutral": this.developer_neutral,
          "developer_low": this.developer_low,
          "implementer_high": this.implementor_high,
          "implementer_neutral": this.implementor_neutral,
          "implementer_low": this.implementor_low
      }
    }
    console.log(this.teamReportService.args)
  }

}
