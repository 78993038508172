import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment } from './../../../../environments/environment';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';

@Component({
	selector: 'app-store-products',
	templateUrl: './store-products.component.html',
	styleUrls: ['./store-products.component.scss']
})
export class StoreProductsComponent implements OnInit {
	public mcart:any;
	public tcart:any;
	public traincart:any = [];
	public bcart:any;
	details:any;
	Cart:any;
	filter:string;
	is_buyer:boolean;
	env:any;
	mindset_quant:any;
	toolset_quant:any;
	books_quant:any;
	training_quant:any = [];
	Quant:any;
	store:any;
	products:any = [];
	books:any = [];
	training:any = [];
	toolset:any = [];
	showBook:boolean = false;
	product_order:any = [];
	product_head:any = [];
	sortedProducts:any = [];
	categoriesProduct;

  	constructor(public location: Location, public http:HttpClient, private activatedRoute: ActivatedRoute, private router: Router) { 

  	}

  	ngOnInit() {
  		this.store = localStorage.getItem('store');
  		this.mindset_quant = [];
  		this.toolset_quant = [];
  		this.books_quant = [];
  		this.mcart = [];
  		this.tcart = [];
  		this.bcart = [];
  		this.filter = '';
  		this.env = environment;

		this.http.get(`${this.env.assessment_admin}api/v1/categories`).subscribe((data:any) => {
			console.warn("categories",data)
			let categoriesHavingShow = data?.categories?.filter(category => {
				if(category.name.includes('show-custom-Books')) {
					let num = category.name?.replace(/\D/g,'');
					category.position = num
				}
				return category.name.includes('show')
			});
			console.log("categoriesHavingShow", categoriesHavingShow)
			let sortedCategories = categoriesHavingShow.sort((a, b) => a.position - b.position);
			console.log("sortedCategories", sortedCategories)
			let onlyPublicStoreCategories = sortedCategories.filter(category => category.name.includes('-custom-'));
			let sortedCatWithSortedProducts = onlyPublicStoreCategories.map(category => {
				let num = category.name?.replace(/\D/g,'');
				console.log("num", category)
				category.products.forEach((product,i) => {
					console.log("cat name", category?.name)
					if(category?.name?.includes('Training Material')) { 
						this.mindset_quant.push(1);
					} else if (category?.name?.includes('Books')) {
						this.books_quant.push(1);
					} else if (category?.name?.includes('Certifications')) {
						this.toolset_quant.push(1);
					}
				})
				// category.name[this.product_order.indexOf(num)] = category.name?.replace(num + '-show-','')
				console.log("quant", this.mindset_quant, this.books_quant)

				return {
					name: category.name?.replace(num + '-show-custom-',''),
					products: category.products.sort((prodA, prodB) => prodA.position - prodB.position)
				}
			})
			console.log("sortedCatWithSortedProducts", sortedCatWithSortedProducts)
			this.categoriesProduct = sortedCatWithSortedProducts;
		})

  		// this.http.get(`${this.env.assessment_admin}api/v1/products`).subscribe(data => {
        //     this.details = data['products'];
		// 	console.warn(this.details)
        //     var products = [];
		// 	let temporder:any;
		// 	let headings:any;
		// 	data['products'].forEach(product => {
		// 		product['categories'].forEach((item,i) => {
		// 			temporder = '';
		// 			temporder = item.name.replace(/\D/g,'');
		// 			headings = item.name;
		// 		})
		// 		console.log(headings)
		// 		if(!this.product_head.includes(headings)) {
		// 			this.product_head.push(headings)
		// 		}
		// 		// temporder = parseInt(temporder)
		// 		if(!this.product_order.includes(temporder)){
		// 			this.product_order.push(temporder);
		// 		}
		// 	})
			
		// 	//sort order
		// 	function compare1( a, b ) {
		// 		console.log(a, b)
		// 		if ( a?.categories[0]?.position < b?.categories[0]?.position ){
		// 			return -1;
		// 		}
		// 		if ( a?.categories[0]?.position > b?.categories[0]?.position ){
		// 			return 1;
		// 		}
		// 		return 0;
		// 	}
		// 	this.details.sort( compare1 )
		// 	console.warn(this.details)
		// 	// this.product_order.sort(function(a, b) {
		// 	// 	return a - b;
		// 	// });
		// 	let myHeading:any = []
		// 	this.product_head.forEach((heading:any,ind)=> {
		// 		console.log(heading)
		// 		if(heading?.includes('-show-custom-')) {
		// 			let num:any = heading?.replace(/\D/g,'');
		// 			myHeading[this.product_order.indexOf(num)] = heading?.replace(num + '-show-custom-','')
		// 		}
		// 	})
		// 	console.warn(myHeading)
		// 	this.product_head = myHeading;
		// 	console.warn(this.product_order)
		// 	console.warn(this.product_head)
		// 	data['products'].forEach((product:any) => {
		// 		product['categories'].forEach((item,i) => {
		// 			console.log(item.name)
		// 			if(item.name.includes('-show-custom-')) {
		// 				this.product_order.forEach((order,oi)=> {
		// 					if(this.sortedProducts[oi]== undefined || this.sortedProducts[oi] == null) {
		// 						this.sortedProducts[oi] = [];
		// 					}
		// 					if(item.name.includes(order)) {
		// 						this.sortedProducts[oi].push(product)
		// 						this.mindset_quant.push(1);
		// 					}
		// 				})
	
		// 				if(item.name.includes('Training Materials')) {
		// 					this.products.push(product)
		// 					this.mcart[i] = 0;
		// 					this.mindset_quant.push(1);
		// 				} else if (item?.name.includes('show-custom-Books') || item?.name.includes('hide-Books')) {
		// 					this.bcart[i] = 0;
		// 					this.books_quant.push(1);
		// 					if(item.name.includes('show-custom-Books')) {
		// 						this.showBook = true;
		// 					}
		// 					this.books.push(product)
		// 				} else if (item?.name.includes('show-custom-Certifications')) {
		// 					this.training.push(product)
		// 					this.traincart.push(0);
		// 					this.training_quant.push(1);
		// 				} else if (item?.name.includes('Toolset')) {
		// 					this.toolset.push(product)
		// 					this.tcart[i] = 0;
		// 					this.toolset_quant.push(1);
		// 				}
		// 			}

		// 		})
		// 	})
		// 	function compare( a, b ) {
		// 		console.log(a, b)
		// 		if ( a?.categories[0]?.position < b?.categories[0]?.position ){
		// 			return -1;
		// 		}
		// 		if ( a?.categories[0]?.position > b?.categories[0]?.position ){
		// 			return 1;
		// 		}
		// 		return 0;
		// 	}
		// 	console.log(this.sortedProducts)
		// 	let sortIt:any;
		// 	this.sortedProducts.forEach((item:any,ind)=> {
		// 		sortIt = item;
		// 		sortIt?.sort( compare )
		// 		console.log(sortIt)
		// 	})
        //     for(var i=0; i<this.details.length;i++){
        //     	if(this.details[i].categories[0]){
        //     		for(var j=0; j<this.details[i].categories.length;j++){
		//             	// if(this.details[i].categories[j].name == 'Mindset'){
		//             	// 	this.mcart[i] = 0;
		//             	// 	this.mindset_quant[i] = 1;
		//             	// }
		//             	// if(this.details[i].categories[j].name == 'Toolset'){
		//             	// 	this.tcart[i] = 0;
		//             	// 	this.toolset_quant[i] = 1;
		//             	// }
		//             	// if(this.details[i].categories[j].name == 'Books'){
		//             	// 	this.bcart[i] = 0;
		//             	// 	this.books_quant[i] = 1;
		//             	// }
		//             	products.push({
		//             		product_id: this.details[i].variants[0].product_id,
		// 					sku: this.details[i].variants[0].sku,
		// 					category: this.details[i]['categories'][0].name,
		// 					name: this.details[i].variants[0].name,
		// 					price: this.details[i].variants[0].price,
		// 					image_url: this.details[i].variants[0].image_url
		//             	})
		//             }
	    //         }
        //     }
        //     console.log(this.mindset_quant);
        //     // this.angulartics2Segment.eventTrack('Product List Viewed', 
        //     //     { 
        //     //         products:products
        //     //     }
        //     // );
        // },
        //     error => {
        //     	console.log('oops', error);
        //     	if(error.status == 500){
		// 			setTimeout(function() {
		// 				this.toastr.options = {
		// 				  closeButton: true,
		// 				  timeOut: 2000
		// 				};
		// 				this.toastr.error('Something went wrong!', 'Sorry!');
		// 			}, 1000);
		// 		}
		// 		else if(error.status == 422){
		// 			setTimeout(function() {
		// 				this.toastr.options = {
		// 				  closeButton: true,
		// 				  timeOut: 4000
		// 				};
		// 				if(!error.error.errors.base[0]){
        //                     this.toastr.error(error.error.errors, 'oops!');
        //                 }else{
        //                     this.toastr.error(error.error.errors.base[0], 'oops!');
        //                 }
		// 			}, 1000);
		// 		}else{
		// 			setTimeout(function() {
		// 				this.toastr.options = {
		// 				  closeButton: true,
		// 				  timeOut: 2000
		// 				};
		// 				this.toastr.error('Something went wrong!', 'oops!');
		// 			}, 1000);
		// 		}
		// 	}
        // );

        this.http.get(`${this.env.assessment_admin}api/v1/carts`).subscribe(data => {
        	if(data['errors']){
        		setTimeout(function() {
					this.toastr.options = {
					  closeButton: true,
					  timeOut: 10000
					};
					this.toastr.error(data['errors'], 'oops!');
				}, 1000);
        	}else{
	        	this.Cart = data['order']['line_items'];
	        	this.is_buyer = data['order']['is_buyer'];
	        }
        },
            error => {
            	console.log('oops', error);
            	if(error.status == 500){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'Sorry!');
					}, 1000);
				}
				else if(error.status == 422){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
						if(!error.error.errors.base[0]){
                            this.toastr.error(error.error.errors, 'oops!');
                        }else{
                            this.toastr.error(error.error.errors.base[0], 'oops!');
                        }
					}, 1000);
				}else{
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'oops!');
					}, 1000);
				}
			}
        );
	}

	public cart = function(i, id, category){
		if((category == 'Mindset' && typeof(this.mindset_quant[i]) == 'number') || (category == 'Toolset' && typeof(this.toolset_quant[i]) == 'number') || (category == 'Books' && typeof(this.books_quant[i]) == 'number')){
			if(category == 'Mindset'){
	    		this.Quant = this.mindset_quant[i];
	        }
	        if(category == 'Toolset'){
	    		this.Quant = this.toolset_quant[i];
	        }
	        if(category == 'Books'){
	    		this.Quant = this.books_quant[i];
	        }
	  		var Data = {
	  			variant_id: parseInt(id),
	  			quantity: this.Quant
	  		}
	  		this.http.post(`${this.env.assessment_admin}api/v1/carts/add_item`,Data).subscribe(data => {            
	            this.Cart = data['order']['line_items'];
	            setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Product added to the Cart!', 'Nice!');
		        }, 1000);
	            if(category == 'Mindset'){
	            	this.mcart[i] = parseInt(id);
	            }
	            if(category == 'Toolset'){
	            	this.tcart[i] = parseInt(id);
	            }
	            if(category == 'Books'){
	            	this.bcart[i] = parseInt(id);
	            }

	        },
	            error => {
	            	console.log('oops', error);
	            	if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
                                this.toastr.error(error.error.errors, 'oops!');
                            }else{
                                this.toastr.error(error.error.errors.base[0], 'oops!');
                            }
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	        );
	  	}

  	}

  	public filterPdts(){
  		if(this.filter == 'popularity'){
  			this.details.sort(function(a, b) {
			    return parseFloat(b.popularity) - parseFloat(a.popularity);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'rating'){
  			this.details.sort(function(a, b) {
			    return parseFloat(b.rating) - parseFloat(a.rating);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'price'){
  			this.details.sort(function(a, b) {
  				var first = a.price_range;
  				var second = b.price_range;
  				if(a.price_range.indexOf("-") > 0){first = a.price_range.substring( 0, a.price_range.indexOf("-"));}
  				if(b.price_range.indexOf("-") > 0){second = b.price_range.substring( 0, b.price_range.indexOf("-"));}
  				if(a.price_range.indexOf(",") > 0){first = a.price_range.replace (/\,/g,"");}
  				if(b.price_range.indexOf(",") > 0){second = b.price_range.replace (/\,/g,"");}
			    return parseFloat(first) - parseFloat(second);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'price-desc'){
  			this.details.sort(function(a, b) {
  				var first = a.price_range;
  				var second = b.price_range;
  				if(a.price_range.indexOf("-") > 0){first = a.price_range.substring( a.price_range.indexOf("-")+1, a.price_range.length);}
  				if(b.price_range.indexOf("-") > 0){second = b.price_range.substring( b.price_range.indexOf("-")+1, b.price_range.length);}
  				if(a.price_range.indexOf(",") > 0){first = a.price_range.replace (/\,/g,"");}
  				if(b.price_range.indexOf(",") > 0){second = b.price_range.replace (/\,/g,"");}
			    return parseFloat(second) - parseFloat(first);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'date'){
  			this.details.sort(function(a, b) {
			    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
			});
			this.defaultQuantity();
  		}
  		if(this.filter == ''){
  			this.details.sort(function(a, b) {
			    return parseFloat(a.position) - parseFloat(b.position);
			});
			this.defaultQuantity();
  		}

  	}

  	public defaultQuantity(){
  		for(var i=0; i<this.details.length;i++){
        	if(this.details[i].categories[0]){
        		for(var j=0; j<this.details[i].categories.length;j++){
	            	if(this.details[i].categories[j].name == 'Mindset'){
	            		this.mcart[i] = 0;
	            		this.mindset_quant[i] = 1;
	            	}
	            	if(this.details[i].categories[j].name == 'Toolset'){
	            		this.tcart[i] = 0;
	            		this.toolset_quant[i] = 1;
	            	}
	            	if(this.details[i].categories[j].name == 'Books'){
	            		this.bcart[i] = 0;
	            		this.books_quant[i] = 1;
	            	}
	            }
            }
        }
  	}

}