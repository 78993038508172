import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule, ErrorHandler, Injectable } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import {RouterModule} from "@angular/router";
import {LocationStrategy, HashLocationStrategy, PathLocationStrategy} from '@angular/common';
import * as $ from 'jquery';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// Import the module from the SDK
// import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

// Amplify
// import { AmplifyAngularModule } from 'aws-amplify-angular';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';

// import { DatepickerModule } from 'ng2-datepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';


import {ROUTES} from "./app.routes";

import { AppComponent } from './app.component';

import { DragDropModule } from '@angular/cdk/drag-drop';

import {LoginComponent} from "./components/login/login.component";

// App modules/components
import {LayoutsModule} from "./components/common/layouts/layouts.module";
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';

// App views
import { CollaborationProfileComponent } from './components/collaboration-profile/collaboration-profile.component';
import { CigComponent } from './components/cig/cig.component';
import { FsProfileComponent } from './components/fs-profile/fs-profile.component';

// Interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { environment } from '../environments/environment';


import { FsGraphComponent } from './components/fs-graph/fs-graph.component';
import { FsIconComponent } from './components/fs-icon/fs-icon.component';
import { CigCoverComponent } from './components/cig/cig-cover/cig-cover.component';
import { CigWhatFuelsYourCreativityComponent } from './components/cig/cig-what-fuels-your-creativity/cig-what-fuels-your-creativity.component';
import { CigTheUniversalProcessComponent } from './components/cig/cig-the-universal-process/cig-the-universal-process.component';
import { CigClarifyComponent } from './components/cig/cig-clarify/cig-clarify.component';
import { CigIdeateComponent } from './components/cig/cig-ideate/cig-ideate.component';
import { CigDevelopComponent } from './components/cig/cig-develop/cig-develop.component';
import { CigImplementComponent } from './components/cig/cig-implement/cig-implement.component';
import { CigFoursightThinkingPreferencesComponent } from './components/cig/cig-foursight-thinking-preferences/cig-foursight-thinking-preferences.component';
import { CigPreferenceDoesNotEqualAbilityComponent } from './components/cig/cig-preference-does-not-equal-ability/cig-preference-does-not-equal-ability.component';
import { CigThereAreNoBadScoresComponent } from './components/cig/cig-there-are-no-bad-scores/cig-there-are-no-bad-scores.component';
import { CigCongratulationsComponent } from './components/cig/cig-congratulations/cig-congratulations.component';
import { CigHereYourFoursightGraphComponent } from './components/cig/cig-here-your-foursight-graph/cig-here-your-foursight-graph.component';
import { CigThe4StagesComponent } from './components/cig/cig-the-4-stages/cig-the-4-stages.component';
import { CigTheUniversalProcessClarifyComponent } from './components/cig/cig-the-universal-process-clarify/cig-the-universal-process-clarify.component';
import { CigTheUniversalProcessIdeateComponent } from './components/cig/cig-the-universal-process-ideate/cig-the-universal-process-ideate.component';
import { CigTheUniversalProcessDevelopComponent } from './components/cig/cig-the-universal-process-develop/cig-the-universal-process-develop.component';
import { CigTheUniversalProcessImplementComponent } from './components/cig/cig-the-universal-process-implement/cig-the-universal-process-implement.component';
import { CigYourClarifyScoreComponent } from './components/cig/cig-your-clarify-score/cig-your-clarify-score.component';
import { CigYourIdeatorScoreComponent } from './components/cig/cig-your-ideator-score/cig-your-ideator-score.component';
import { CigYourDevelopScoreComponent } from './components/cig/cig-your-develop-score/cig-your-develop-score.component';
import { CigYourImplementerScoreComponent } from './components/cig/cig-your-implementer-score/cig-your-implementer-score.component';
import { CigWhatYouShouldKnowComponent } from './components/cig/cig-what-you-should-know/cig-what-you-should-know.component';
import { CigWhatNextComponent } from './components/cig/cig-what-next/cig-what-next.component';
import { CigWorkWellWithOthersComponent } from './components/cig/cig-work-well-with-others/cig-work-well-with-others.component';
import { CigCreateYourCollaborationProfileComponent } from './components/cig/cig-create-your-collaboration-profile/cig-create-your-collaboration-profile.component';
import { CigThankyouComponent } from './components/cig/cig-thankyou/cig-thankyou.component';
import { CigProfileComponent } from './components/cig/cig-profile/cig-profile.component';
import { SpacelessPipe } from './pipe/spaceless.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { RemoveStartersPipe } from './pipe/remove-starters.pipe';

import { LearnerMapsComponent } from './components/learner-maps/learner-maps.component';
import { ToolsetComponent } from './components/learner-maps/toolset/toolset.component';


import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LearnerMapsListingComponent } from './components/learner-maps-listing/learner-maps-listing.component';
import { FoursightFrameworkComponent } from './components/foursight-framework/foursight-framework.component';
import { InnovationStationComponent } from './components/innovation-station/innovation-station.component';
import { InnovationClarifyComponent } from './components/innovation-station/innovation-clarify/innovation-clarify.component';
import { InnovationDevelopComponent } from './components/innovation-station/innovation-develop/innovation-develop.component';
import { InnovationIdeateComponent } from './components/innovation-station/innovation-ideate/innovation-ideate.component';
import { InnovationIntroductionComponent } from './components/innovation-station/innovation-introduction/innovation-introduction.component';
import { InnovationImplementerComponent } from './components/innovation-station/innovation-implementer/innovation-implementer.component';
import { ToolcardsComponent } from './components/toolcards/toolcards.component';
import { ToolcardsActionStepsComponent } from './components/toolcards/toolcards-action-steps/toolcards-action-steps.component';
import { ToolcardsAssistersResistersComponent } from './components/toolcards/toolcards-assisters-resisters/toolcards-assisters-resisters.component';
import { ToolcardsBrainwritingComponent } from './components/toolcards/toolcards-brainwriting/toolcards-brainwriting.component';
import { ToolcardsCardSortComponent } from './components/toolcards/toolcards-card-sort/toolcards-card-sort.component';
import { ToolcardsDataQuestionsComponent } from './components/toolcards/toolcards-data-questions/toolcards-data-questions.component';
import { ToolcardsEvaluationMatrixComponent } from './components/toolcards/toolcards-evaluation-matrix/toolcards-evaluation-matrix.component';
import { ToolcardsExcursionsComponent } from './components/toolcards/toolcards-excursions/toolcards-excursions.component';
import { ToolcardsForcedConnectionsComponent } from './components/toolcards/toolcards-forced-connections/toolcards-forced-connections.component';
import { ToolcardsIndexComponent } from './components/toolcards/toolcards-index/toolcards-index.component';
import { ToolcardsLearningCycleComponent } from './components/toolcards/toolcards-learning-cycle/toolcards-learning-cycle.component';
import { ToolcardsManagingRiskComponent } from './components/toolcards/toolcards-managing-risk/toolcards-managing-risk.component';
import { ToolcardsMindMappingComponent } from './components/toolcards/toolcards-mind-mapping/toolcards-mind-mapping.component';
import { ToolcardsOverviewComponent } from './components/toolcards/toolcards-overview/toolcards-overview.component';
import { ToolcardsPerformanceDashboardComponent } from './components/toolcards/toolcards-performance-dashboard/toolcards-performance-dashboard.component';
import { ToolcardsPhraseChallengesComponent } from './components/toolcards/toolcards-phrase-challenges/toolcards-phrase-challenges.component';
import { ToolcardsPraiseFirstComponent } from './components/toolcards/toolcards-praise-first/toolcards-praise-first.component';
import { ToolcardsScamperComponent } from './components/toolcards/toolcards-scamper/toolcards-scamper.component';
import { ToolcardsStakeholderAnalysisComponent } from './components/toolcards/toolcards-stakeholder-analysis/toolcards-stakeholder-analysis.component';
import { ToolcardsStickBrainstormingComponent } from './components/toolcards/toolcards-stick-brainstorming/toolcards-stick-brainstorming.component';
import { ToolcardsStoppingYouComponent } from './components/toolcards/toolcards-stopping-you/toolcards-stopping-you.component';
import { ToolcardsStoryboardingComponent } from './components/toolcards/toolcards-storyboarding/toolcards-storyboarding.component';
import { ToolcardsTargetingComponent } from './components/toolcards/toolcards-targeting/toolcards-targeting.component';
import { TsCardComponent } from './components/learner-maps/toolset/ts-card/ts-card.component';
import { TsCardSingleComponent } from './components/learner-maps/toolset/ts-card-single/ts-card-single.component';
import { AccountEmailsComponent } from './components/account-emails/account-emails.component';
import { ToolsComponent } from './components/tools/tools.component';
import { ManageProfilesComponent } from './components/manage-profiles/manage-profiles.component';
import { MemberComponent } from './components/member/member.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AdviceComponent } from './components/advice/advice.component';
import { OrgsComponent } from './components/orgs/orgs.component';
import { OrgsIndividualComponent } from './components/orgs-individual/orgs-individual.component';
import { OrgteamMembersComponent } from './components/orgteam-members/orgteam-members.component';
import { ManageMembersComponent } from './components/manage-members/manage-members.component';
import { ManageTeamMembersComponent } from './components/manage-team-members/manage-team-members.component';
import { ManageSessionMembersComponent } from './components/manage-session-members/manage-session-members.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TeamMembersComponent } from './components/team-members/team-members.component';
import { OrgTrainingSessionMembersComponent } from './components/org-training-session-members/org-training-session-members.component';
import { UserComponent } from './components/user/user.component';
import { TrainingSessionsComponent } from './components/orgs-individual/training-sessions/training-sessions.component';
import { AccountDownloadsComponent } from './components/account-downloads/account-downloads.component';
import { FilterprofilePipe } from './pipe/filterprofile.pipe';
import { GroupProfileComponent } from './components/group-profile/group-profile.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { OrgsTeamPermissionsComponent } from './components/orgs-team-permissions/orgs-team-permissions.component';
import { TeamProfileComponent } from './components/team-profile/team-profile.component';
import { TeamReportComponent } from './components/team-report/team-report.component';
import { TeamCoverComponent } from './components/team-report/team-cover/team-cover.component';
import { FourPreferencesComponent } from './components/team-report/four-preferences/four-preferences.component';
import { TeamClarifierComponent } from './components/team-report/team-clarifier/team-clarifier.component';
import { TeamIdeatorComponent } from './components/team-report/team-ideator/team-ideator.component';
import { TeamDeveloperComponent } from './components/team-report/team-developer/team-developer.component';
import { TeamImplementerComponent } from './components/team-report/team-implementer/team-implementer.component';
import { TeamIntegratorComponent } from './components/team-report/team-integrator/team-integrator.component';
import { TeamCommunicateComponent } from './components/team-report/team-communicate/team-communicate.component';
import { TeamFsProcessComponent } from './components/team-report/team-fs-process/team-fs-process.component';
import { TeamTogetherComponent } from './components/team-report/team-together/team-together.component';
import { TeamFaqComponent } from './components/team-report/team-faq/team-faq.component';
import { TeamFooterComponent } from './components/team-report/team-footer/team-footer.component';
import { MembersComponent } from './components/team-report/members/members.component';
import { ManageWizardsComponent } from './components/manage-wizards/manage-wizards.component';
import { SessionProfileComponent } from './components/orgs-individual/session-profile/session-profile.component';
import { GroupMembersProfileComponent } from './components/group-members-profile/group-members-profile.component';
import { OrgManageMembersComponent } from './components/org-manage-members/org-manage-members.component';
import { OrgIndividualMembersComponent } from './components/org-individual-members/org-individual-members.component';
import { OrgIndividualTeamsComponent } from './components/org-individual-teams/org-individual-teams.component';
import { OrgIndividualSessionsComponent } from './components/org-individual-sessions/org-individual-sessions.component';
import { NewPricingModelComponent } from './components/learner-maps/new-pricing-model/new-pricing-model.component';
import { NewPricingCardComponent } from './components/learner-maps/new-pricing-model/new-pricing-card/new-pricing-card.component';
import { NewPricingCardSingleComponent } from './components/learner-maps/new-pricing-model/new-pricing-card-single/new-pricing-card-single.component';
import { StoreComponent } from './components/store/store.component';
import { StoreCartComponent } from './components/store/store-cart/store-cart.component';
import { StoreOrdersComponent } from './components/store/store-orders/store-orders.component';
import { StoreProductsComponent } from './components/store/store-products/store-products.component';
import { StoreProductDetailsComponent } from './components/store/store-product-details/store-product-details.component';
import { OrderDetailsComponent } from './components/store/store-orders/order-details/order-details.component';
import { OrderStatusComponent } from './components/store/store-orders/order-status/order-status.component';
import { VolumePricingComponent } from './components/store/volume-pricing/volume-pricing.component';
import { AddressesComponent } from './components/addresses/addresses.component';
import { EndlinePipe } from './pipe/endline.pipe';
import { OrderVerifyComponent } from './components/store/order-verify/order-verify.component';
import { VersionCheckService } from './version-check.service';
import { AdminSingleLearnerMapComponent } from './components/admin-single-learner-map/admin-single-learner-map.component';
import { AdminLearnerMapsListComponent } from './components/admin-learner-maps-list/admin-learner-maps-list.component';
import { DynamicLearnerMapComponent } from './components/learner-maps/dynamic-learner-map/dynamic-learner-map.component';
import { DynamicCardSingleComponent } from './components/learner-maps/dynamic-learner-map/dynamic-card-single/dynamic-card-single.component';
import { DynamicCardComponent } from './components/learner-maps/dynamic-learner-map/dynamic-card/dynamic-card.component';
import { YourCertificateComponent } from './components/learner-maps/your-certificate/your-certificate.component';
import { MyBrandingComponent } from './components/my-branding/my-branding.component';
import { CustomProfileComponent } from './components/custom-profile/custom-profile.component';
import { SlideThankyouComponent } from './components/custom-profile/slide-thankyou/slide-thankyou.component';
import { CustomNavigationComponent } from './components/custom-profile/custom-navigation/custom-navigation.component';
import { ImpersonateComponent } from './components/impersonate/impersonate.component';
import { ChallengesComponent } from './components/challenges/challenges.component';
import { ClarifyChallengeComponent } from './components/challenges/clarify-challenge/clarify-challenge.component';
import { IdeateChallengeComponent } from './components/challenges/ideate-challenge/ideate-challenge.component';
import { DevelopChallengeComponent } from './components/challenges/develop-challenge/develop-challenge.component';
import { ImplementChallengeComponent } from './components/challenges/implement-challenge/implement-challenge.component';
import { IntroductionChallengeComponent } from './components/challenges/introduction-challenge/introduction-challenge.component';
import { SummaryChallengeComponent } from './components/challenges/summary-challenge/summary-challenge.component';
import { WarmUpComponent } from './components/challenges/warm-up/warm-up.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { PublicStoreProductsComponent } from './components/store/public-store/public-store-products/public-store-products.component';
import { PublicStoreComponent } from './components/store/public-store/public-store.component';
import { PublicProductDetailsComponent } from './components/store/public-store/public-product-details/public-product-details.component';
import { PublicStoreCartComponent } from './components/store/public-store/public-store-cart/public-store-cart.component';
import { ToolsetGuideComponent } from './components/toolset-guide/toolset-guide.component';
import { AcceptPaypalComponent } from './components/store/accept-paypal/accept-paypal.component';
import { AssociatedEmailsComponent } from './components/associated-emails/associated-emails.component';
import { AdminPdfComponent } from './components/admin-pdf/admin-pdf.component';
import { LearnerMapSlideshowComponent } from './components/learner-maps/dynamic-learner-map/dynamic-card-single/learner-map-slideshow/learner-map-slideshow.component';
import { InterpretiveGuideComponent } from './components/custom-profile/interpretive-guide/interpretive-guide.component';
import { SlidemapComponent } from './components/cig/slidemap/slidemap.component';
import { CustomDashboardComponent } from './components/custom-profile/custom-dashboard/custom-dashboard.component';
import { CustomThinkingProfileComponent } from './components/custom-profile/custom-thinking-profile/custom-thinking-profile.component';
import { CustomCollaborationProfileComponent } from './components/custom-profile/custom-collaboration-profile/custom-collaboration-profile.component';
import { GuideFlipbookComponent } from './components/custom-profile/guide-flipbook/guide-flipbook.component';
import { CustomTpDashboardComponent } from './components/custom-profile/custom-tp-dashboard/custom-tp-dashboard.component';
import { CigVideoComponent } from './components/cig/cig-video/cig-video.component';
import { CigImageWithoutTitleComponent } from './components/cig/cig-image-without-title/cig-image-without-title.component';
import { CigVideoMp4Component } from './components/cig/cig-video-mp4/cig-video-mp4.component';
import { CigVideoFullComponent } from './components/cig/cig-video-full/cig-video-full.component';
import { FsRevealDashboardComponent } from './components/custom-profile/fs-reveal-dashboard/fs-reveal-dashboard.component';
import { FsSlideshowComponent } from './components/fs-slideshow/fs-slideshow.component';
import { CigWistiaComponent } from './components/cig/cig-wistia/cig-wistia.component';
import { SlideshowPrintComponent } from './components/learner-maps/dynamic-learner-map/dynamic-card/slideshow-print/slideshow-print.component';
import { Auth } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { LoaderComponent } from './components/loader/loader.component';
import { FsAccountComponent } from './components/fs-account/fs-account.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { fsProfileEffect} from './store/effect/fs.effects';
import { EffectsModule, Actions } from "@ngrx/effects";
import { reducers } from './store/reducer/fs.reducer';
import { ProfileEffects } from './store/effect/fs-effects';
import { ChallengeNavigatorPrintComponent } from './components/challenge-navigator-print/challenge-navigator-print.component';
import { CigAudioComponent } from './components/cig/cig-audio/cig-audio.component';
import { CnPrintComponent } from './components/cn-print/cn-print.component';
import { CnPrintClarifyComponent } from './components/cn-print/cn-print-clarify/cn-print-clarify.component';
import { CnPrintIdeateComponent } from './components/cn-print/cn-print-ideate/cn-print-ideate.component';
import { CnPrintDevelopComponent } from './components/cn-print/cn-print-develop/cn-print-develop.component';
import { CnPrintImplementComponent } from './components/cn-print/cn-print-implement/cn-print-implement.component';
import { CnPrintIntroComponent } from './components/cn-print/cn-print-intro/cn-print-intro.component';
import { CnPrintNavComponent } from './components/cn-print/cn-print-nav/cn-print-nav.component';
import { CnFrameworkComponent } from './components/cn-print/cn-framework/cn-framework.component';
import { CnPrintTooltipComponent } from './components/cn-print/cn-print-tooltip/cn-print-tooltip.component';
import { CnPrintNotesComponent } from './components/cn-print/cn-print-notes/cn-print-notes.component';
import { CnPrintSummaryComponent } from './components/cn-print/cn-print-summary/cn-print-summary.component';
import { ChallengesNavComponent } from './components/challenges/challenges-nav/challenges-nav.component';
import { DialogboxComponent } from './components/dialogbox/dialogbox.component';
import { GuidePopupComponent } from './components/dialogbox/guide-popup/guide-popup.component';
import { CnResetPopupComponent } from './components/dialogbox/cn-reset-popup/cn-reset-popup.component';
import { CnDeletePopupComponent } from './components/dialogbox/cn-delete-popup/cn-delete-popup.component';
import { CnCardDetailsPopupComponent } from './components/dialogbox/cn-card-details-popup/cn-card-details-popup.component';
import { CnEditCardDetailsPopupComponent } from './components/dialogbox/cn-edit-card-details-popup/cn-edit-card-details-popup.component';
import { CnCopyCardDetailsPopupComponent } from './components/dialogbox/cn-copy-card-details-popup/cn-copy-card-details-popup.component';
import { ToolcardsPerspectivesComponent } from './components/toolcards/toolcards-perspectives/toolcards-perspectives.component';
import { ToolcardAssumptionReversalComponent } from './components/toolcards/toolcard-assumption-reversal/toolcard-assumption-reversal.component';
import { SelectedGroupProfileComponent } from './components/manage-wizards/selected-group-profile/selected-group-profile.component';
import { SelectedGroupProfilePlusComponent } from './components/manage-wizards/selected-group-profile-plus/selected-group-profile-plus.component';
import { PdfDownloadPopupComponent } from './components/dialogbox/pdf-download-popup/pdf-download-popup.component';
import { ChangePasswordPopupComponent } from './components/dialogbox/change-password-popup/change-password-popup.component';
import { ChangeAccountEmailComponent } from './components/dialogbox/change-account-email/change-account-email.component';
import { LinkNewEmailPopupComponent } from './components/dialogbox/link-new-email-popup/link-new-email-popup.component';
import { NewProfilePopupComponent } from './components/dialogbox/new-profile-popup/new-profile-popup.component';
import { AddressPopupComponent } from './components/dialogbox/address-popup/address-popup.component';
import { AddLearnermapPopupComponent } from './components/dialogbox/add-learnermap-popup/add-learnermap-popup.component';
import { AddlmTypePopupComponent } from './components/dialogbox/addlm-type-popup/addlm-type-popup.component';
import { LmlistTypePopupComponent } from './components/dialogbox/lmlist-type-popup/lmlist-type-popup.component';
import { OrgsInputPopupComponent } from './components/dialogbox/orgs-input-popup/orgs-input-popup.component';
import { TeamsInputPopupComponent } from './components/dialogbox/teams-input-popup/teams-input-popup.component';
import { VolumePricingPopupComponent } from './components/dialogbox/volume-pricing-popup/volume-pricing-popup.component';
import { PricingSchedulePopupComponent } from './components/dialogbox/volume-pricing-popup/pricing-schedule-popup/pricing-schedule-popup.component';
import { CalculatedPricePopupComponent } from './components/dialogbox/volume-pricing-popup/calculated-price-popup/calculated-price-popup.component';
import { StripeAddCardComponent } from './components/dialogbox/stripe-add-card/stripe-add-card.component';
import { IssueUncheckedPopupComponent } from './components/dialogbox/issue-unchecked-popup/issue-unchecked-popup.component';
import { CustomPfExpirePopupComponent } from './components/dialogbox/custom-pf-expire-popup/custom-pf-expire-popup.component';
import { MyteamGraphComponent } from './components/teams/myteam-graph/myteam-graph.component';
import { IndividualGraphComponent } from './components/teams/individual-graph/individual-graph.component';
import { MyteamsIndvidualComponent } from './components/teams/myteams-indvidual/myteams-indvidual.component';
import { MyteamsOne2oneComponent } from './components/teams/myteams-one2one/myteams-one2one.component';
import { MyteamUserprofileComponent } from './components/teams/myteam-userprofile/myteam-userprofile.component';
import { MyteamsMemberstabComponent } from './components/teams/myteams-memberstab/myteams-memberstab.component';
import { MyteamsToolstabComponent } from './components/teams/myteams-toolstab/myteams-toolstab.component';
import { MyteamsTabsHeadComponent } from './components/teams/myteams-tabs-head/myteams-tabs-head.component';
import { MyteamsTeammatesTableComponent } from './components/teams/myteams-teammates-table/myteams-teammates-table.component';
import { MyteamsUserAdviceComponent } from './components/teams/myteams-user-advice/myteams-user-advice.component';
import { MyteamsUserToolsComponent } from './components/teams/myteams-user-tools/myteams-user-tools.component';
import { MyteamsGPPComponent } from './components/teams/myteams-gpp/myteams-gpp.component';
// import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AddmemberTeampopupComponent } from './components/dialogbox/addmember-teampopup/addmember-teampopup.component';
import { UpdateTeampopupComponent } from './components/dialogbox/update-teampopup/update-teampopup.component';
import { ParticipantsViewComponent } from './components/teams/participants-view/participants-view.component';
import { MyteamGraphPopupComponent } from './components/teams/myteam-graph-popup/myteam-graph-popup.component';
import { TeamsDashboardComponent } from './components/teams-dashboard/teams-dashboard.component';
import { AllteammatesComponent } from './components/teams-dashboard/allteammates/allteammates.component';
import { RosterAddMemberPopupComponent } from './components/dialogbox/roster-add-member-popup/roster-add-member-popup.component';
import { RosterDelPopupComponent } from './components/dialogbox/roster-del-popup/roster-del-popup.component';
import { RosterDelMemberPopupComponent } from './components/dialogbox/roster-del-member-popup/roster-del-member-popup.component';
import { AssociatedCollaborationProfileComponent } from './components/associated-emails/associated-collaboration-profile/associated-collaboration-profile.component';
import { HierarchicalViewComponent } from './components/org-individual-teams/hierarchical-view/hierarchical-view.component';
import { RosterGraphComponent } from './components/orgs/roster-graph/roster-graph.component';
import { ThinkingProfileIdComponent } from './components/teams/participants-view/thinking-profile-id/thinking-profile-id.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RosterTableViewComponent } from './components/orgs/roster-table-view/roster-table-view.component';
import { RosterHierarchicalViewComponent } from './components/orgs/roster-hierarichal-view/roster-hierarchical-view.component';
import { PresenterResourcesDashboardComponent } from './components/presenter-resources-dashboard/presenter-resources-dashboard.component';
import { PrAdditionalResourcesComponent } from './components/presenter-resources-dashboard/pr-additional-resources/pr-additional-resources.component';
import { PrTrainingComponent } from './components/presenter-resources-dashboard/pr-training/pr-training.component';
import { PrCertificationComponent } from './components/presenter-resources-dashboard/pr-certification/pr-certification.component';
import { PrVideoBasedWorkshopsComponent } from './components/presenter-resources-dashboard/pr-video-based-workshops/pr-video-based-workshops.component';
import { ShareProfileComponent } from './components/share-profile/share-profile.component';
import { ProfileDesciptionPopupComponent } from './components/dialogbox/profile-desciption-popup/profile-desciption-popup.component';
import { BookDemoPopupComponent } from './components/store/public-store/book-demo-popup/book-demo-popup.component';

Amplify.configure(awsmobile);

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        LoginCallbackComponent,
        CollaborationProfileComponent,
        CigComponent,
        FsProfileComponent,
        FsGraphComponent,
        FsIconComponent,
        CigCoverComponent,
        CigWhatFuelsYourCreativityComponent,
        CigTheUniversalProcessComponent,
        CigClarifyComponent,
        CigIdeateComponent,
        CigDevelopComponent,
        CigImplementComponent,
        CigFoursightThinkingPreferencesComponent,
        CigPreferenceDoesNotEqualAbilityComponent,
        CigThereAreNoBadScoresComponent,
        CigCongratulationsComponent,
        CigHereYourFoursightGraphComponent,
        CigThe4StagesComponent,
        CigTheUniversalProcessClarifyComponent,
        CigTheUniversalProcessIdeateComponent,
        CigTheUniversalProcessDevelopComponent,
        CigTheUniversalProcessImplementComponent,
        CigYourClarifyScoreComponent,
        CigYourIdeatorScoreComponent,
        CigYourDevelopScoreComponent,
        CigYourImplementerScoreComponent,
        CigWhatYouShouldKnowComponent,
        CigWhatNextComponent,
        CigWorkWellWithOthersComponent,
        CigCreateYourCollaborationProfileComponent,
        CigThankyouComponent,
        CigProfileComponent,
        SpacelessPipe,
        FilterPipe,
        LearnerMapsComponent,
        DashboardComponent,
        LoginComponent,
        InnovationStationComponent,
        InnovationClarifyComponent,
        InnovationDevelopComponent,
        InnovationIdeateComponent,
        InnovationIntroductionComponent,
        InnovationImplementerComponent,
        ToolcardsComponent,
        ToolcardsActionStepsComponent,
        ToolcardsAssistersResistersComponent,
        ToolcardsBrainwritingComponent,
        ToolcardsCardSortComponent,
        ToolcardsDataQuestionsComponent,
        ToolcardsEvaluationMatrixComponent,
        ToolcardsExcursionsComponent,
        ToolcardsForcedConnectionsComponent,
        ToolcardsIndexComponent,
        ToolcardsLearningCycleComponent,
        ToolcardsManagingRiskComponent,
        ToolcardsMindMappingComponent,
        ToolcardsOverviewComponent,
        ToolcardsPerformanceDashboardComponent,
        ToolcardsPhraseChallengesComponent,
        ToolcardsPraiseFirstComponent,
        ToolcardsScamperComponent,
        ToolcardsStakeholderAnalysisComponent,
        ToolcardsStickBrainstormingComponent,
        ToolcardsStoppingYouComponent,
        ToolcardsStoryboardingComponent,
        ToolcardsTargetingComponent,
        ToolsetComponent,
        TsCardComponent,
        TsCardSingleComponent,
        AccountEmailsComponent,
        FoursightFrameworkComponent,
        ToolsComponent,
        LearnerMapsListingComponent,
        ManageProfilesComponent,
        MemberComponent,
        UserProfileComponent,
        AdviceComponent,
        OrgsComponent,
        FilterPipe,
        OrgsIndividualComponent,
        OrgteamMembersComponent,
        ManageMembersComponent,
        ManageTeamMembersComponent,
        ManageSessionMembersComponent,
        TeamsComponent,
        TeamMembersComponent,
        OrgTrainingSessionMembersComponent,
        UserComponent,
        TrainingSessionsComponent,
        AccountDownloadsComponent,
        FilterprofilePipe,
        GroupProfileComponent,
        CertificateComponent,
        PermissionsComponent,
        OrgsTeamPermissionsComponent,
        TeamProfileComponent,
        TeamReportComponent,
        TeamCoverComponent,
        FourPreferencesComponent,
        TeamClarifierComponent,
        TeamIdeatorComponent,
        TeamDeveloperComponent,
        TeamImplementerComponent,
        TeamIntegratorComponent,
        TeamCommunicateComponent,
        TeamFsProcessComponent,
        TeamTogetherComponent,
        TeamFaqComponent,
        TeamFooterComponent,
        MembersComponent,
        ManageWizardsComponent,
        SessionProfileComponent,
        GroupMembersProfileComponent,
        OrgManageMembersComponent,
        OrgIndividualMembersComponent,
        OrgIndividualTeamsComponent,
        OrgIndividualSessionsComponent,
        NewPricingModelComponent,
        NewPricingCardComponent,
        NewPricingCardSingleComponent,
        StoreComponent,
        StoreCartComponent,
        StoreOrdersComponent,
        StoreProductsComponent,
        StoreProductDetailsComponent,
        OrderDetailsComponent,
        OrderStatusComponent,
        VolumePricingComponent,
        AddressesComponent,
        EndlinePipe,
        OrderVerifyComponent,
        AdminSingleLearnerMapComponent,
        AdminLearnerMapsListComponent,
        DynamicLearnerMapComponent,
        DynamicCardSingleComponent,
        DynamicCardComponent,
        YourCertificateComponent,
        MyBrandingComponent,
        CustomProfileComponent,
        SlideThankyouComponent,
        CustomNavigationComponent,
        ImpersonateComponent,
        ChallengesComponent,
        ClarifyChallengeComponent,
        IdeateChallengeComponent,
        DevelopChallengeComponent,
        ImplementChallengeComponent,
        IntroductionChallengeComponent,
        SummaryChallengeComponent,
        WarmUpComponent,
        AutofocusDirective,
        RemoveStartersPipe,
        PublicStoreProductsComponent,
        PublicStoreComponent,
        PublicProductDetailsComponent,
        PublicStoreCartComponent,
        ToolsetGuideComponent,
        AcceptPaypalComponent,
        AssociatedEmailsComponent,
        AdminPdfComponent,
        LearnerMapSlideshowComponent,
        InterpretiveGuideComponent,
        SlidemapComponent,
        CustomDashboardComponent,
        CustomThinkingProfileComponent,
        CustomCollaborationProfileComponent,
        GuideFlipbookComponent,
        CustomTpDashboardComponent,
        CigVideoComponent,
        CigImageWithoutTitleComponent,
        CigVideoMp4Component,
        CigVideoFullComponent,
        FsRevealDashboardComponent,
        FsSlideshowComponent,
        CigWistiaComponent,
        SlideshowPrintComponent,
        LoaderComponent,
        FsAccountComponent,
        ChallengeNavigatorPrintComponent,
        CigAudioComponent,
        CnPrintComponent,
        CnPrintClarifyComponent,
        CnPrintIdeateComponent,
        CnPrintDevelopComponent,
        CnPrintImplementComponent,
        CnPrintIntroComponent,
        CnPrintNavComponent,
        CnFrameworkComponent,
        CnPrintTooltipComponent,
        CnPrintNotesComponent,
        CnPrintSummaryComponent,
        ChallengesNavComponent,
        DialogboxComponent,
        GuidePopupComponent,
        CnResetPopupComponent,
        CnDeletePopupComponent,
        CnCardDetailsPopupComponent,
        CnEditCardDetailsPopupComponent,
        CnCopyCardDetailsPopupComponent,
        ToolcardsPerspectivesComponent,
        ToolcardAssumptionReversalComponent,
        SelectedGroupProfileComponent,
        SelectedGroupProfilePlusComponent,
        PdfDownloadPopupComponent,
        ChangePasswordPopupComponent,
        ChangeAccountEmailComponent,
        LinkNewEmailPopupComponent,
        NewProfilePopupComponent,
        AddressPopupComponent,
        AddLearnermapPopupComponent,
        AddlmTypePopupComponent,
        LmlistTypePopupComponent,
        OrgsInputPopupComponent,
        TeamsInputPopupComponent,
        VolumePricingPopupComponent,
        PricingSchedulePopupComponent,
        CalculatedPricePopupComponent,
        StripeAddCardComponent,
        IssueUncheckedPopupComponent,
        CustomPfExpirePopupComponent,
        MyteamGraphComponent,
        IndividualGraphComponent,
        MyteamsIndvidualComponent,
        MyteamsOne2oneComponent,
        MyteamUserprofileComponent,
        MyteamsMemberstabComponent,
        MyteamsToolstabComponent,
        MyteamsTabsHeadComponent,
        MyteamsTeammatesTableComponent,
        MyteamsUserAdviceComponent,
        MyteamsUserToolsComponent,
        MyteamsGPPComponent,
        AddmemberTeampopupComponent,
        UpdateTeampopupComponent,
        ParticipantsViewComponent,
        MyteamGraphPopupComponent,
        TeamsDashboardComponent,
        AllteammatesComponent,
        RosterAddMemberPopupComponent,
        RosterDelPopupComponent,
        RosterDelMemberPopupComponent,
        AssociatedCollaborationProfileComponent,
        HierarchicalViewComponent,
        RosterGraphComponent,
        ThinkingProfileIdComponent,
        RosterTableViewComponent,
        RosterHierarchicalViewComponent,
        PresenterResourcesDashboardComponent,
        PrAdditionalResourcesComponent,
        PrTrainingComponent,
        PrCertificationComponent,
        PrVideoBasedWorkshopsComponent,
        ShareProfileComponent,
        ProfileDesciptionPopupComponent,
        BookDemoPopupComponent,
    ],
    imports: [
        NgxDatatableModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        LayoutsModule,
        DragDropModule,
        // NgxSkeletonLoaderModule,
        // NgbModule,
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule,
        CKEditorModule,
        BsDatepickerModule.forRoot(),
        // DatepickerModule,
        // AmplifyAngularModule,
        StoreModule.forRoot({'profile': reducers}),
        EffectsModule.forRoot([ProfileEffects]),
        StoreDevtoolsModule.instrument(),
        RouterModule.forRoot(ROUTES, { useHash: false})
    ],
    providers: [
        Auth,
        VersionCheckService,
        // AmplifyService,
        TeamMembersComponent,
        ManageProfilesComponent,
        TrainingSessionsComponent,
        OrgsIndividualComponent,
        OrgIndividualTeamsComponent,
        OrgIndividualSessionsComponent,
        OrgIndividualMembersComponent,
        AuthGuardService,
        {
            provide: LocationStrategy, useClass: PathLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
