import { Component } from '@angular/core';
import { OrgIndividualTeamsComponent } from '../../org-individual-teams/org-individual-teams.component';
import { CommonDataService } from '../../common-data.service';

@Component({
  selector: 'app-roster-del-member-popup',
  templateUrl: './roster-del-member-popup.component.html',
  styleUrls: ['./roster-del-member-popup.component.scss']
})
export class RosterDelMemberPopupComponent {
  constructor(public orgIndivTeams: OrgIndividualTeamsComponent, private commonDataService: CommonDataService) {}

  public close() {
    $('.roster-del-mem').css('display','none');
  }
}
