// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-actions__opr {
  padding: 2px 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/orgs/roster-table-view/roster-table-view.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR","sourcesContent":[".team-actions {\n    &__opr {\n        padding: 2px 4px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
