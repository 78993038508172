import { Component,Input } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { Auth } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment';
import {CommonDataService} from './../../common-data.service';
import {AccessGroupService} from './../../access-group.service';
import { HttpClient } from '@angular/common/http';
// Import the AuthService type from the SDK
// import { AuthService } from '@auth0/auth0-angular';
import { concatMap, pluck, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fsProfileActions from "../../../store/action/fs.action";
import { TeamReportServiceService } from '../../team-report/team-report-service.service';

declare var jQuery:any;

@Component({
  selector: "navigation",
  templateUrl: "navigation.template.html",
})
export class NavigationComponent {
  // app:any;
  public env;
  menu_links;
  public profile: any;
  public Profiles: any;
  sideNav: boolean = true;
  access_group: any;
  store_url: string = environment.store_url;
  store: string = environment.store;
  assessment_admin: string = environment.assessment_admin;
  app_v: string = environment.appv;
  allmaps: any;
  email_verified: string = "false";
  email: string = "";
  active_menu: boolean = true;
  prev_item_index:number;
  storeNgrx:any;
  ngrxAccessGroup:any;
  userProfile:any;
  allmapslength:number;
  lmHeadings:any = [];
  lmHeadingsRecords:any = [];

  constructor(
    public http: HttpClient,
    private router: Router,
    public service: CommonDataService,
    public accessgroup: AccessGroupService,
    public auth: Auth,
    public ngrxstore: Store<any>,
    public teamReport: TeamReportServiceService
  ) {}

  async ngOnInit() {
    this.storeNgrx = this.ngrxstore.select<any>((state) => state?.profile?.account?.default_profile);
    this.ngrxAccessGroup = this.ngrxstore.select<any>((state) => state.profile.accessGroupData);
    this.env = environment;
    this.storeActions();
    if (localStorage.getItem('impersonation') == 'false') {
        await this.auth.getProfile((err, profile) => {
            console.log(profile);
            this.profile = profile;
            this.noAuthProfile();
            let len = 15;
            if(profile.email.length > len) {
                for(let i = 0; i < len; i++) {
                    this.email += profile.email[i];
                    // console.log('i-->',profile.email[i]);
                }
                this.email += '...';
                console.log(this.email);
            } else {
                this.email = profile.email;
                this.email += '...';
            }
        });
        console.log(this.profile)
    }
    this.accessgroup.data().subscribe(message => { 
        if(message){  
            this.allmaps = [];              
            this.menu_links = message;
            this.lmHeadings = Object?.keys(this.menu_links?.main_menu?.learner_maps)
            this.lmHeadings.forEach((key,i) => {
                console.log(key)
                let tempRecords = {}
                tempRecords[key] = this.menu_links?.main_menu?.learner_maps[key]
                this.lmHeadingsRecords.push(tempRecords)
                console.log(Object?.keys(this.menu_links?.main_menu?.learner_maps[this.lmHeadings[i]]))
            })
            console.log(this.lmHeadingsRecords)
            if(this.menu_links?.main_menu?.learner_maps != null) {
                this.allmapslength = Object?.keys(this.menu_links?.main_menu?.learner_maps)?.length;
            }
            console.log(this.allmapslength);
            console.warn(this.menu_links)
            this.email_verified = 'true';
            if(this.menu_links.main_menu?.learner_maps != null){
                for(var i in this.menu_links?.main_menu.learner_maps){
                    if(this.menu_links?.main_menu?.learner_maps[i].title && this.menu_links.main_menu.learner_maps[i].title != 'My Profile' && this.menu_links.main_menu.learner_maps[i].title != 'Presenting' && this.menu_links.main_menu.learner_maps[i].title != 'Promoting'){
                        this.allmaps.push([i, this.menu_links.main_menu.learner_maps[i]]);
                    }
                }
                this.allmaps.sort(function(a, b) {
                    return parseInt(b[1].priority) - parseInt(a[1].priority);
                });
                console.log(this.allmaps)
            }
            if(this.menu_links.main_menu?.shop && this.menu_links.main_menu.shop == true){
                this.http.get(`${environment.assessment_admin}api/v1/public/stripe_decline_codes.json`)
                .subscribe(
                    res => {                
                        console.log(res);
                        localStorage.setItem('error_codes', JSON.stringify(res));
                    }
                ), err =>{
                    console.log(err);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error(err.errors, 'oops!');
                    }, 1000);
                }
            }
        }
    },
    err => {
        // Log errors if any
        console.log(err);
        if(err.status == 412){
            this.email_verified = 'false';
        }
    })

    // Initialize Scrollbar
    // jQuery("#side-menu .mCustomScrollbar").mCustomScrollbar();
    var that = this;
    jQuery(document).ready(function() {
        jQuery('body').click(function(e) {
            if(e.target.id == "user-nav" || e.target.id == "sidenav"){
                return false;
            }
            else
            that.sideNav = true;
        });            

    })

    this.storeNgrx.subscribe(message => {
        // for(var i=0;i<message.length;i++){
          // if(message[i]?.default) {
            console.log(message)
            this.userProfile = message;
            console.log(this.userProfile);
          // }
        // }
    });

    
    setTimeout(() => {
        this.toggleMenu();
    //     jQuery('.scroll-bar').metisMenu({});    
    }, 2000);
            
}

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     jQuery(".scroll-bar").metisMenu({});
  //   }, 2000);

    // if (jQuery("body").hasClass('fixed-sidebar')) {
    //     jQuery('.sidebar-collapse').slimscroll({
    //         height: '100%'
    //     })
    // }
  // }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  // Toggle Switch for User Nav
  public openNav() {
    console.log("setting features");
    if (this.sideNav) {
      this.sideNav = false;
    } else {
      this.sideNav = true;
    }
  }

  public noAuthProfile() {
    if (!this.profile.name && !this.profile.email) {
      this.storeNgrx.subscribe((res)=> {
        this.Profiles = res;
      })
      if (this.Profiles) {
        if (localStorage.getItem("impersonation") == "true") {
          localStorage.setItem("email", this.Profiles.email);
        }
        if (
          this.Profiles.identity.picture_url == "" ||
          this.Profiles.identity.picture_url == null
        ) {
          this.Profiles.identity.picture_url =
            localStorage.getItem("picture_url");
        }
      } else {
        $(".profile-error").show();
      }
    }
  }

  // Smaller Navbar
  public toggleNavigation() {
    $("body").toggleClass("mini-navbar");
    if (
      !$("body").hasClass("mini-navbar") ||
      $("body").hasClass("body-small")
    ) {
      // Hide menu in order to smoothly turn on when maximize menu
      $("#side-menu").hide();
      // For smoothly turn on menu
      setTimeout(function () {
        $("#side-menu").fadeIn(500);
      }, 100);
    } else {
      // Remove all inline style from jquery fadeIn function to reset menu state
      $("#side-menu").removeAttr("style");
    }

    if ($(".owl-carousel").length) {
      setTimeout(function () {
        var owl = $(".owl-carousel").data("owlCarousel");
        owl.updateVars();
      }, 500);
    }
  }

  // public toggleMenu(e,counter){
  //     e.preventDefault();
  //     // console.log(e.target.parentNode.closest('ul.nav.nav-second-level')
  //     if(e.target?.classList?.contains('active')){
  //         e.target.closest('li').classList.remove('active');
  //         $(`#submenu${counter}`).removeClass('in');
  //         e.target.classList.remove('active');
  //     } else {
  //         e.target.closest('li').classList.add('active');
  //         $(`#submenu${counter}`).addClass('in');
  //         e.target.classList.add('active');
  //     }
  // }
    // public toggleMenu(e,counter){
    //     e.preventDefault();
    //     // console.log(e.target.parentNode.closest('ul.nav.nav-second-level')
    //     if(e.target?.classList?.contains('active')){
    //         e.target.closest('li').classList.remove('active');
    //         $(`#submenu${counter}`).removeClass('in');
    //         e.target.classList.remove('active');
    //     } else {
    //         e.target.closest('li').classList.add('active');
    //         $(`#submenu${counter}`).addClass('in');
    //         e.target.classList.add('active');
    //     }
    // }

    public toggleMenu() {
      $('.scroll-bar>li ul.nav.nav-second-level').removeClass('in')
      let removeIn = () => {
          $('.scroll-bar>li').removeClass('in')
          console.log('nav else');
          let all_item = document.querySelectorAll('.scroll-bar>li');
          all_item.forEach((item,i) => {
              if(item.classList.contains('active')) {
                  item.classList.add('active--clr');
                  item.querySelectorAll('ul.nav.nav-second-level')[0].classList.remove('in')
              }
          });
      }
      setTimeout(() => {
          let all_item = document.querySelectorAll('.scroll-bar>li');
          all_item.forEach((item,i) => {
              if(item.classList.contains('active')) {
                  console.log(i,this.prev_item_index)
                  if(i == this.prev_item_index) {
                      this.prev_item_index = all_item.length + 1;
                      removeIn();
                  } else {
                      this.prev_item_index = i;
                      item.querySelectorAll('ul.nav.nav-second-level')[0].classList.add('in');
                      item.classList.remove('active--clr');
                  }
              }
          });
      }, 50);
  }

  // for my teams default view
  public myTeamsDefaultView() {
    this.teamReport.defaultMyTeamsView.next(true)
  }

  // calling ngrx actions
  public storeActions() {
    // profile action
    this.ngrxstore.dispatch(fsProfileActions.getProfile());
    // access group action
    this.ngrxstore.dispatch(fsProfileActions.getAccessGroup());
  }

  // return space class for sidebar
  public getSpaceClass(fetureName:string) {
    if(fetureName == 'learnermap') {
      if(!this.menu_links?.main_menu?.orgs && !this.menu_links?.main_menu?.manage_profiles && !this.menu_links?.menu_account?.account_downloads) {
        return true 
      }
    } 
    if(fetureName == 'store') {
      if(!this.menu_links?.main_menu?.orgs && !this.menu_links?.main_menu?.manage_profiles && !this.menu_links?.menu_account?.account_downloads && this.allmapslength == 0) {
        return true
      }
    }
  }
    
  
}