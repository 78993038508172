import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TeamReportServiceService } from '../../team-report/team-report-service.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-myteams-one2one',
  templateUrl: './myteams-one2one.component.html',
  styleUrls: ['./myteams-one2one.component.scss']
})
export class MyteamsOne2oneComponent {

  env;
  @Input() userProfile;
  @Input() compareUserProfile;
  allProfiles = ['','Clarifier','Ideator','Developer','Implementer','Early-bird','Analyst','Accelerator','Theorist','Driver','Finisher','Hare','Idea-broker','Optimist','Integrator','Realist'];
  advice;
  recEqn;
  teammateEqn;
  showAll = {'rec': '','teamMate': ''}
  loading:boolean = false;
  ngrxAccount;
  loginUserDetails;
  participantData;
  constructor(public http:HttpClient, public router: ActivatedRoute, private teamReportService: TeamReportServiceService, public routes: Router, public store:Store<any>) {}

  ngOnDestroy() {
    this.teamReportService.oneRatioOne.next(false);
    console.log("vchange value for one ratio one false")
  }

  ngOnInit() {
    this.teamReportService.oneRatioOne.next(true)
    this.ngrxAccount = this.store.select<any>((state) => state.profile.account);
    this.ngrxAccount.subscribe(res=> {
      console.warn('------------->',res)
      this.loginUserDetails = res;
      this.router.params.subscribe((params)=> {
        if(this.routes.url.includes('all-team-members') && this.loginUserDetails){
          let receiver_id;
          let id;
          if(params.id) {
            console.log(this.loginUserDetails)
            receiver_id = this.loginUserDetails?.member.id;
            id = params.id
          } else {
            receiver_id = this.userProfile?.member?.id
            id = this.compareUserProfile?.member_id;
          }
          this.one2oneData(receiver_id,id);
        }
      })
    })
    console.log('userProfile', this.userProfile);
    console.log('compareUserProfile', this.compareUserProfile);
    let id;
    this.router.params.subscribe((params)=> {
      id = params?.id
      console.log(params?.id)
      id = parseInt(id)
      if(this.routes?.url?.includes('member/'+id)){
        this.participantData = JSON.parse(localStorage.getItem('participantData'))
        let recvr_id;
        if(!this.participantData) {
          recvr_id = this.loginUserDetails.member.id
        } else {
           recvr_id = this.participantData?.member_id
        }
        console.warn("participant member id",id)
        this.http.post(`${environment.profileURL}/api/v1/team_advices/advice_121.json`,{receiver_id : recvr_id, teammate_id : id}).subscribe((res:any)=> {
          console.log(res)
          this.advice = res.team_advice;
          this.recEqn = this.advice?.receiver?.default_profile?.profile_equation;
          this.teammateEqn = this.advice.teammate.default_profile.profile_equation;
          this.showAll.rec = this.advice?.receiver?.default_profile?.profile_description_121;
          this.showAll.teamMate = this.advice?.teammate?.default_profile?.profile_description_121;
          if(this.advice?.receiver?.default_profile?.profile_description_121.length > 200) {
            this.showAll.rec = ''
            this.showAll.teamMate = this.advice.rec.default_profile.profile_description_121.substr(0, 200)
          }
          if(this.advice.teammate.default_profile.profile_description_121.length > 200) {
            this.showAll.teamMate = ''
            this.showAll.teamMate = this.advice.teammate.default_profile.profile_description_121.substr(0, 200)
          }
        })
        if(this.participantData) {
          this.loginUserDetails = this.participantData
          console.log(JSON.parse(localStorage.getItem('participantData')),id)
          console.log(this.loginUserDetails)
          console.log(this.routes.url)
        }
        $(".loader-121").css('display','none');
      } else {
        if(!this.routes?.url?.includes('all-team-members')) {
          id = this.compareUserProfile?.member_id;
          this.one2oneData(this.userProfile?.member?.id, id)
          this.loading = false;
          $(".loader-121").css('display','none');
        }
      }
    })

    this.env = environment
    this.loading = true;
    if(this.compareUserProfile?.member_id) {
      id = this.compareUserProfile?.member_id
    }
    

    // this.router.params.subscribe((params)=> {
    //   console.log(params)
    //   this.loading = false;
    // })
    
  }

  public one2oneData(recId, teammateId) {
    this.http.post(`${environment.profileURL}/api/v1/team_advices/advice_121.json`,{receiver_id : recId, teammate_id : teammateId}).subscribe((res:any)=> {
      this.advice = res.team_advice;
      this.recEqn = this.advice?.receiver.default_profile?.profile_equation;
      this.teammateEqn = this.advice?.teammate.default_profile?.profile_equation;
      this.showAll.rec = this.advice?.receiver?.default_profile?.profile_description_121;
      this.showAll.teamMate = this.advice?.teammate?.default_profile?.profile_description_121;
      if(this.advice?.receiver?.default_profile?.profile_description_121?.length > 200) {
        this.showAll.rec = ''
        this.showAll.teamMate = this.advice?.rec?.default_profile?.profile_description_121?.substr(0, 200)
      }
      if(this.advice?.teammate?.default_profile?.profile_description_121?.length > 200) {
        this.showAll.teamMate = ''
        this.showAll.teamMate = this.advice?.teammate?.default_profile?.profile_description_121.substr(0, 200)
      }
      this.loading = false;
    }, err=> {
      setTimeout(function() {
        this.toastr.options = {
            closeButton: true,
            progressBar: true,
            timeOut: 2000
        };
        this.toastr.error('Something went wrong!', 'Oops!');
    }, 1000);
    })
    $(".loader-121").css('display','none');
  }

  public back() {
    window.history.back();
  }

  public showAllText(type) {
    if(type == 'rec') {
      this.showAll.rec = this.advice.teammate.default_profile.profile_description_121;  
    } else {
      this.showAll.teamMate = this.advice.teammate.default_profile.profile_description_121;
    }
  }
}
