import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {PublicProductDetailsComponent} from './public-product-details/public-product-details.component';

declare var hbspt:any;

@Component({
  selector: 'app-public-store',
  templateUrl: './public-store.component.html',
  styleUrls: ['./public-store.component.scss']
})
export class PublicStoreComponent implements OnInit {

    public cartId:any;
    public cart: any;
    env:any;
    hbspt:any;
    havingQueryParams:any;
    bookDemo:boolean = false;
    @ViewChild(PublicProductDetailsComponent) child: PublicProductDetailsComponent;

  constructor(public http: HttpClient, public router: Router, public activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        var hbspt:any;
        console.log(this.child);
        this.activatedRoute.queryParams.subscribe(prm=> {
            this.havingQueryParams = prm;
            console.log(prm);
        })
        this.env = environment;
        jQuery(".child-trigger").on("click" , function(e) {
            e.preventDefault();
            if( window.innerWidth <= 1080 ) {               
                jQuery(this).toggleClass("child-open");
                jQuery(this).next("ul.hs-menu-children-wrapper").slideToggle();
            }
        });

        // hbspt.forms.create({
        //     portalId: "4424590",
        //     formId: "ca3bd281-e525-4727-be55-b06c3a8b1c57",
        //     target: "#hubspotForm",
        //     onFormReady: function($form, ctx){
                // $('#hubspotFormQualification').find('#email-1d4c6c5e-09c8-463e-a355-0452f5694869').val( that.email ).change();
                // $('#hubspotFormQualification').find('.hs-email').hide();
                // $('#hubspotFormQualification').find('.hs-submit').hide();
                // var check = setInterval(function() {
                //     $("#comfortable_in_presenting_lm-1d4c6c5e-09c8-463e-a355-0452f5694869").prop("checked", false);
                // }, 0);
                
                // $('#hubspotFormQualification .hs-form-booleancheckbox').click(function() {
                //     clearInterval(check);
                //     setTimeout(function() {
                //         jQuery('#hubspotFormQualification').find('.hs-submit input').click();
                //     }, 500);
                                     
                // });
        //     }
        // });

        // this.http.get(`${environment.assessment_admin}api/v1/categories.json`).subscribe((data:any) => {
        //     console.warn('categories',data);
        // })

        // this.http.get(`${environment.assessment_admin}api/v1/categories.json`).subscribe((data:any) => {
        //     data?.categories?.forEach(element => {
        //         this.http.get(`${environment.assessment_admin}api/v1/categories/${element.id}.json`).subscribe((result)=>{
        //             console.warn(result);
        //         })
        //     });
        // })

        if(localStorage.getItem('cart_id')){
            this.cartId = localStorage.getItem('cart_id');
            this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId).subscribe(data => {
                console.log(data);
                this.cart = data['order']['line_items'];
            },
                error => {
                    console.log('oops', error);
                    if(error.status == 500){
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 2000
                            };
                            this.toastr.error('Something went wrong!', 'Sorry!');
                        }, 1000);
                    }
                    else if(error.status == 422){
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 4000
                            };
                            if(!error.error.errors.base[0]){
                                this.toastr.error(error.error.errors, 'oops!');
                            }else{
                                this.toastr.error(error.error.errors.base[0], 'oops!');
                            }
                        }, 1000);
                    }else{
                        setTimeout(function() {
                            this.toastr.options = {
                              closeButton: true,
                              timeOut: 2000
                            };
                            this.toastr.error('Something went wrong!', 'oops!');
                        }, 1000);
                    }
                }
            )
        }else{
            this.cart = [];
        }
    }

    public menuToggle(){
        $(".header__menu").slideToggle();
    }

    public changeCart(val){
        this.cart = val['data'];
    }

    public gotoPdt(id, slug){
        if(this.router.url.indexOf('store/') > -1 && !this.router.url.includes('/cart')){
            this.child.gotoPdt(id,slug);
        }else{
            this.router.navigate([`/store/${slug}`])
        }
    }

    public showMenu(){        
        if($('.mobile-trigger').hasClass('active-re')){
            $('.mobile-trigger').removeClass('active-re');
            $('.bm-menu__inner').slideUp();
        }else{
            $('.mobile-trigger').addClass('active-re');
            $('.bm-menu__inner').slideDown();
        }
    }

    public hideMenu(){
        $('.mobile-trigger').addClass('active-re');
    }

    public showPopup(){
        $('.book-demo').removeClass('f-d-none');
    }

}
