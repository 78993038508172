import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { environment } from './../../../../environments/environment'
import FuzzySearch from 'fuzzy-search';
import { CommonDataService } from '../../common-data.service';

@Component({
  selector: 'app-allteammates',
  templateUrl: './allteammates.component.html',
  styleUrls: ['./allteammates.component.scss']
})
export class AllteammatesComponent {


  teammatesData;
  teammates:any;
  searchData;
  srchByFname;
  srchByLname;
  sortFname = 'asc'
  sortLname = 'asc'
  membersPerPage= 20;
  pgCounter:any=1;
  pages = []
  currentPg = 1;
  totalMembers;
  constructor(public http:HttpClient, private commonDataService: CommonDataService) {}

  async ngOnInit() {
    console.log('allteams Components')
    try {
      this.http.get(`${environment.profileURL}/api/v1/users/teammates.json?roster_only=true`).subscribe((res:any)=> {
        console.log("===>all teammates",res)
        this.teammatesData = res?.teammates;
        this.teammates = res.members;
        this.totalMembers = res?.meta?.total_count;
        this.pagination()
      }) 
    } catch (err) {
      console.log(err)
      setTimeout(function() {
        this.toastr.options = {
            closeButton: true,
            progressBar: true,
            timeOut: 2000
        };
        this.toastr.error('Something went wrong', 'Oops!');
    }, 1000);
    }
  }

  pagination() {
    this.pages = [];
    this.pgCounter = this.totalMembers/this.membersPerPage + 1;
    this.pgCounter = parseInt(this.pgCounter);
    for(let i:any = 1; i<=this.pgCounter; i++) {
      this.pages.push(i);
    }
    console.log(this.pgCounter,this.pages)
  }

  changePageNumber(pgNumber) {
    // let data = []
    // this.teammatesData = [];
    this.currentPg = pgNumber
    try {
      this.http.get(`${environment.profileURL}/api/v1/users/teammates.json?roster_only=true&per_page=${this.membersPerPage}&page=${pgNumber}`).subscribe((res:any)=> {
        this.teammates = res.teammates;
        this.teammatesData = res.teammates;
      })
    } catch(err) {
      console.log(err)
      setTimeout(function() {
        this.toastr.options = {
            closeButton: true,
            progressBar: true,
            timeOut: 2000
        };
        this.toastr.error('Something went wrong', 'Oops!');
    }, 1000);
    }
    // console.warn(this.teammates,this.membersPerPage* pgNumber,((pgNumber-1) * this.membersPerPage))
    // for(let i=((pgNumber-1) * this.membersPerPage);i<this.membersPerPage* pgNumber;i++) {
    //   if(i < this.teammates?.length) {
    //     data.push(this.teammates[i])
    //   }
    // }
    // console.log(data)
    // this.teammatesData = data;
  }

  public search(name: 'first_name' | 'last_name'= "first_name") {
    try {
      let data = ''
      if(name == 'first_name'){
        this.srchByLname = '';
        data = this.srchByFname;
      } else {
        this.srchByFname = ''
        data = this.srchByLname;
      }
      if(data.length >=3 || data.length == 0) {
        this.http.get(`${environment.profileURL}/api/v1/users/teammates.json?${name}=${data}&roster_only=true`).subscribe((res:any)=> {
          // this.teammatesData = res?.members;
          let searchBasedOn = 'default_identity.' + name
          console.log(searchBasedOn)
          const searcher = new FuzzySearch(res.teammates, [searchBasedOn], {
            caseSensitive: false,
          });
          this.teammatesData = searcher.search(data)
          // console.log(searcher.search(this.filterRoster))
        })
      }
    } catch(err) {
      console.log(err)
      setTimeout(function() {
        this.toastr.options = {
            closeButton: true,
            progressBar: true,
            timeOut: 2000
        };
        this.toastr.error('Something went wrong', 'Oops!');
    }, 1000);
    }
  }

  public checkMember(name: 'first_name' | 'last_name') {
    setTimeout(() => {
      if((!this.srchByFname && name == 'first_name') || (!this.srchByLname && name == 'last_name')) {
        this.teammatesData = this.teammates;
      }
    }, 100);
  }

  public sorting(name,value) {
    console.log(value)
    if(value == 'asc') {
      value = 'desc'
    }else if( value == 'desc') {
      value = 'asc'
    }
    if(name == 'firstName') {
      this.sortFname = value
    } else {
      this.sortLname = value;
    }
    console.log(this.sortFname, this.sortLname)
    try {
      this.http.get(`${environment.profileURL}/api/v1/users/teammates.json?${name}=${value}`).subscribe((res:any)=> {
        this.teammates = res.teammates;
        this.teammatesData = res.teammates;
      })
    } catch(err) {
      console.log(err)
      setTimeout(function() {
        this.toastr.options = {
            closeButton: true,
            progressBar: true,
            timeOut: 2000
        };
        this.toastr.error('Something went wrong', 'Oops!');
      }, 1000);
    }
  }

  updatedPerPgMem() {
    let data = []
    console.warn(this.teammates)
    this.currentPg = 1;
    if(this.membersPerPage > 20) {
      try {
        this.http.get(`${environment.profileURL}/api/v1/users/teammates.json?per_page=${this.membersPerPage}&page=${this.currentPg}&roster_only=true`).subscribe((res:any)=> {
          this.teammates = res.teammates;
          this.teammatesData = res.teammates;
        })
      } catch(err) {
        console.log(err)
        setTimeout(function() {
          this.toastr.options = {
              closeButton: true,
              progressBar: true,
              timeOut: 2000
          };
          this.toastr.error('Something went wrong', 'Oops!');
      }, 1000);
      }
    }
    for(let i=0;i<this.membersPerPage;i++) {
      if(i < this.teammates.length-1) {
        data.push(this.teammates[i])
      }
    }
    console.log(data)
    this.teammatesData = data;
    this.pagination();
  }

  

}
