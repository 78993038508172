import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() changePos = false;
  @Input() changeTop = false;
  @Input() text;
  @Input() margin = true;

  constructor() { }

  ngOnDestroy() {
    $('.loader-container').css('margin-left','0px');
  }

  ngOnInit(): void {
    console.log(this.margin);
    if(this.margin && !this.changePos) {
      if(document.querySelector('body').classList.contains('mini-navbar')) {
        $('.loader-container').css('margin-left','70px');
      } else {
        $('.loader-container').css('margin-left','220px');
      }
    }
  }

}
