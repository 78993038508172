import { Component } from '@angular/core';
import { OrgIndividualTeamsComponent } from '../../org-individual-teams/org-individual-teams.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CommonDataService } from '../../common-data.service';
@Component({
  selector: 'app-addmember-teampopup',
  templateUrl: './addmember-teampopup.component.html',
  styleUrls: ['./addmember-teampopup.component.scss']
})
export class AddmemberTeampopupComponent {

  teamname:string = "";
  invitationName = "";
  selectAll:boolean = true;
  invitationResult:any;
  SelectedinvitationIds = [];
  selectedMembersCounter:Array<any> = [];
  showMem:number = 2;
  constructor(public orgIndivTeams: OrgIndividualTeamsComponent, public http: HttpClient, private commonDataService:CommonDataService){}

  ngOnInit() {
    console.warn('=======>', this.orgIndivTeams.teams)
    console.warn('=======>', this.orgIndivTeams.selectedRosterMembers)
    this.selectedMembersCounter = this.orgIndivTeams.selectedRosterMembers
    if(!this.orgIndivTeams?.membersDetails4NewTeam?.teams_names && !this.orgIndivTeams?.invite && this.orgIndivTeams.membersDetails4NewTeam?.teams_names) {
      this.orgIndivTeams.membersDetails4NewTeam["teams_names"] = {}
    }
    if(this.orgIndivTeams?.invite) {
      setTimeout(() => {
        this.checkedAll(true)
      }, 100);
    }

    // if(this.orgIndivTeams.invite) {
    //   this.orgIndivTeams.invitationList.forEach(element => {
    //     this.SelectedinvitationIds.push(element.id)
    //   });
    // }

  }
  checkedAll(checked) {
    this.selectAll = !this.selectAll
      var checkboxes = document.querySelectorAll('.teams_popup__team_invitation--chkd input');
    if(checked) {
      checkboxes.forEach((item:any)=> {
          item.checked = true;   
      })
    } else {
        checkboxes.forEach((item:any)=> {
            item.checked = false;   
        })
    }
  }

  public updateInviteChecked(id) {
    let index = this.SelectedinvitationIds.indexOf(id);
    if(index != -1) {
      this.SelectedinvitationIds.splice(index, 1);
    } else {
      this.SelectedinvitationIds.push(id)
    }
    console.log(this.SelectedinvitationIds)
  }

  public searchInvitation() {
    console.log('searching',this.invitationName)
    if(!this.invitationName) {
      this.invitationResult = [];
    }
    if(this.invitationName.length > 2) {
      this.orgIndivTeams.loading = true;
      this.http.get(`${environment.profileURL}/api/v1/organizations/${this.orgIndivTeams?.id}/invitations?per_page=100&name=${this.invitationName}`).subscribe((res:any)=> {
        console.log(res)
        this.invitationResult = res?.invitations
        this.orgIndivTeams.loading = false;
        console.log(this.invitationResult)
      })
    }
  }

  public updateMemberTeam(team,index,checkedValue) {
    // this.orgIndivTeams.checkChanges = 0;
    if(this.orgIndivTeams?.membersDetails4NewTeam?.teams_names[team.id] == team?.name) {
      console.warn("included team member")
      if(this.orgIndivTeams.teams[index].checked == -1){
        console.warn("I am working here")
        this.orgIndivTeams.teams[index].checked = false;
      } else {
        this.orgIndivTeams.teams[index].checked = -1;
      }
    }  else {
      this.orgIndivTeams.teams[index].checked = checkedValue;
    }

    console.warn(team,'safdgfds',this.orgIndivTeams.teams[index].checked, checkedValue)
  }

  public showAllLess() {
    this.showMem == 2 
    ? this.showMem= this.orgIndivTeams?.teamsData?.members?.length 
    : this.showMem = 2;
  }

  public RemoveSelectedItem(i) {
    this.selectedMembersCounter.splice(i,1);
  }

  public clearSelected() {
    this.selectedMembersCounter = [];
    this.showMem = 2;
  }

  // public invitationSent() {
  //   let data = {
  //     invitation_ids : this.SelectedinvitationIds,
  //     team_id: this.orgIndivTeams.inviteTeamId
  //   }
  //   if(!this.SelectedinvitationIds.length) {
  //     this.http.post(`${environment.profileURL}/api/v1/organizations/${this.orgIndivTeams.id}/add_organization_members_to_team.json`,data).subscribe((res:any)=> {
  //       setTimeout(function() {
  //         this.toastr.options = {
  //             closeButton: true,
  //             progressBar: true,
  //             timeOut: 2000
  //         };
  //         this.toastr.success('The Team Name was updated successfully!', 'Nice!');
  //       }, 1000);
  //       this.orgIndivTeams.additionalTeamPopupClose()
  //       this.orgIndivTeams.getTeamsData();
  //     })
  //   } else {
  //     this.http.post(`${environment.profileURL}/api/v1/organizations/${this.orgIndivTeams.id}/add_invitations_members_to_team.json`,data).subscribe((res:any)=> {
  //       setTimeout(function() {
  //         this.toastr.options = {
  //             closeButton: true,
  //             progressBar: true,
  //             timeOut: 2000
  //         };
  //         this.toastr.success('The Team Name was updated successfully!', 'Nice!');
  //       }, 1000);
  //       this.orgIndivTeams.additionalTeamPopupClose()
  //       this.orgIndivTeams.getTeamsData();
  //     })
  //   }
  // }

}
