import { Component, Injector, Input } from '@angular/core';
import { TeamsComponent } from '../teams.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamReportServiceService } from '../../team-report/team-report-service.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import FuzzySearch from 'fuzzy-search';
import { CommonDataService } from '../../common-data.service';

@Component({
  selector: 'app-myteams-teammates-table',
  templateUrl: './myteams-teammates-table.component.html',
  styleUrls: ['./myteams-teammates-table.component.scss']
})
export class MyteamsTeammatesTableComponent {

  indivTeamsFirstName = '';
  indivTeamsLastName = '';
  pfAlert = true;
  memNotFound:boolean = false;
  teamsCompo:any = {};
  pageCounter:Array<number> = []
  params;
  currentPg:number = 0;
  teamName:string;
  @Input() participantAdminTeams = false;
  iconProfileForPopup:any;
  iconPfPopup: boolean = false;


  constructor(private injector: Injector,public router: ActivatedRoute,private teamReport: TeamReportServiceService, public route: Router, public http: HttpClient, private CommonDataService: CommonDataService) {
    this.router.params.subscribe((params)=> {
      if(!params.id) {
        this.teamsCompo = this.injector.get(TeamsComponent);
      }
    })
  }

  // ngOnDestroy() {
  //   this.teamReport.oneRatioOne.next(false);
  // }

  ngOnInit() {
    // console.log('---->',(this.participantAdminTeams))
    // console.warn('---->',(this.route.url))
    this.router.params.subscribe((params)=> {
      if(params['id']) {
        this.params = params?.id
        console.log("TEAM REPORT",this.teamReport.participantTeamData);
        
        if (this.teamReport.participantTeamData) {
          this.teamsCompo["no_profile_counter"] = this.teamReport.participantTeamData.memberships_count - this.teamReport.participantTeamData.memberships_without_profile_count;
        }
        this.teamName = this.teamReport.participantTeamData.name ? this.teamReport.participantTeamData.name : this.teamReport.participantTeamData.team_name
        this.teamsCompo["members"] = this.teamReport.participantTeamData.allMembers
        localStorage.getItem('membersPerPage') ? this.teamsCompo["membersByPage"] =   localStorage.getItem('membersPerPage') : this.teamsCompo["membersByPage"] = 20
        this.teamsCompo["teamMembersPgBs"] = this.teamReport.participantTeamData.allMembers
        this.teamsCompo["currentPg"] = this.currentPg;
        this.teamsCompo['individualId'] = params.id
        this.perPageMembers();
        console.warn(this.teamsCompo)
        console.warn(this.participantAdminTeams)
      }
    })
  }

  compare1( a, b ) {
    if ( a?.membership_role_id == 4){
      return -1;
    } else {
      return 1;
    }
  }

  public pageNumber(pgno:number) {
    this.teamsCompo["teamMembersPgBs"] = [];
    this.currentPg = pgno - 1
    console.log(this.teamsCompo.membersByPage)
    let prevPgMemb = pgno * this.teamsCompo?.membersByPage;
    if(prevPgMemb > this.teamReport.participantTeamData.allMembers.length) {
        prevPgMemb = this.teamReport.participantTeamData.allMembers.length - ((pgno-1)  * this.teamsCompo?.membersByPage)
    }
    console.log(prevPgMemb)
    this.teamReport.participantTeamData.allMembers.forEach((item,index)=> {
        // console.log('indexpg',index)
        if((this.teamsCompo.membersByPage * (pgno-1)) <= index  && index <this.teamsCompo?.membersByPage * pgno) {
        // if(index > this.membersByPage - 1 && index < prevPgMemb) {
            this.teamsCompo?.teamMembersPgBs.push(item)
        }
    })
    this.teamsCompo["currentPg"] = this.currentPg
    this.teamsCompo.teamMembersPgBs.sort( this.compare1 );
  }

  public perPageMembers() {
        localStorage.setItem('membersPerPage', this.teamsCompo?.membersByPage)
        this.teamsCompo["teamMembersPgBs"] = [];
        this.teamReport.participantTeamData.allMembers?.forEach((item,index)=> {
          // console.log('index',this.teamsCompo["membersByPage"])
          if(index < this.teamsCompo["membersByPage"]) {
            this.teamsCompo?.teamMembersPgBs.push(item)
          }
        })
        // console.warn('===>',this.teamsCompo?.teamMembersPgBs)
        // sorting based on first_name
        // function compare( a, b ) {
        //     let a_name = a?.default_profile?.first_name;
        //     let b_name = b?.default_profile?.first_name;
        //     if(!a_name) {
        //         a_name = "zzzz"
        //     } 
        //     if(!b_name) {
        //         b_name = "zzzz"
        //     }
        //     if ( a_name.toLowerCase() < b_name.toLowerCase() ){
        //         console.log(a?.default_profile?.first_name, "-->", b?.default_profile?.first_name) 
        //       return -1;
        //     }
        //     if ( a_name.toLowerCase() > b_name.toLowerCase() ){
        //         console.log(a?.default_profile?.first_name, "-->", b?.default_profile?.first_name) 
        //       return 1;
        //     }
        //     return 0;
        //   }
          
        // this.teamsCompo.teamMembersPgBs.sort( compare );

        // sorting based on lead id
        this.teamsCompo.teamMembersPgBs.sort( this.compare1 );
        
        // for number of pages
        let pageCounter:any = this.teamReport.participantTeamData.memberships_count/this.teamsCompo.membersByPage;
        // console.log(this.teamsCompo,pageCounter)
        pageCounter = parseInt(pageCounter);
        if(pageCounter > 0) {
          // console.log(pageCounter, 'if blk')
            this.pageCounter = [];
            for(let i = 0;i < pageCounter + 1; i++) {
                this.pageCounter.push(i + 1)
            }
            this.teamsCompo["pageCounter"]  = this.pageCounter;
        } else {
            this.pageCounter = [];
            this.teamsCompo["pageCounter"] = this.pageCounter;
        }
        // console.log(this.teamsCompo)

    }

  public missingPfAlert(){
    this.pfAlert = !this.pfAlert 
  }

  //function to return list of numbers from 0 to n-1 
  numSequence(n: number): Array<number> { 
    return Array(n); 
  }


  public async searchRequest(clear = false, name= 'first_name') {
    let searchBy  = ''
    let value = ''
    if(!clear) {
      if(name == 'first_name') {
        searchBy = 'first_name';
        value = this.indivTeamsFirstName
        this.indivTeamsLastName = ''
      } else {
        searchBy = 'last_name'
        value = this.indivTeamsLastName
        this.indivTeamsFirstName = '';
      }
    }
    console.log(searchBy, value)
    await this.http.get(`${environment.profileURL}/api/v1/teams/${this.teamsCompo.individualId}/memberships.json?last_name_order=asc&${searchBy}=${value}`).subscribe((data:any) => {
      // console.log(data)
      this.teamsCompo.teamMembersPgBs = data?.memberships;
      const searcher = new FuzzySearch(data.memberships, [searchBy], {
          caseSensitive: false,
      });
      this.teamsCompo.teamMembersPgBs = searcher.search(value)
      this.membersNotFound('')
    })
  }
  
  
  membersNotFound(clr) {
    console.warn(clr)
    let counter = 0;
    if(this.indivTeamsFirstName?.length > 0) {
      // console.warn('if')
      for(let i = 0; i<this.teamsCompo?.teamMembersPgBs?.length; i++) {
        let mem:any = this.teamsCompo?.teamMembersPgBs[i];
        console.warn(((mem?.first_name)?.toLowerCase())?.indexOf(this.indivTeamsFirstName?.toLowerCase()) < 0)
        if(((mem?.first_name)?.toLowerCase())?.indexOf(this.indivTeamsFirstName?.toLowerCase()) < 0) {
          this.memNotFound = true;
        } else {
          this.memNotFound = false;
          break;
        }
      }
    } else if(this.indivTeamsLastName?.length > 0 ) {
      console.warn('else if')
      for(let i = 0; i<this.teamsCompo?.teamMembersPgBs?.length; i++) {
        let mem:any = this.teamsCompo?.teamMembersPgBs[i];
        if(((mem?.last_name)?.toLowerCase())?.indexOf(this.indivTeamsLastName?.toLowerCase()) < 0) {
          this.memNotFound = true;
        } else {
          this.memNotFound = false;
          break;
        }
      }
    } else {
      console.log('else')
      this.memNotFound= false;
    }
    if(clr == 'clear') {
      this.memNotFound = false;
    }
    console.warn(this.indivTeamsFirstName.length, this.indivTeamsLastName)
  }

  oneOnOneView(member) {
    console.log(member)
    this.teamReport.oneRatioOne.next(true);
    console.log("change value for one ratio one true",this.route.url,this.route.url.includes('participant/'+ this.params))
    this.teamReport?.participantRightSideMem?.next(member);
    if(this.route.url.includes('participant/'+ this.params)) {
      let url:any = this.route.url;
      url = url.replace("/myteams/",'')
      url = url.replace("/participant/"+this.params,'')
      this.route.navigate([`myteams/${url}/member/${member?.member_id}`])
    }else {
      this.route.navigate([`myteams/${this.params}/member/${member?.member_id}`])
    }
  }

  profileIconProfile(profile) {
    console.log(profile)
    this.iconPfPopup = true;
    this.iconProfileForPopup = profile
  }
}
