import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-roster-graph',
  templateUrl: './roster-graph.component.html',
  styleUrls: ['./roster-graph.component.scss']
})
export class RosterGraphComponent {
  clarifier_low:number = 0;
  clarifier_high:number = 0;
  ideator_low:number = 0;
  ideator_high:number = 0;
  developer_low:number = 0;
  developer_high:number = 0;
  implementor_low:number = 0;
  implementor_high:number = 0;
  integrator:number = 0;
  highest_score:number = 0;
  total_members:number = 0;
  @Input() members;
  @Input() id;
  @Input() totalMem;
  profileValues:boolean = false;

  constructor(public http: HttpClient) {}


  async ngOnInit() {
    if(this.totalMem != 0) {
      $('.graph-loader').css('display','block');
      this.http.get(`${environment.profileURL}/api/v1/organizations/`+this.id+`/memberships.json?per_page=${this.totalMem}`).subscribe((data:any) => {
        console.log('=====>',data)
        this.members = data.memberships;
        this.total_members = this.members?.length;
          // this.showTeam();
        this.members?.forEach(list => {
          const profile = list?.default_profile;
      
          if (profile) {
            // Define a helper function to update scores and highest score
            const updateScore = (scoreType, scoreValue) => {
                if (scoreValue === 'Low') {
                    this[`${scoreType}_low`] = (this[`${scoreType}_low`] || 0) + 1;
                    this.highest_score = Math.max(this.highest_score, this[`${scoreType}_low`]);
                } else if (scoreValue === 'High') {
                    this[`${scoreType}_high`] = (this[`${scoreType}_high`] || 0) + 1;
                    this.highest_score = Math.max(this.highest_score, this[`${scoreType}_high`]);
                }
            };
    
            // Update scores
            updateScore('clarifier', profile.clarifier_score);
            updateScore('ideator', profile.ideator_score);
            updateScore('developer', profile.developer_score);
            updateScore('implementor', profile.implementer_score);
    
            // Check for 'Neutral' scores
            if (profile.clarifier_score === 'Neutral' &&
                profile.ideator_score === 'Neutral' &&
                profile.developer_score === 'Neutral' &&
                profile.implementer_score === 'Neutral') {
                this.integrator = (this.integrator || 0) + 1;
            }
          }
        });
  
        // Set default values if all high and low scores are zero
        const resetScores = (scoreType) => {
            if (this[`${scoreType}_high`] === 0 && this[`${scoreType}_low`] === 0) {
                this[`${scoreType}_high`] = 50;
                this[`${scoreType}_low`] = 50;
            }
        };
  
        resetScores('clarifier');
        resetScores('ideator');
        resetScores('developer');
        resetScores('implementor');

        // Ensure highest score has a default value if it's still zero
        if (this.highest_score === 0) {
            this.highest_score = 50;
        }
        $('.graph-loader').css('display','none');
      })
    } else {
      this.members = false;
    }
  }
}

