import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-share-profile',
  templateUrl: './share-profile.component.html',
  styleUrls: ['./share-profile.component.scss']
})
export class ShareProfileComponent implements OnInit {
  public profile: any;
  env;
  current_profile;
  profile_title: string = "";
  storeNgrx:any;
  profileText:object = {
    1: "This thinking profile wants to understand the situation to be sure they're solving the right problem.",
    2: "This thinking profile likes to think big and come up with new ideas and possibilities.",
    3: "This thinking profile likes to analyze the options and take time to work out the perfect solution.",
    4: "This thinking profile likes to make things happen and push for results.",
    5: "This thinking profile likes to strategize by seeing opportunities and thinking of ideas to address them.",
    6: "This thinking profile likes to piece together the puzzle by analyzing the facts and designing smart, elegant solutions.",
    7: " ...to see problems and eliminate them with a no-nonsense approach.",
    8: "...to tinker with ideas and work out the perfect approach.",
    9: "This profile likes to make new ideas happen, taking the shortest line from idea to result.",
    10: "This profile likes to put well-crafted plans into action, working out the details and delivering them.",
    11:"This profile likes to figure it all out—understand the problem, come up with ideas, and make a plan.",
    12: "...to identify the problem, then come up with a good idea, and run with it.",
    13: "...to take on a challenge and transform it with ideas, solutions, and action.",
    14: "This profile likes to be a team player, taking an inclusive, even-handed approach to problem solving.",
    15: "This profile likes to take a realistic approach—get the facts, make a plan, and deliver results."
  };

  constructor(
    public store: Store<any>
  ) {}


  async ngOnInit() {
    this.storeNgrx = this.store.select<any>((state) => state.profile?.account.default_profile);
    this.env = environment;

    // fetch profile data for login app
    await this.storeNgrx.subscribe((message) => {
      console.log(message)
      // for (var i = 0; i < message.length; i++) {
        this.current_profile = message;
      // }
      this.profile_title = this.current_profile?.profile_title;
      
    });
  }
}