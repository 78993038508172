import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { AccessGroupService } from '../access-group.service';

@Component({
  selector: 'app-fs-account',
  templateUrl: './fs-account.component.html',
  styleUrls: ['./fs-account.component.scss']
})
export class FsAccountComponent implements OnInit {

  account_obj:any;
  env:any;
  breadcrumbFor:string = '';
  menu_links:any;
  email_verified:boolean = false;
  ngrxAccessGroup:any;

  constructor(public router:Router,public accessgroup:AccessGroupService,public store:Store<any>) { }

  async ngOnInit() {
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.env = environment;
    var that = this;

    await this.ngrxAccessGroup.subscribe(message => { 
      console.log(message);
      if(message) {
        that.email_verified = true;
        that.menu_links = message;
      }    
      if(this.router.url.includes('teams-admin')) {
        this.breadcrumbFor = 'FSO Admin';

        this.account_obj = [
          {
            title:'Rosters',
            img_link:`https://s3.ca-central-1.amazonaws.com/assets.foursightonline.com/app/images/dashboard_images/teamsAdmin/rosters.png`,
            active: that.menu_links?.main_menu?.orgs,
            lm_id:'',
            routerlink: `/orgs`
          }
          // ,{
          //   title: 'Members',
          //   img_link:`${this.env.admin_upload}322/original.jpg?1664791016`,
          //   active: that.menu_links?.main_menu?.manage_profiles,
          //   lm_id: '',
          //   routerlink: `/manage-profiles`
          // },{
          //   title: 'Reports',
          //   img_link:`${this.env.admin_upload}328/original.jpg?1665035587`,
          //   active: that.menu_links?.menu_account?.account_downloads,
          //   lm_id: '',
          //   routerlink: `/account-downloads`
          // }
        ]
      } else if(this.router.url.includes('my-account')) {
        this.breadcrumbFor = 'Account';

        that.account_obj = [
          // {
          //   title: 'Store Orders',
          //   img_link:`${this.env.admin_upload}324/original.jpg?1664791080`,
          //   active: true,
          //   lm_id: '',
          //   routerlink: `/custom-store/orders`
          // },{
          //     title:'Store Addresses',
          //     img_link:`${this.env.admin_upload}318/original.jpg?1664790760`,
          //     active: true,
          //     lm_id:'',
          //     routerlink: `/addresses`
          // },
          {
            title: 'Settings',
            img_link:`${this.env.admin_upload}326/original.jpg?1664861595`,
            active: true,
            lm_id: '',
            routerlink: `/settings`
          },
          {
            title: 'Brought to you by',
            img_link:`${this.env.admin_upload}325/original.jpg?1664791557`,
            active: that.menu_links?.menu_link?.assessment_admin,
            lm_id: '',
            routerlink: `/branding`
          }
        ]
      } else if(this.router.url.includes('fs-admin')) {
        this.breadcrumbFor = 'FS System Admin';

        this.account_obj = [
          {
            title:'Learner Maps',
            img_link:`${this.env.admin_upload}321/original.jpg?1664790977`,
            active: that.menu_links?.main_menu?.learner_maps_admin,
            lm_id:'',
            routerlink: `/learner-maps-admin`
          },{
            title:'PDF Downloads',
            img_link:`${this.env.admin_upload}319/original.jpg?1665035963`,
            active: that.menu_links?.menu_account?.admin_pdf,
            lm_id:``,
            routerlink: `/admin-pdf`
          }
        ]
      }
      console.log(that.account_obj)
    })
  }

}
