import { Component, OnInit } from '@angular/core';
import {CommonDataService} from './../common-data.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {AccessGroupService} from './../access-group.service';
import { Store } from '@ngrx/store';
import { int } from 'aws-sdk/clients/datapipeline';
import { DomSanitizer } from '@angular/platform-browser';
import { TeamReportServiceService } from '../team-report/team-report-service.service';
import { LambdaFunctionService } from './../../service/lambda-function.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
	profile:any;
	teams:any = [{id:'skelton',tabName:'graphskelton'},{id:'skelton',tabName:'graphskelton'},{id:'skelton',tabName:'graphskelton'},{id:'skelton',tabName:'graphskelton'}];
	meta:any;
	id:any;
	totalRecords:any;
	team:any;
	isLoading:any;	
	query_string:any;
	count:any;
	curPage:any;
	pageLen:any;
	item:any;
	membersByPage:number = 5;
    teamName:any;
    teamOrg:any;
    ngrxAccessGroup:any;
    env;
    ngrxAccount:any;
    userAccount:any;
    usrDataToggle:boolean = false;
    teamDataToggle:boolean = false;
    individualTeam:boolean = false;
    members:Array<object>;
    oneRatioOne:boolean = false;
    individualId:string = "";
    editState:boolean = false;
    // editStateText:String = "This team's tendency towards clarification means I will need to bring lots of documentation.";
    fieldEdit = 0;
    // editAgreementsTeam:boolean = false;
    teamAgreements:any;
    no_profile_counter:number = 0;
    activeTabValue:any = {teamName:'',index: null};
    teamMembersPgBs:Array<Object> = [];
    oneUserDetails:Object;
    indexForIndTeam:number;
    pageCounter:Array<number> = []
    individualTeamDetails;
    currentPg:number = 0
    ForMyTeamId = localStorage.getItem('ForMyTeamId')
    forMyTeamIndex;
    energyText = '';
    editedPlanResult = '';
    teamPlan = false;
    myTeamTopViewTrack = false;
    editOurPlan = false;
    prevOurPlan = '';
    memberLeaderInTeamsArr = [];

  	constructor(public accessgroup: AccessGroupService, public service : CommonDataService, public http: HttpClient, private router: Router, public store:Store<any>, private sanitizer: DomSanitizer, public teamReports: TeamReportServiceService, public lmbda_service: LambdaFunctionService) { }  

  	async ngOnInit() {
        localStorage.removeItem("participantTeamData");
        localStorage.removeItem("participantsTeamsAdmin");
        this.env = environment;
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        this.ngrxAccount = this.store.select<any>((state) => state.profile.account);

        
        if(this.ForMyTeamId) {
            $('.mytm-loader').css('display','block')
        }
          // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => {
            if(message && message.length > 0){
                if(!message['main_menu'].teams){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.teamReports.defaultMyTeamsView.subscribe(()=> {
            this.individualId = '';
            this.oneRatioOne = false;
            this.individualTeam = false;
            $(".teams_cont").css('display','block');
        })

        this.ngrxAccount.subscribe((res)=> {
            this.userAccount = res
            console.log('userAccount',res)
            this.http.get(`${environment.profileURL}/api/v1/profile_types.json`,{params:{profile:"custom",language:this.userAccount?.default_profile?.language?.short_name}}).subscribe((data:any) => {
                data?.profile_types?.forEach((item:any) => {
                    if(this.userAccount?.default_profile?.profile_title == item?.profile_title) {
                        this.energyText = item?.teams_user_energy
                    }
                });
            })
        })

        this.teamName = '';
        this.teamOrg = '';
  		this.isLoading = true;
  		// this.id = localStorage.getItem('member_id');
        let memberCounter = localStorage.getItem('membersPerPage')
        memberCounter ? this.membersByPage =  parseInt(memberCounter) : this.membersByPage = 20
        this.ngrxAccount.subscribe(async (res)=> {
            console.log(res["member"]?.id)
            this.id = res["member"]?.id;
            if(this.id) {
                await this.http.get(`${environment.profileURL}/api/v1/members/${this.id}/roster_teams.json`).subscribe(data => {
                    console.log(data);
                    this.teams = data['teams'];
                    // add tabName for tab body
                    for(let i =0; i<=this.teams.length; i ++) {
                        // this.teams[i]["tabName"]= "graph" + this.teams[i]?.id
                        // if(ForMyTeamId == team.id) {
                        //     await this.http.get(`${environment.profileURL}/api/v1/teams/`+team.id +`/memberships.json?per_page=${team.memberships_count}`).subscribe(data => {
                        //         team["allMembers"] =  data['memberships'];
                        //     })
                        // } 
                        // if(!ForMyTeamId) {
                            if(this.teams[i]?.memberships_count > 20) {
                                this.http.get(`${environment.profileURL}/api/v1/teams/`+this.teams[i]?.id +`/memberships.json?per_page=${this.teams[i]?.memberships_count}&last_name_order=asc`).subscribe(data => {
                                    this.teams[i]["allMembers"] =  data['memberships'];
                                })
                            } else {
                                this.teams[i]["allMembers"] = this.teams[i]["memberships"]
                            }
    
                            if(this.ForMyTeamId == this.teams[i]?.id) {
                                this.forMyTeamIndex = i
                                this.teams[i]["forTeamAdmin"] = true; 
                                setTimeout(() => {
                                    this.fromTeamsAdmin()
                                }, 1000);
                                break;
                            } 
                    }
                    console.warn('teams data',this.teams)
                    this.meta = data['meta'];
                    this.totalRecords = this.meta.total_count;
                    this.count = this.meta.total_count;
                    this.isLoading = false;
                        this.pageLen = this.meta.total_pages;
                        this.curPage = 0;
                        this.item = [];
                        for(var i = 1; i<=this.pageLen;i++){
                        this.item.push(i);
                    }
                    // if(this.meta.total_count > this.membersByPage){
                        this.render( this.curPage, this.item, true );
                        console.log(data);
                    // }      
                },(err)=> {
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.error(err?.error?.errors, 'Oops!');
                    }, 1000);
                })
            }
        })
  	}

    public fromTeamsAdmin() {
        if(this.ForMyTeamId) {
            setTimeout(() => {
                this.openTeamMembers(this.forMyTeamIndex,this.ForMyTeamId)
                localStorage.removeItem('ForMyTeamId')
                $('.mytm-loader').css('display','none')
            }, 1000);
        }
    }

  	// public openMembers(record){
  	// 	if(record.open){
    //     	record.open = false;
    //   	}else{
    //     	record.open = true;
    //   	}
  	// }

    // edit my plan for this team
    public editPlan(id, myPlan = ""){

        // condition for when user already on edit state and click on another edit plan pencil
        if(this.editState) {
            this.teams.forEach(team => {
                if(this.fieldEdit == team?.id) {
                    console.log(this.editedPlanResult)
                    team.my_plan = this.editedPlanResult;
                }
            });
        }
        this.editState = true;
        this.fieldEdit = id;
        console.log(this.teams)
        this.teams.forEach(team => {
            if(id == team?.id) {
                this.editedPlanResult = myPlan;
            }
        });
        console.log(id, this.editedPlanResult)
    }

    // public editAgreement(){
    //     this.editAgreementsTeam = true;
    // }

    // edit my plan for this team
    public cancelPlan(){
        this.editState = false;
        console.log('edited plan result')
        this.teams.forEach(team => {
            if(this.fieldEdit == team?.id) {
                console.log(this.editedPlanResult)
                team.my_plan = this.editedPlanResult;
            }
        });
        this.fieldEdit = 0;
    }

    // public cancelAgreement(){
    //     this.editAgreementsTeam = false;
    // }

    modifyOurPlan(team_plan) {
        this.editOurPlan = true;
        this.prevOurPlan = team_plan;
    }

    cancelOurPlan(record) {
        record.team_plan = this.prevOurPlan;
        this.editOurPlan = false;
    }

    public saveOurPlan(orgId,teamId, planText:string){
        this.editState = false;
        this.fieldEdit = 0;
        let data = {team_plan: planText}
        console.log(data)
        try {
            // console.log('implement',id)
            // this.http.post(`${environment.profileURL}/api/v1/teams/${id}/update_my_plan.json`,data)
            this.http.post(`${environment.profileURL}/api/v1/teams/${teamId}/update_team_plan.json`,data)
            .subscribe(
                res => {	
                    this.editOurPlan = false    		
                    console.log(res);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Plan updated Successfully!', 'Nice!');
                    }, 1000);

                }
            ) 
        } catch(err) {
            console.log(err)
        }
    }

    // save my plan for this team
    public savePlan(id, planText:string){
        this.editState = false;
        this.fieldEdit = 0;
        let data = {my_plan: planText}
        try {
            // console.log('implement',id)
            this.http.post(`${environment.profileURL}/api/v1/teams/${id}/update_my_plan.json`,data)
            .subscribe(
                res => {	    		
                    console.log(res);
                    setTimeout(function() {
                        this.toastr.options = {
                            closeButton: true,
                            progressBar: true,
                            timeOut: 2000
                        };
                        this.toastr.success('Plan updated Successfully!', 'Nice!');
                    }, 1000);
                }
            ) 
        } catch(err) {
            console.log(err)
        }
    }

    // public saveAgreement(id){
    //     this.fieldEdit = 0;
    //     let params = { team_agreements : this.teamAgreements }
    //     try {
    //         // console.log('implement',id)
    //         this.http.put(`${environment.profileURL}/api/v1/teams/${id}/update_team_agreements`,'',{params})
    //         .subscribe(
    //             res => {	    		
    //                 console.log(res);
    //                 this.editAgreementsTeam = false;
    //                 setTimeout(function() {
    //                     this.toastr.options = {
    //                         closeButton: true,
    //                         progressBar: true,
    //                         timeOut: 2000
    //                     };
    //                     this.toastr.success('Agreement updated Successfully!', 'Nice!');
    //                 }, 1000);
    //             }
    //         ) 
    //         } catch(err) {
    //             console.log(err)
    //         }
    // }

  	public isPageInRange = function( curPage, index, maxPages, pageBefore, pageAfter ) {
        if (index <= 1) {
	        // first 2 pages
	        return true;
        }
        if (index >= maxPages - 2) {
	        // last 2 pages
	        return true;
        }
        if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
	        return true;
        }
	}
  	public render = function( curPage, item, first ) {
        var html = '', separatorAdded = false;
        for(var i in item){
            if ( this.isPageInRange( curPage, i, this.pageLen, 2, 2 ) ) {
                html += '<li data-page="' + i + '">' + item[i] + '</li>';
                // as we added a page, we reset the separator Added
                separatorAdded = false;
            } else {
                if (!separatorAdded) {
                    // only add a separator when it wasn't added before
                    html += '<li class="separator" />';
                    separatorAdded = true;
                }
            }
        }
        var holder:any = document.querySelector('#holder'); 
        // if(this.meta.total_count > this.membersByPage){ 
            if(holder) {
                holder.innerHTML = html;
                if(document.querySelector('#holder>li[data-page="' + curPage + '"]') != null){document.querySelector('#holder>li[data-page="' + curPage + '"]').classList.add('active');}
                if ( first ) {
                    holder.addEventListener('click', e => {
                        console.log(e.target['attributes']['data-page'].value);
                        if (!e.target['attributes']['data-page'].value) {
                            // no relevant item clicked (you could however offer expand here )
                            return;
                        }
    
                        curPage = parseInt(e.target['attributes']['data-page'].value);
                        var page_number = curPage+1;
                        this.isLoading = true;
                        this.http.get(`${this.query_string}&page=${page_number}`).subscribe(data => {
                            console.log(data);
                            this.teams = data['teams'];
                            this.meta = data['meta']; 
                            this.count = this.meta.total_count;
                            this.pageLen = this.meta.total_pages;
                            this.isLoading = false;
                            this.curPage = 0;
                            this.item = [];
                            for(var i = 1; i<=this.pageLen;i++){
                                this.item.push(i);
                            }
    
                        },
                            error => console.log('oops', error)
                        )
                        this.render( curPage, item );
                    });
                }
            }
        // }else{
        //     holder.innerHTML = null;
        // }
    }

    // public Filter(){
    //     this.teams = [];
    // 	this.query_string = environment.profileURL+`/api/v1/members/`+this.id+`/teams.json?admin=0&per_page=20`;
    //     if(this.teamName){
    //         this.query_string += '&name=' + this.teamName;
    //     }  
    //     if(this.teamOrg){
    //         this.query_string += '&org_name=' + this.teamOrg;
    //     }      
    //     this.isLoading = true;
    //     console.log(this.query_string);
    //     this.http.get(`${this.query_string}`).subscribe(data => {
    //         this.teams = data['teams'];
    //         this.meta = data['meta']; 
    //         this.count = this.meta.total_count;
    //         this.pageLen = this.meta.total_pages;
    //         this.curPage = 0;
    //         this.item = [];
    //         this.isLoading = false;
    //         for(var i = 1; i<=this.pageLen;i++){
    //             this.item.push(i);
    //         }
    //         console.log(true);
    //     	this.render( this.curPage, this.item, true );
    //     	console.log(data);        
    //     },
    //         error => console.log('oops', error)
    //     )
    // }

    // public resetTeamName() {
    //     this.teamName = '';
    //     this.Filter();
    // }

    // public resetOrgName() {
    //     this.teamOrg = '';
    //     this.Filter();
    // }

    // slide toggle teams page data
    public toggleTeam(toggleClass,e) {
        console.log('event', e)
        e.target.classList.toggle('active');
        $(`.${toggleClass}`).slideToggle();
    }

    // show hide team members
    public openTeamMembers(index, id) {
        console.log("for team id", localStorage.getItem('ForMyTeamId'))
        console.log("open team members",id,index)
        this.individualId = id;
        this.oneRatioOne = false;
        console.warn('back button view',this.individualTeam,this.oneRatioOne)
        if(!this.oneRatioOne) {
            this.individualTeam = !this.individualTeam
        }

        if(this.myTeamTopViewTrack) {
            this.myTeamTopViewTrack =  false;
            $(`._toggle_usr_blk`).eq(0).css('display','block') 
            $(`.fa.cursor-pointer.fa-chevron-up`).eq(0).addClass('active')
        }
        
        if(id) {
            // add class when we hide top view and goto next step
            if(!$('.fa.cursor-pointer.fa-chevron-up').hasClass('active')) {
                this.myTeamTopViewTrack = true;
                $(`._toggle_usr_blk`).eq(0).css('display','block') 
                $(`.fa.cursor-pointer.fa-chevron-up`).eq(0).addClass('active')
            }
            console.warn('hide teams_cont',index);
            $(".teams_cont").css('display','none');
            let selectedItem = document.querySelectorAll(".teams_cont")[index] as HTMLElement;
            console.warn('hide2',document.querySelectorAll(".teams_cont"))
            if(selectedItem) {
                selectedItem.style.display = 'block';
                this.indexForIndTeam = index
            }

            this.members = this.teams[index]?.allMembers;
            this.perPageMembers();

            // For agreement data
            this.teams.forEach(item => {
                if(item.id == this.individualId) {
                    let counter = 0;
                    let counterPf = 0;
                    console.warn('all members',item.memberships.length)
                    item.memberships.forEach((pf)=> {
                        if(pf.default_profile) {
                            counterPf += 1; 
                        } else {
                            counter += 1
                        }
                    })
                    console.warn('profile',counterPf,'noprofile',counter)
                    this.individualTeamDetails = item
                    this.teamAgreements = item.team_agreements;
                    console.log('after',item)  
                    this.no_profile_counter = item?.memberships_count - item?.memberships_without_profile_count;
                }
            });
        } else {
            $(".teams_cont").css('display','block');
            this.individualTeam = false;
        }

        // teams in which logged in user is leader 
        this.teams.forEach((team)=> {
            if(this.individualId == team?.id) {
                team.allMembers?.forEach((item,index)=> {
                    if(this.userAccount?.member?.id == item.member_id && item?.membership_role_id == 4) {
                        this.memberLeaderInTeamsArr.push(team?.id)
                    }
                }) 
            }

        })
        console.warn("==========>",this.memberLeaderInTeamsArr)
    }

    public oneOnOneView(compUserName:any = '') {
        console.log('one ration one view', this.individualTeam)
        console.warn(compUserName)
        this.oneRatioOne = !this.oneRatioOne;
        if(!this.oneRatioOne) {
            setTimeout(() => {
                $(".teams_cont").css('display','none');
                let selectedItem = document.querySelectorAll(".teams_cont")[this.indexForIndTeam] as HTMLElement;
                if(selectedItem) {
                    selectedItem.style.display = 'block';
                    this.indexForIndTeam = this.indexForIndTeam
                }
                
            }, 1000);
        }
        if(compUserName) {
            this.oneUserDetails = compUserName;
        }
    }

    public getTabData(data) {
        this.activeTabValue = data;
        this.teams[data.index].tabName = data.tabName;
    }

    // sorting based on lead id
    compare1( a, b ) {
        if ( a?.membership_role_id == 4){
          return -1;
        } else {
          return 1;
        }
    }


    public perPageMembers() {
        localStorage.setItem('membersPerPage', this.membersByPage + '');
        this.teamMembersPgBs = [];
        this.members?.forEach((item,index)=> {
          console.log('index',this.membersByPage)
          if(index < this.membersByPage) {
            this.teamMembersPgBs.push(item)
          }
        })
        console.warn('===>',this.teamMembersPgBs)
        // sorting based on first_name
        // function compare( a, b ) {
        //     let a_name = a?.default_profile?.first_name;
        //     let b_name = b?.default_profile?.first_name;
        //     if(!a_name) {
        //         a_name = "zzzz"
        //     } 
        //     if(!b_name) {
        //         b_name = "zzzz"
        //     }
        //     if ( a_name.toLowerCase() < b_name.toLowerCase() ){
        //         console.log(a?.default_profile?.first_name, "-->", b?.default_profile?.first_name) 
        //       return -1;
        //     }
        //     if ( a_name.toLowerCase() > b_name.toLowerCase() ){
        //         console.log(a?.default_profile?.first_name, "-->", b?.default_profile?.first_name) 
        //       return 1;
        //     }
        //     return 0;
        //   }
          
        // this.teamMembersPgBs.sort( compare );
        this.teamMembersPgBs.sort( this.compare1 );
        
        // for number of pages
        let pageCounter:any = this.members?.length/this.membersByPage;
        pageCounter = parseInt(pageCounter);
        console.log(pageCounter)
        if(pageCounter > 0) {
            this.pageCounter = [];
            for(let i = 0;i < pageCounter + 1; i++) {
                this.pageCounter.push(i + 1)
            }
        } else {
            this.pageCounter = [];
        }
    }

    public pageNumber(pgno:number) {
        this.teamMembersPgBs = [];
        this.currentPg = pgno - 1
        console.log(this.membersByPage)
        let prevPgMemb = pgno * this.membersByPage;
        if(prevPgMemb > this.members.length) {
            prevPgMemb = this.members.length - ((pgno-1)  * this.membersByPage)
        }
        console.log(prevPgMemb)
        this.members.forEach((item,index)=> {
            console.log('indexpg',index)
            if((this.membersByPage * (pgno-1)) <= index  && index <this.membersByPage * pgno) {
            // if(index > this.membersByPage - 1 && index < prevPgMemb) {
                this.teamMembersPgBs.push(item)
            }
        })
        this.teamMembersPgBs.sort( this.compare1 );
    }


    public secretText(usrEmail, length = 5) {
        return usrEmail?.substring(0,length) + '...';
    }

}
