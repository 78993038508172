import { Component, Input, OnInit } from '@angular/core';
// import { Auth } from '../../auth/auth.service';
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {CommonDataService} from './../../../common-data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {AccessGroupService} from './../../../access-group.service';
import * as AWS from 'aws-sdk';
import { LambdaFunctionService } from '../../../../service/lambda-function.service';
import { select, Store } from '@ngrx/store';
// import { selectProfile, selectProfileCollection, } from '../../store/selector/fs.selectors';
import { errorSelector, isLoadingSelector, profileSelector} from '../../../../store/selector/fs.selectors';
import * as fsProfileActions from "../../../../store/action/fs.action"

@Component({
  selector: 'app-thinking-profile-id',
  templateUrl: './thinking-profile-id.component.html',
  styleUrls: ['./thinking-profile-id.component.scss']
})
export class ThinkingProfileIdComponent {

    @Input() forTeamCompo:boolean= true;
    public profiles: any;
    public sysdate:number = 2023;
    public modal:boolean = false;
    public modal1:any;
    public env;
    public Profile;
    public oldValue;
    public fieldEdit:number;
    public profileId:number;
    routerSubscription: Subscription;
    id:number;
    getProfile:boolean = false;
    brand_identity:any;
    args:any;
    public member_id:string;
    isChrome: any;
    isSafari: any;
    letter_format:string = 'Download Profile PDF (US Letter)';
    a4_format:string = 'Download Profile PDF (International A4)';
    image_content:string = 'Download Profile PNG';
    date_of_profile: any;
    organization_title: any;
    thinking_profile_heading: any;
    thinking_profile_message: any;
    download:any;
    share_my_profile:any;
    explain_my_profile:any;
    low_preference: any;
    high_preference: any;
    contrast: any;
    clarify:any;
    ideate:any;
    develop:any;
    implement:any;
    prefers_to:any;
    get_report: any;
    sign_up_with:any;
    guide:any;
    guides:any;
    short_guide:string;
    long_guide:string;
    downloadingPdf:boolean = false;
    selected_format:string = 'letter';
    selected_lang:string = 'en';
    pf_description:string = '';
    pf_eqn:string = '';
    pf_title = '';
    pf_email_hd = 'Profile Email:';
    fspf:any;
    storeNgrx:any;
    accountNgrx:any;
    ngrxAccessGroup:any;
    gen_pdf:any = '';
    loader:boolean = false;
    file_type:string = 'PDF';
    guidesLang:any;
    download_txt:string = 'Download';
    profileAllData;

    constructor(public accessgroup:AccessGroupService, public activatedRoute:ActivatedRoute,private router: Router, private location: Location, public http: HttpClient, public service:CommonDataService,private modalService: NgbModal,public lmda_service:LambdaFunctionService,public store:Store<any> ) { }

    cancel() {
        this.location.back(); // <-- go back to previous location on cancel
    }

    public printprofile() {
        // this.modal.close();
        $('.navbar-default').hide();
        $('body').addClass('print-body');
        window.print();
        $('.navbar-default').show();
        $('body').removeClass('print-body');
    }

    // To open template
    public open(content,pf=''){
        $('.pdf-modal').css('display','block');
        this.modal = true;
        // this.modal = this.modalService.open(content, { windowClass: 'in grey' });
        if(pf != '') {
            setTimeout(function() {
                $('.modal-dialog').addClass('pf-popup');
            })
        }
    }
    
    public printInfo() {

    }

    async ngOnInit() {
        // this.storeNgrx = this.store.select<any>((state) => state.profile.account.default_profile);
        // this.accountNgrx = this.store.select<any>((state) => state.profile.account);
        this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
        localStorage.setItem('profile_slideshow','true');
        this.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
        this.isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
        this.env = environment;

        console.warn('teams',this.router.url)
        if(this.router.url != '/myteams') {
            this.service.pf_selected_language.subscribe((val)=> {
                this.selected_lang = val;
                this.change_language(false);
            });
        }

        await this.activatedRoute.params.subscribe((params)=>{
            console.log(params.id)
            this.getPfUsingId(params?.id);
        });

        // it re-directs url to the dashboard page if access to this page is not permitted.
        this.ngrxAccessGroup.subscribe(message => {
            console.log(message)
            if(message){
                this.Profile = message['main_menu']?.profile?.fs_thinking_profile;
                if(message['main_menu']?.profile?.fs_thinking_profile.profile_graph != true && message.length > 0){
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }

        },
        err => {
            // Log errors if any
            console.log(err);
        })

        this.env = environment;


        jQuery(document).ready(function() {
            $('html,body').animate({
                scrollTop: $('.page-heading').offset().top
              }, 100);
        });

        this.profileLangList();

    }

    // get profile using profile id
    async getPfUsingId(id) {
        console.warn(localStorage.getItem('participantsTeamsAdmin'))
        console.log(localStorage.getItem('participantTeamData'))
        let participantTeamData:any = localStorage.getItem('participantTeamData')
        participantTeamData = JSON.parse(participantTeamData)
        participantTeamData?.memberships.forEach((member,index) => {
            console.warn(member.id == id)
            if(member.id == id) {
              this.profileAllData = member;
              this.profiles = member?.default_profile;
              this.profileId = member?.default_profile.id;
              this.pf_description = member?.default_profile?.profile_description;
              this.pf_eqn = member?.default_profile?.profile_equation;
              this.pf_title = member?.default_profile?.profile_title;
              this.updatedPfData(member?.default_profile,participantTeamData?.memberships,index)
            }
        });
    }

    public updatedPfData(data,message,i) {
        console.warn(data);
        this.date_of_profile = "Profile Date";
        this.organization_title = "Organization";
        this.thinking_profile_heading = 'FourSight<sup>®</sup> Thinking Profile</span>';
        this.thinking_profile_message = 'measures where you gain energy in the innovation process. There are no "good" or "bad" scores. Each of these four preferences has its own strengths and potential weaknesses. The goal is not to change your preferences, but to understand how your preferences are affecting your decision making, your perceptions, your stress levels and your interactions with others.';
        this.download = "Download";
        this.share_my_profile = data['share_my_profile'];
        this.explain_my_profile = data['explain_my_profile'];
        this.low_preference = "Low Preference",
        this.high_preference = "High Preference",
        this.contrast = "Neutral";
        this.clarify = "CLARIFY";
        this.ideate = "IDEATE";
        this.develop = "DEVELOP";
        this.implement = "IMPLEMENT";
        this.prefers_to = data['prefers_to'];
        this.get_report = data['get_report'];
        this.sign_up_with = data['sign_up_with'];
        this.oldValue = message[i];
        this.download_txt ="Download";
        this.arguments(message[i]?.default_profile);
    }

    // get profile language from guide api
    public profileLangList() {
        this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
            console.log(data);
            // Sort by Language name
            this.guidesLang = data['interpretive_guides'].sort(function(a, b){
                return a.language_name.localeCompare(b.language_name);
            });
        },
            error => console.log('oops', error)
        )
    }

    public arguments(message) {
        console.log(message)
        this.args = {
          "date_title": message["date_of_profile"],
          "email_title" : message["profile_email"],
          "id": message["id"],
          "content_type": "pdf",
          "personalized_note": `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${message["email"]}`,
          "template": "profile.html",
          "email": message["email"],
          "date_of_profile": this.date_of_profile,
          "organization_title": this.organization_title,
          "first_name": this.profileAllData.first_name ? this.profileAllData.first_name  : message["first_name"],
          "last_name": this.profileAllData?.last_name ? this.profileAllData?.last_name : message["last_name"],
          "low_preference": "Low Preference",
          "high_preference": "High Preference",
          "contrast": this.contrast,
          "clarify": "Clarify",
          "ideate": this.ideate,
          "develop": "Develop",
          "implement": "Implement",
          "prefers_to": "prefers to",
          "get_report":  "Get your full report at",
          "sign_up_with": "Sign up with the email",
          "thinking_profile_heading": "FourSight<sup>&reg;</sup>&nbsp;Thinking Profile",
          "thinking_profile_message": "measures thinking preferences, where you gain and lose energy when you try to solve a complex challenge. There are no “good” or “bad” scores. Each of the 15 FourSight Profiles has its own strengths and blind spots. The goal is not to change your thinking preferences, but to understand how they affect your decision making, your perceptions, your stress levels and your interactions with others. Self awareness helps you solve challenges more effectively.",
          "organization": message["organization_name"],
          "clarifier_sum": message["clarifier_sum"],
          "developer_sum": message["developer_sum"],
          "ideator_sum": message["ideator_sum"],
          "implementer_sum": message["implementer_sum"],
          "low_contrast": message["low_contrast"],
          "high_contrast": message["high_contrast"],
          "generated_at": message["generated_at"],
          "profile_title": message?.profile_title,
          "profile_equation": message["profile_equation"],
          "profile_description": message["profile_description"],
        };    
        console.log(this.args)
    }

    public changeProfileName(){
        console.log(this.profiles);
        this.http.patch(`${environment.profileURL}/api/v1/profiles/${this.profileId}/update_info.json?first_name=${this.profiles.first_name}&last_name=${this.profiles.last_name}`,'').subscribe(res => {
            console.log(res);
            this.fieldEdit = 0;
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Your profile name has been updated successfully!', 'Nice!');
            }, 1000);
            this.storeNgrx.subscribe(message => { 
                if(message){
                            this.profiles = message;
                            this.args = {
                                "id": message["id"],
                                "content_type": "pdf",
                                "personalized_note": `Get personalized results at <a href="${this.env.lambda_pf_footer}/thinking-coach"> ${this.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${message["email"]}`,
                                "template": "profile.html",
                                "date_of_profile": this.date_of_profile,
                                "organization_title": this.organization_title,
                                "first_name": message["first_name"],
                                "last_name": message["last_name"],
                                "low_preference": this.low_preference,
                                "high_preference": this.high_preference,
                                "contrast": this.contrast,
                                "clarify": this.clarify,
                                "ideate": this.ideate,
                                "develop": this.develop,
                                "implement": this.implement,
                                "prefers_to": this.prefers_to,
                                "get_report": this.get_report,
                                "sign_up_with": this.sign_up_with,
                                "thinking_profile_heading": this.thinking_profile_heading,
                                "thinking_profile_message": this.thinking_profile_message,
                                "organization": message["organization_name"],
                                "clarifier_sum": message["clarifier_sum"],
                                "developer_sum": message["developer_sum"],
                                "ideator_sum": message["ideator_sum"],
                                "implementer_sum": message["implementer_sum"],
                                "low_contrast": message["low_contrast"],
                                "high_contrast": message["high_contrast"],
                                "generated_at": message["generated_at"],
                                "profile_title": message["profile_title"],
                                "profile_equation": message["profile_equation"],
                                "profile_description": message["profile_description"],
                            };
                }
            })
        },
        error => {
            console.log('oops', error)
            this.fieldEdit = 0;
            setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.error(error.error.errors, 'Oops!');
            }, 1000);
        })
    }

    // create profile pdf or png based on type
    public async lambdaPDF(format, title,type){
        this.lmda_service.lambda_res.next([])
        console.log( title, type);
        this.loader = true;
        // remove members from already using args
        let date = new Date();
        var argument = this.args;
        this.downloadingPdf = true;
        let pdfFilename = "foursight-profile-" + this.args.first_name + '-' + this.args.last_name + '-' + date.getFullYear().toString().substr(-2) + date.getMonth() + date.getDate();

        if(type == 'pdf'){
            this.args.content_type = "pdf";
            if(format == 'A4'){
                this.a4_format = 'Generating Profile PDF...';
                this.args.format = format;
            }else {
                this.letter_format = 'Generating Profile PDF...';
                this.args.format = format;
            }
           argument = {
                "template": "profile.html",
                "format": "A4",
                "organization": "Organization",
                "content_type": "pdf",
                "filename": pdfFilename,
                "members":{
                    "0": this.args,
                }
            }
        }else {
            this.image_content = 'Generating Profile PNG...';
            argument = {
                "template": "profile.html",
                "format": "A4",
                "organization": "Organization",
                "content_type": "png",
                "filename": pdfFilename,
                "members":{
                    "0": this.args,
                }
            }
            this.args.content_type = 'png';
        }
        this.args.template = 'profile.html';
        console.log(this.args);
        await this.lmda_service.lambda_invoke('genr-InvoiceFunction-sZf7W6rjtE0H',this.args);
        this.lmda_service.lambda_res.subscribe((val)=> {
            if(val) {
                this.gen_pdf = '';
                this.gen_pdf = val;
                console.log(this.gen_pdf);
                this.loader = false;
            }
        })
        // res.subscribe(output => {
        //     let newWin = window.open(
        //         output['response'].body,
        //         '_blank' // <- This is what makes it open in a new window.
        //     );
        // });
        this.letter_format = 'Download Profile PDF (US Letter)';
        this.a4_format = 'Download Profile PDF (International A4)';
        this.image_content = 'Download Profile PNG';
        argument = null;
        this.downloadingPdf = false;
                
    }

    public selectFormat(fileType,format) {
        this.gen_pdf = '';
        this.file_type = fileType;
        console.log('gen pdf is ....',this.gen_pdf)
        this.selected_format = format;
    }

    public getGuides(content){
        this.guide = '';
        this.http.get(`${environment.assessment_admin}api/v1/interpretive_guides`).subscribe(data => {
            console.log(data);
            // Sort by Language name
            this.guides = data['interpretive_guides'].sort(function(a, b){
                return a.language_name.localeCompare(b.language_name);
            });
            this.modal1 = this.modalService.open(content, { windowClass: 'in grey' });
        },
            error => console.log('oops', error)
        )
    }

    public changeGuide(){
        console.log(this.guides);
        for(var i=0; i<this.guides.length; i++){
            if(this.guides[i].language_code == this.guide){
                this.short_guide = this.guides[i].short_guide;
                this.long_guide = this.guides[i].long_guide;
            }
        }
    }

    // scrolling quick guide
    public quickGuide() {
        setTimeout(function() {
            $('html,body').animate({
                scrollTop: $('.fn-profile.section-padding').offset().top},
            'slow');
        }, 100);
    }

    // downloading profile pdf based on selected radio btn
    public profile_downloader() {
        console.log(this.selected_format);
        if(this.selected_format == 'A4') {
            this.lambdaPDF('A4', 'Download Profile PDF (International A4)','pdf');
        } else if(this.selected_format == 'letter') {
            this.lambdaPDF('Letter', 'Download Profile PDF (US Letter)','pdf')
        } else {
            this.lambdaPDF('Letter', 'Download Profile PNG','png');
        }
    }

    // change profile pdf language based on selected dropdown value
    public change_language(lang_update: boolean = true) {
        var that = this;
        this.gen_pdf = '';
        this.pfDesAnyLang(this.selected_lang);
        console.log(this.selected_lang);
        console.log(this.profiles);
        if(lang_update) {
            this.service.pf_selected_language.next(this.selected_lang)
        }
        // this.storeNgrx.subscribe(message => {
        //     this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+ this.selected_lang,{params: {groups: 'Thinking Profile'}}).subscribe(data => {
        //         console.log(data)
        //         if(Object.keys(data).length > 0) {
        //             updatedPfData(data)
        //         } else {
        //             this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+message?.language?.short_name).subscribe(data => {
        //                 updatedPfData(data)
        //             });
        //         }
        //         function updatedPfData(data) {
        //             that.pf_email_hd = data["profile_email"];
        //             that.date_of_profile = data["date_of_profile"];
        //             that.organization_title = data['organization_title'];
        //             that.thinking_profile_heading = data['thinking_profile_heading'];
        //             that.thinking_profile_message = data['thinking_profile_message'];
        //             that.download = data['download'];
        //             that.share_my_profile = data['share_my_profile'];
        //             that.explain_my_profile = data['explain_my_profile'];
        //             that.low_preference = data['low_preference'];
        //             that.high_preference = data['high_preference'];
        //             that.contrast = data['contrast'];
        //             that.clarify = data['clarify'];
        //             that.ideate = data['ideate'];
        //             that.develop = data['develop'];
        //             that.implement = data['implement'];
        //             that.prefers_to = data['prefers_to'];
        //             that.get_report = data['get_report'];
        //             that.sign_up_with = data['sign_up_with'];
        //             that.download_txt = data['download'];
        //         }
        //     })
        // });
    }

    // get profile profile description for other languages
    public pfDesAnyLang(lang_code) {
         // fetch collaboration data
        console.log(lang_code)
        this.http.get(`${environment.profileURL}/api/v1/profile_types.json`,{params:{profile:"custom",language:lang_code}}).subscribe(res => {
            console.warn(res["profile_types"]);
            for(let i = 0;i< res["profile_types"].length; i++) {
                if(this.profiles?.profile_title === res["profile_types"][i]["type"]) {
                    console.log(res["profile_types"][i]["individual_description"]);
                    this.pf_description = res["profile_types"][i]["individual_description"];
                    this.pf_eqn = res["profile_types"][i]["profile_equation"];
                    this.pf_title = res["profile_types"][i]["profile_title"];
                }
            }
        })
        this.changePdflang()
    }

    // change pdf language in selected lang
    public changePdflang() {
        var pf_desc;
        var pf_eqn;
        var pf_title;
        this.http.get(`${environment.profileURL}/api/v1/profile_types.json`,{params:{profile:"custom",language:this.selected_lang}}).subscribe(res => {
            console.warn(res["profile_types"]);
            for(let i = 0;i< res["profile_types"].length; i++) {
                if(this.profiles?.profile_title === res["profile_types"][i]["type"]) {
                    console.log(res["profile_types"][i]["individual_description"]);
                    pf_desc = res["profile_types"][i]["individual_description"];
                    pf_eqn = res["profile_types"][i]["profile_equation"];
                    pf_title = res["profile_types"][i]["profile_title"];
                }
            }
        })
        // this.storeNgrx.subscribe(message => {
        //     var that = this;
        //     this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+ this.selected_lang,{params: {groups: 'Thinking Profile'}}).subscribe(data => {
        //         console.log(data)
        //         if(Object.keys(data).length > 0) {
        //             updatedPfData(data)
        //         } else {
        //             this.http.get(`${environment.profileURL}/api/v1/translations.json?language=`+message?.language?.short_name).subscribe(data => {
        //                 updatedPfData(data)
        //             });
        //         }
        //         function updatedPfData(data) {
        //             console.log(data);
        //             console.log(data["download"]);
        //             that.download_txt = data["download"];
        //         that.args = {
        //             "date_title": data?.date_of_profile,
        //             "email_title" : data?.profile_email,
        //             "id": message["id"],
        //             "content_type": "pdf",
        //             "personalized_note": `Get personalized results at <a href="${that.env.lambda_pf_footer}/thinking-coach"> ${that.env.lambda_pf_footer}/thinking-coach</a> by signing up with the email ${message["email"]}`,
        //             "template": "profile.html",
        //             "email": message["email"],
        //             "date_of_profile": data['date_of_profile'],
        //             "organization_title": data['organization_title'],
        //             "first_name": message["first_name"],
        //             "last_name": message["last_name"],
        //             "low_preference": data['low_preference'],
        //             "high_preference": data['high_preference'],
        //             "contrast": data['contrast'],
        //             "clarify": data['clarify'],
        //             "ideate": data['ideate'],
        //             "develop": data['develop'],
        //             "implement": data['implement'],
        //             "prefers_to": data['prefers_to'],
        //             "get_report": data['get_report'],
        //             "sign_up_with": data['sign_up_with'],
        //             "thinking_profile_heading": data['thinking_profile_heading'],
        //             "thinking_profile_message": data['thinking_profile_message'],
        //             "organization": message["organization_name"],
        //             "clarifier_sum": message["clarifier_sum"],
        //             "developer_sum": message["developer_sum"],
        //             "ideator_sum": message["ideator_sum"],
        //             "implementer_sum": message["implementer_sum"],
        //             "low_contrast": message["low_contrast"],
        //             "high_contrast": message["high_contrast"],
        //             "generated_at": message["generated_at"],
        //             "profile_title": pf_title,
        //             "profile_equation": pf_eqn,
        //             "profile_description": pf_desc,
        //         };
        //         }
                
        //     })
        // });
    }
}
