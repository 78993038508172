import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment } from './../../../../../environments/environment';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-public-store-products',
  templateUrl: './public-store-products.component.html',
  styleUrls: ['./public-store-products.component.scss']
})
export class PublicStoreProductsComponent implements OnInit {

	public mcart:any;
	public tcart:any;
	public traincart:any;
	public bcart:any;
	details:any;
	Cart:any;
	filter:string;
	is_buyer:boolean;
	env:any;
	mindset_quant:any;
	toolset_quant:any;
	books_quant:any;
	training_quant:any;
	Quant:any;
	cartId:any;
	system_notifications:any = [];
	lang_assessment:any = 'en';
	@Output() cartChange = new EventEmitter<any>();
	showBook:boolean = false;
	product_order = [];
	product_head = [];
	sortedProducts = [];
	products = []
	books = []
	training = []
	toolset = [];
	error;
	heroProdBtn:boolean = false;
	heroProdQty:number = 5;
	heroProdDetails:any;
	categoriesProduct;

  	constructor(public location: Location, public http:HttpClient, private activatedRoute: ActivatedRoute, private router: Router) { 

  	}
	
	ngOnDestroy(){
		document.title = "App | FourSight";
	}

  	async ngOnInit() {
		
		document.title = "FourSight Store";

  		this.mindset_quant = [];
  		this.toolset_quant = [];
  		this.books_quant = [];
  		this.training_quant = [];
  		this.mcart = [];
  		this.tcart = [];
  		this.traincart = [];
  		this.bcart = [];
  		this.filter = '';
  		this.env = environment;
  		if(localStorage.getItem('cart_id')){
  			this.cartId = localStorage.getItem('cart_id');
  		}

		this.http.get(`${this.env.assessment_admin}api/v1/public/categories.json`).subscribe((data:any) => {
			console.warn("categories",data)
			let categoriesHavingShow = data?.categories?.filter(category => {
				if(category.name.includes('show-public-Books')) {
					let num = category.name?.replace(/\D/g,'');
					category.position = num
				}
				return category.name.includes('show')
			});
			console.log("categoriesHavingShow", categoriesHavingShow)
			let sortedCategories = categoriesHavingShow.sort((a, b) => a.position - b.position);
			console.log("sortedCategories", sortedCategories)
			let onlyPublicStoreCategories = sortedCategories.filter(category => category.name.includes('-public-'));
			let sortedCatWithSortedProducts = onlyPublicStoreCategories.map(category => {
				let num = category.name?.replace(/\D/g,'');
				category.products.forEach((product,i) => {
					if(category?.name?.includes('show-public-Training Material')) { 
						this.mindset_quant.push(1);
					} else if (category?.name?.includes('show-public-Books')) {
						this.books_quant.push(1);
					} else if (category?.name?.includes('show-public-Certifications')) {
						this.toolset_quant.push(1);
					}
				})
				if(this.mindset_quant.length < this.books_quant.length) {
					this.mindset_quant = this.books_quant;
				}
				console.warn(this.mindset_quant, this.books_quant, this.toolset_quant)
				// category.name[this.product_order.indexOf(num)] = category.name?.replace(num + '-show-','')
				return {
					name: category.name?.replace(num + '-show-public-',''),
					products: category.products.sort((prodA, prodB) => prodA.position - prodB.position)
				}
			})
			console.log("sortedCatWithSortedProducts", sortedCatWithSortedProducts)
			this.categoriesProduct = sortedCatWithSortedProducts;
		});

		// hero product input tag prevent from typing 
		// document.getElementById('heroProdQty').addEventListener('keydown', function(event) {
		// 	// Prevent typing (allowing only arrow key usage)
		// 	if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
		// 	  event.preventDefault();
		// 	}
		// });

  		await this.http.get(`${this.env.assessment_admin}api/v1/public/products.json`).subscribe(data => {
            this.details = data['products'];
			this.heroProdDetails = data['products'].filter(product => product.slug == 'foursight-online-subscription');
			// console.warn(this.heroProdDetails)
			// var products = [];
			// let temporder;
			// let headings;
			// data['products'].forEach(product => {
			// 	product['categories'].forEach((item,i) => {
			// 		temporder = '';
			// 		temporder = item.name.replace(/\D/g,'');
			// 		console.warn(temporder, item.name)
			// 		headings = item.name;
			// 	})
			// 	console.log(headings)
			// 	if(!this.product_head.includes(headings)) {
			// 		this.product_head.push(headings)
			// 	}
			// 	// temporder = parseInt(temporder)
			// 	if(!this.product_order.includes(temporder)){
			// 		this.product_order.push(temporder);
			// 	}
			// })

			// //sort order
			// console.log("berfore sort",this.product_order)
			// this.product_order.sort(function(a, b) {
			// 	return a - b;
			// });
			// let myHeading = []
			// this.product_head.forEach((heading,ind)=> {
			// 	console.log(heading)
			// 	let num = heading?.replace(/\D/g,'');
			// 	myHeading[this.product_order.indexOf(num)] = heading?.replace(num + '-show-','')
			// })
			// console.warn(myHeading)
			// this.product_head = myHeading;
			// console.warn(this.product_order)
			// console.warn(this.product_head)
			// data['products'].forEach(product => {
			// 	product['categories'].forEach((item,i) => {

			// 		this.product_order.forEach((order,oi)=> {
			// 			if(this.sortedProducts[oi]== undefined || this.sortedProducts[oi] == null) {
			// 				this.sortedProducts[oi] = [];
			// 			}
			// 			if(item?.name?.includes(order)) {
			// 				this.sortedProducts[oi].push(product)
			// 				this.mindset_quant.push(1);
			// 			}
			// 		})

			// 		if(item?.name?.includes('show-Products')) {
			// 			this.products.push(product)
			// 			this.mcart[i] = 0;
		    //         	this.mindset_quant.push(1);
			// 		} else if (item?.name?.includes('show-Books') || item?.name.includes('hide-Books')) {
			// 			this.bcart[i] = 0;
		    //         	this.books_quant.push(1);
			// 			if(item.name.includes('show-Books')) {
			// 				this.showBook = true;
			// 			}
			// 			this.books.push(product)
			// 		} else if (item?.name?.includes('show-Training')) {
			// 			this.training.push(product)
			// 			this.traincart[i] = 0;
		    //         	this.training_quant.push(1);
			// 		} else if (item?.name?.includes('Toolset')) {
			// 			this.toolset.push(product)
			// 			this.tcart[i] = 0;
		    //         	this.toolset_quant.push(1);
			// 		}
			// 	})
			// })
			// console.log("before position",this.sortedProducts)
			// this.sortedProducts.forEach((prodArr, prodIndx) => {
			// 	prodArr.sort((a, b) => {
			// 		return a.position - b.position;
			// 	})
			// })
			// console.log("after position",this.sortedProducts)
            // for(var i=0; i<this.details.length;i++){
            // 	if(this.details[i].categories[0]){
            // 		for(var j=0; j<this.details[i].categories.length;j++){
		    //         	// if(this.details[i].categories[j].name.includes('show-Products')){
		    //         	// 	this.mcart[i] = 0;
		    //         	// 	this.mindset_quant[i] = 1;
		    //         	// }
		    //         	// if(this.details[i].categories[j].name.includes('Toolset')){
		    //         	// 	this.tcart[i] = 0;
		    //         	// 	this.toolset_quant[i] = 2;
		    //         	// }
		    //         	// if(this.details[i].categories[j].name.includes('Books')){
		    //         	// 	this.bcart[i] = 0;
		    //         	// 	this.books_quant[i] = 3;
		    //         	// }
		    //         	products.push({
		    //         		product_id: this.details[i].variants[0].product_id,
			// 				sku: this.details[i].variants[0].sku,
			// 				category: this.details[i]['categories'][0].name,
			// 				name: this.details[i].variants[0].name,
			// 				price: this.details[i].variants[0].price,
			// 				image_url: this.details[i].variants[0].image_url
		    //         	})
		    //         }
	        //     }
            // }
            // this.angulartics2Segment.eventTrack('Product List Viewed', 
            //     { 
            //         products:products
            //     }
            // );
        },
            error => {
				if(error.status == 503){
					this.error = error;
				}
            	console.log('oops', error);
            	if(error.status == 500){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'Sorry!');
					}, 1000);
				}
				else if(error.status == 422){
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 4000
						};
						if(!error.error.errors.base[0]){
                            this.toastr.error(error.error.errors, 'oops!');
                        }else{
                            this.toastr.error(error.error.errors.base[0], 'oops!');
                        }
					}, 1000);
				}else{
					setTimeout(function() {
						this.toastr.options = {
						  closeButton: true,
						  timeOut: 2000
						};
						this.toastr.error('Something went wrong!', 'oops!');
					}, 1000);
				}
			}
        );

        this.http.get(`${this.env.assessment_admin}api/v1/public/system_settings.json`).subscribe(data => {
        	this.system_notifications = data;
        	console.log(this.system_notifications[0]);
        },
            error => {
				if(error.status == 503){
					this.error = error;
				}
                console.log('oops', error);
                if(error.status == 500){
                    setTimeout(function() {
                        this.toastr.options = {
                          closeButton: true,
                          timeOut: 2000
                        };
                        this.toastr.error('Something went wrong!', 'Sorry!');
                    }, 1000);
                }
                else if(error.status == 422){
                    setTimeout(function() {
                        this.toastr.options = {
                          closeButton: true,
                          timeOut: 4000
                        };
                        if(!error.error.errors.base[0]){
                            this.toastr.error(error.error.errors, 'oops!');
                        }else{
                            this.toastr.error(error.error.errors.base[0], 'oops!');
                        }
                    }, 1000);
                }else{
                    setTimeout(function() {
                        this.toastr.options = {
                          closeButton: true,
                          timeOut: 2000
                        };
                        this.toastr.error('Something went wrong!', 'oops!');
                    }, 1000);
                }
            }
        );
		console.log(this.error)
	}
	
	// add input element and add to cart button for hero product
	addInputButon() {
		var that = this;
		setTimeout(() => {
			let heroProdDescp = document.querySelector('.prod-dest') as HTMLElement;
			console.log("heroproddescp", heroProdDescp);
			// let pElement = document.createElement("p");
			// pElement.innerHTML = "Number of users";	
			// heroProdDescp.appendChild(pElement);
			let inputElement = document.createElement("input");
			inputElement.type = "number"; 
			inputElement.id = "heroProdQty";
			inputElement.className = "f-col-5";
			inputElement.value = "5";
			inputElement.min = "5";
			inputElement.step = "5";
			inputElement.max = "100";
			heroProdDescp.appendChild(inputElement);       
			let buttonElement = document.createElement("button");
			buttonElement.id = "hero-prod-cart";
			buttonElement.className = "f-btn f-btn--sm f-font-secondary f-col-5 f-m-top-10";
			buttonElement.innerHTML = "Add to cart";
			heroProdDescp.appendChild(buttonElement);
			buttonElement?.addEventListener("click", function() {
				if(!that.heroProdBtn) {
					console.log("buttonElement", buttonElement);
					console.log("inputElement", inputElement);
					let inputValue = parseInt(inputElement.value);
					if(inputValue > 0) {
						console.log("inputValue", inputValue);
						that.heroProdQty = inputValue;
						that.addToCart(that.heroProdDetails[0]?.variants[0]?.id)
						that.heroProdBtn = true;
						buttonElement.innerHTML = "View Cart";
					}
				} else {
					that.router.navigate(['/store/cart']);
				}
			})
		},100)
	}

	public cart = function(i, id, category){
		if(this.cartId){
			if((category == 'Mindset' && typeof(this.mindset_quant[i]) == 'number') || (category == 'Toolset' && typeof(this.toolset_quant[i]) == 'number') || (category == 'Books' && typeof(this.books_quant[i]) == 'number')){
				if(category == 'Mindset'){
		    		this.Quant = this.mindset_quant[i];
		        }
		        if(category == 'Toolset'){
		    		this.Quant = this.toolset_quant[i];
		        }
		        if(category == 'Books'){
		    		this.Quant = this.books_quant[i];
		        }
		  		var Data = {
		  			variant_id: parseInt(id),
		  			quantity: this.Quant ? this.Quant : 1
		  		}
		  		this.http.post(`${this.env.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {            
		            this.Cart = data['order']['line_items'];
		            this.cartChange.emit({data :data['order']['line_items']});
		            console.log(this.cartChange);
		            setTimeout(function() {
			            this.toastr.options = {
			                closeButton: true,
			                timeOut: 2000
			            };
			            this.toastr.success('Product added to the Cart!', 'Nice!');
			        }, 1000);
			        // this.angulartics2Segment.eventTrack('Page', 
		            //     { 
		            //         funnel: 'Store',
		            //         product_id: id,
		            //         cta_color:'developer-green',
			        //         cta_text:'Add Item',
			        //         cta_type:'button',
			        //         cta_location:'https://app.foursightonline.com/store',
		            //         url: window.location.origin + this.location.path()
		            //     }
		            // );
		            // this.angulartics2Segment.eventTrack('Product Added', 
		            //     { 
		            //     	cart_id: this.cartId,
		            //         product_id: id,
					// 		quantity: this.Quant,
					// 		category: category
		            //     }
		            // );
		            if(category == 'Mindset'){
		            	this.mcart[i] = parseInt(id);
		            }
		            if(category == 'Toolset'){
		            	this.tcart[i] = parseInt(id);
		            }
		            if(category == 'Books'){
		            	this.bcart[i] = parseInt(id);
		            }
		        },
		            error => {
		            	console.log('oops', error);
		            	if(error.status == 500){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								this.toastr.error('Something went wrong!', 'Sorry!');
							}, 1000);
						}
						else if(error.status == 422){
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 4000
								};
								if(!error.error.errors.base[0]){
	                                this.toastr.error(error.error.errors, 'oops!');
	                            }else{
	                                this.toastr.error(error.error.errors.base[0], 'oops!');
	                            }
							}, 1000);
						}else{
							setTimeout(function() {
								this.toastr.options = {
								  closeButton: true,
								  timeOut: 2000
								};
								this.toastr.error('Something went wrong!', 'oops!');
							}, 1000);
						}
					}
		        );
		  	}
		}else{
			this.http.post(`${this.env.assessment_admin}api/v1/public/carts.json`).subscribe(data => {
	            this.cartId = data['order']['id'];
	            localStorage.setItem('cart_id',data['order']['id']);
	            this.cart(i, id, category);
	            console.log(this.mindset_quant);
	        },
	            error => {
	            	console.log('oops', error);
	            	if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
	                            this.toastr.error(error.error.errors, 'oops!');
	                        }else{
	                            this.toastr.error(error.error.errors.base[0], 'oops!');
	                        }
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
	        );

		}

  	}
	// add hero products in cart
	addToCart(id) {
		console.log('id',id);
		var Data = {
			variant_id: id,
			quantity: this.heroProdQty
		}
		if(!this.cartId){
			this.http.post(`${this.env.assessment_admin}api/v1/public/carts.json`,'').subscribe(data => {
				this.cartId = data['order']['id'];
				this.addToCart(id)
				localStorage.setItem('cart_id',data['order']['id']);
			},
				error => {
					console.log('oops', error);
					if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
								this.toastr.error(error.error.errors, 'oops!');
							}else{
								this.toastr.error(error.error.errors.base[0], 'oops!');
							}
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
			);
		} else {
			this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {
				console.log(data)
				this.heroProdBtn = true;
			})
		}
	}

  	public filterPdts(){
  		if(this.filter == 'popularity'){
  			this.details.sort(function(a, b) {
			    return parseFloat(b.popularity) - parseFloat(a.popularity);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'rating'){
  			this.details.sort(function(a, b) {
			    return parseFloat(b.rating) - parseFloat(a.rating);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'price'){
  			this.details.sort(function(a, b) {
  				var first = a.price_range;
  				var second = b.price_range;
  				if(a.price_range.indexOf("-") > 0){first = a.price_range.substring( 0, a.price_range.indexOf("-"));}
  				if(b.price_range.indexOf("-") > 0){second = b.price_range.substring( 0, b.price_range.indexOf("-"));}
  				if(a.price_range.indexOf(",") > 0){first = a.price_range.replace (/\,/g,"");}
  				if(b.price_range.indexOf(",") > 0){second = b.price_range.replace (/\,/g,"");}
			    return parseFloat(first) - parseFloat(second);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'price-desc'){
  			this.details.sort(function(a, b) {
  				var first = a.price_range;
  				var second = b.price_range;
  				if(a.price_range.indexOf("-") > 0){first = a.price_range.substring( a.price_range.indexOf("-")+1, a.price_range.length);}
  				if(b.price_range.indexOf("-") > 0){second = b.price_range.substring( b.price_range.indexOf("-")+1, b.price_range.length);}
  				if(a.price_range.indexOf(",") > 0){first = a.price_range.replace (/\,/g,"");}
  				if(b.price_range.indexOf(",") > 0){second = b.price_range.replace (/\,/g,"");}
			    return parseFloat(second) - parseFloat(first);
			});
			this.defaultQuantity();
  		}
  		if(this.filter == 'date'){
  			this.details.sort(function(a, b) {
			    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
			});
			this.defaultQuantity();
  		}
  		if(this.filter == ''){
  			this.details.sort(function(a, b) {
			    return parseFloat(a.position) - parseFloat(b.position);
			});
			this.defaultQuantity();
  		}

  	}

  	public defaultQuantity(){
  		for(var i=0; i<this.details.length;i++){
        	if(this.details[i].categories[0]){
        		for(var j=0; j<this.details[i].categories.length;j++){
	            	if(this.details[i].categories[j].name == 'Mindset'){
	            		this.mcart[i] = 0;
	            		this.mindset_quant[i] = 1;
	            	}
	            	if(this.details[i].categories[j].name == 'Toolset'){
	            		this.tcart[i] = 0;
	            		this.toolset_quant[i] = 1;
	            	}
	            	if(this.details[i].categories[j].name == 'Books'){
	            		this.bcart[i] = 0;
	            		this.books_quant[i] = 1;
	            	}
	            }
            }
        }
  	}

  	public groupFunnel(){
  		// this.angulartics2Segment.eventTrack('Page', 
        //     { 
        //         funnel: 'Group Assessment',
        //         cta_color:'developer-green',
        //         cta_text:'Order for a group',
        //         cta_type:'button',
        //         cta_location:'https://app.foursightonline.com/store',
        //         url: window.location.origin + this.location.path()
        //     }
        // );
  	}

  	public soloFunnel(){
  		// this.angulartics2Segment.eventTrack('Page', 
        //     { 
        //         funnel: 'Solo Assessment',
        //         cta_color:'developer-green',
        //         cta_text:'Take it myself',
        //         cta_type:'button',
        //         cta_location:'https://app.foursightonline.com/store',
        //         url: window.location.origin + this.location.path()
        //     }
        // );
  	}

	public setHeroProdQty(){
		if(this.heroProdQty < 5) {
			this.heroProdQty = 5;
		} else if(this.heroProdQty > 100) {
			this.heroProdQty = 100;
		}
	}

}
