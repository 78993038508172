import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { environment } from './../../../../../environments/environment';
// import { Angulartics2Segment } from 'angulartics2';
import { Location } from '@angular/common';

declare var jQuery:any;

@Component({
  selector: 'app-public-store-cart',
  templateUrl: './public-store-cart.component.html',
  styleUrls: ['./public-store-cart.component.scss']
})
export class PublicStoreCartComponent implements OnInit {

	public cart:any;
	public discounted_items_price:any;
	public cartId:any;
	listMembers:any;
	@Output() cartChange = new EventEmitter<any>();
	havingQueryParams:any;
	variants;

  	constructor(public location: Location, public http:HttpClient, public router: Router, public activatedRoute: ActivatedRoute) { }

  	ngOnInit() {
		this.activatedRoute.queryParams.subscribe((prm:any)=> {
			if(Object.keys(prm).length !== 0) {
				this.havingQueryParams = prm;
			}
			console.log(prm);
		})
		if(this.havingQueryParams){
			if(this.havingQueryParams?.type && this.havingQueryParams.language && this.havingQueryParams.guide) {
				this.createCartId()
			} else {
				this.addToCart(this.havingQueryParams.id)
			}
		}else {
			this.cartDetails()
		}
  	}

	createCartId(){
		if(!this.cartId) {
			this.http.post(`${environment.assessment_admin}api/v1/public/carts.json`,'').subscribe(data => {
				this.cartId = data['order']['id'];
				this.getAssessmentDetails()
				localStorage.setItem('cart_id',data['order']['id']);
			},
				error => {
					console.log('oops', error);
					if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
								this.toastr.error(error.error.errors, 'oops!');
							}else{
								this.toastr.error(error.error.errors.base[0], 'oops!');
							}
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
			);
		}
	}

	addAssessment(){
		let language = this.havingQueryParams.language;
		let assessment = this.havingQueryParams.type;
		let guide = this.havingQueryParams.guide;
		console.log(language, assessment, guide, this.cartId)
		if(language && assessment && guide){
			for(var j=0; j<this.variants.length; j++){
				if(assessment == this.variants[j].mode && guide == this.variants[j].interpretive_guide_mode && language == this.variants[j].language.name){
				  var id = this.variants[j].id;
				  console.log("id",id)
					var Data = {
					  variant_id: parseInt(id),
					  quantity: this.havingQueryParams.qty
				  }
					this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {
						this.cart = data['order']['line_items'];
					  	this.cartChange.emit({data :data['order']['line_items']});
				  },
					  error => {
						  console.log('oops', error);
						  if(error.status == 500){
							  setTimeout(function() {
								  this.toastr.options = {
									closeButton: true,
									timeOut: 2000
								  };
								  this.toastr.error('Something went wrong!', 'Sorry!');
							  }, 1000);
						  }
						  else if(error.status == 422){
							  setTimeout(function() {
								  this.toastr.options = {
									closeButton: true,
									timeOut: 4000
								  };
								  if(!error.error.errors.base[0]){
									  this.toastr.error(error.error.errors, 'oops!');
								  }else{
									  this.toastr.error(error.error.errors.base[0], 'oops!');
								  }
							  }, 1000);
						  }else{
							  setTimeout(function() {
								  this.toastr.options = {
									closeButton: true,
									timeOut: 2000
								  };
								  this.toastr.error('Something went wrong!', 'oops!');
							  }, 1000);
						  }
					  }
				  );
				}
			}
		}
	}

	async getAssessmentDetails() {
		this.createCartId()
		await this.http.get(`${environment.assessment_admin}api/v1/public/products/foursight-thinking-profile-assessment.json`).subscribe(async (data) => {
			console.log('variant', data['product']['variants'])
			this.variants = data['product']['variants'];
			await this.addAssessment()
		})
	}


	cartDetails() {
		if(localStorage.getItem('cart_id')){
			this.cartId = localStorage.getItem('cart_id');
			this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId).subscribe(data => {
			  console.log(data);
			  this.cart = data['order']['line_items'];
			  this.cartChange.emit({data :data['order']['line_items']});
			  this.discounted_items_price = data['order']['discounted_items_price'];
			  var products = [];
			  for(var i= 0; i< this.cart.length;i++){
				  products.push({
					  product_id: this.cart[i].variant.product_id,
					  sku: this.cart[i].variant.sku,
					  name: this.cart[i].variant.name,
					  price: this.cart[i].variant.price,
					  image_url: this.cart[i].variant.image_url
				  })
			  }
			  // this.angulartics2Segment.eventTrack('Cart Viewed', 
			  //     { 
			  //     	cart_id: this.cartId,
			  //         products: products
			  //     }
			  // );
		  },
			  error => {
				  this.showError(error);
			  }
		  )
		}else{
			this.cart = [];
		}
	}



	  addToCart(id) {
		console.log('id',id);
		var Data = {
			variant_id: id,
			quantity: this.havingQueryParams.qty
		}
		if(!this.cartId){
			this.http.post(`${environment.assessment_admin}api/v1/public/carts.json`,'').subscribe(data => {
				this.cartId = data['order']['id'];
				this.addToCart(id)
				localStorage.setItem('cart_id',data['order']['id']);
			},
				error => {
					console.log('oops', error);
					if(error.status == 500){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'Sorry!');
						}, 1000);
					}
					else if(error.status == 422){
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 4000
							};
							if(!error.error.errors.base[0]){
								this.toastr.error(error.error.errors, 'oops!');
							}else{
								this.toastr.error(error.error.errors.base[0], 'oops!');
							}
						}, 1000);
					}else{
						setTimeout(function() {
							this.toastr.options = {
							  closeButton: true,
							  timeOut: 2000
							};
							this.toastr.error('Something went wrong!', 'oops!');
						}, 1000);
					}
				}
			);
		} else {
			this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/add_item`,Data).subscribe(data => {
				console.log(data)
				this.cartDetails()
				// this.heroProdBtn = true;
			})
		}
	}

  	public remove(id){
		this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/remove_item?variant_id=${id}`).subscribe(data => {
            console.log(data);
            this.cart = data['order']['line_items'];
            this.cartChange.emit({data :data['order']['line_items']});
			this.discounted_items_price = data['order']['discounted_items_price'];
			// this.angulartics2Segment.eventTrack('Product Removed', 
            //     { 
            //     	cart_id: this.cartId,
            //         product_id: id
            //     }
            // );
        },
            error => {
            	this.showError(error);
			}
    	)
	}

	public updatePrice(nmbr){
		if(jQuery('#'+nmbr).val() != ''){
			var counter = 0;
	    	this.listMembers = [];
	    	console.log($('#'+nmbr).val());
			this.cart.forEach(list =>{
				if(nmbr == list.variant.id){
					if(list.organization_id != null){
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(jQuery('#'+nmbr).val()), 'organization_id': parseInt(list.organization_id) };
						counter++;
					}else{
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(jQuery('#'+nmbr).val()) };
						counter++;
					}

				}else{
					if(list.organization_id != null){
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(list.quantity), 'organization_id': parseInt(list.organization_id) };
						counter++; 
					}else{
						this.listMembers[counter] = { 'variant_id': parseInt(list.variant.id), 'quantity': parseInt(list.quantity) };
						counter++;
					}
				}   		  			
			});
			this.http.post(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/update_items`,{"line_items":this.listMembers}).subscribe(data => {
	            console.log(data);
	            this.cart = data['order']['line_items'];
	            this.cartChange.emit({data :data['order']['line_items']});
				this.discounted_items_price = data['order']['discounted_items_price'];
	        },
	            error => {
	            	this.showError(error);
				}
	    	)
		}
	}

	public emptyCart(){
		this.http.get(`${environment.assessment_admin}api/v1/public/carts/`+this.cartId+`/empty`).subscribe(data => {
            console.log(data);
            this.cart = data['order']['line_items'];
            this.cartChange.emit({data :data['order']['line_items']});
			this.discounted_items_price = data['order']['discounted_items_price'];
        },
            error => {
            	this.showError(error);
			}
    	)
	}

	public showError(error){
    	console.log('oops', error);
    	if(error.status == 500){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('Something went wrong!', 'Sorry!');
			}, 1000);
		}
		else if(error.status == 422){
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 4000
				};
				if(error.error.errors.number[0]){
					this.toastr.error(error.error.errors.number[0], 'oops!');
				}
				if(error.error.errors.base[0]){
					this.toastr.error(error.error.errors.base[0], 'oops!');
				}
				if(!error.error.errors.base[0] && !error.error.errors.number[0]){
					this.toastr.error(error.error.errors, 'oops!');
				}
			}, 1000);
		}
		else{
			setTimeout(function() {
				this.toastr.options = {
				  closeButton: true,
				  timeOut: 2000
				};
				this.toastr.error('Something went wrong!', 'oops!');
			}, 1000);
		}

  	}

}
