import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { BehaviorSubject, Subject, throwError } from "rxjs";
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { fsProfileModel } from '../store/model/fs-profile.model';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {
	public mail:any;
    default_profile: any;
    pf_selected_language = new BehaviorSubject<string>('en');

  	constructor(public http: HttpClient) {}

    // ngrx store profile function
    getFsProfile(): Observable<Array<fsProfileModel>> {
    return this.http
        .get<{ profile: fsProfileModel[]}>(
            `${environment.profileURL}/api/v1/profiles.json`
        )
        .pipe(map((pf) => pf['profiles'] || []));
    }

    // To get default profile
    getProfile(forRedux:boolean = false) {
        console.warn('======>fsdghjvmb,n.bv',forRedux)
        if(forRedux) {

            return this.http.get(`${environment.profileURL}/api/v1/users/account`).pipe(map(data => {
                if(data["default_profile"].length == 0){
                    return 0;
                }else{            
                    var nmbr = -1;
                    // for(var i=0;i<data["default_profile"].length;i++){
                    //     if(data["default_profile"][i].default==true){
                    //         nmbr=i;
                    //     }
                    // }
    
                    // if(nmbr!=-1){
                        let Id=data['member']['id'];
                        localStorage.setItem('member_id',Id);
                        localStorage.setItem('team_id',data['default_profile']['team_id']);
                        localStorage.setItem('organization_id',data['default_profile']['organization_id']);
                        localStorage.setItem('first_name',data['default_profile']['first_name']);
                        localStorage.setItem('last_name',data['default_profile']['last_name']);
                        this.default_profile = data['default_profile'];
                        return data;
                    // }
                      
                }
            }),
            )
        } else {
            return this.http.get(`${environment.profileURL}/api/v1/users/account`).pipe(map(data => {
                if(data["default_profile"].length == 0){
                    return 0;
                }else{            
                    var nmbr = -1;
                    for(var i=0;i<data["default_profile"].length;i++){
                        if(data["default_profile"][i].default==true){
                            nmbr=i;
                        }
                    }
    
                    if(nmbr!=-1){
                        let Id=data['member']['id'];
                        localStorage.setItem('member_id',Id);
                        localStorage.setItem('team_id',data['default_profile']['team_id']);
                        localStorage.setItem('organization_id',data['default_profile']['organization_id']);
                        localStorage.setItem('first_name',data['default_profile']['first_name']);
                        localStorage.setItem('last_name',data['default_profile']['last_name']);
                        this.default_profile = data['default_profile'];
                        return data["default_profile"];
                    }
                }
            }),
            )
        }
        // catchError(this.handleError)
  	}

    profile(){
        return this.default_profile;
    }

    // To get all profiles of user
    Profile()  {
        return this.http.get(`${environment.profileURL}/api/v1/profiles.json`).pipe(map(data => {
            return (data['profiles']);
        }),
        catchError(this.handleError)
        )
    }

    members()  {
        let team_id=localStorage.getItem('team_id');
        let organization_id=localStorage.getItem('organization_id');
        let first_name=localStorage.getItem('first_name');
        let last_name=localStorage.getItem('last_name');
        return this.http.get(`${environment.profileURL}/api/v1/users/members.json`).pipe(map(data => {
            return (data);
        }),
        catchError(this.handleError)
        )
    }
    private handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        return throwError(errorMessage);
    }

    // return user name from usrObj object
    userName(usrObj, name) {
        if(usrObj?.[name]) {
            return usrObj?.[name];
        // } else if(usrObj?.default_profile?.[name]) {
        //     return usrObj?.default_profile?.[name];
        } else {
            return "No Name";
        }
    }
}
