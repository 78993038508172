import { Component } from '@angular/core';
import { AccessGroupService } from '../../access-group.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pr-additional-resources',
  templateUrl: './pr-additional-resources.component.html',
  styleUrls: ['./pr-additional-resources.component.scss']
})
export class PrAdditionalResourcesComponent {
  obj_container:any;
  env:any;
  breadcrumbFor:string = '';
  menu_links:any;
  email_verified:boolean = false;
  ngrxAccessGroup:any;

  constructor(public router:Router,public accessgroup:AccessGroupService,public store:Store<any>) { }

  async ngOnInit() {
    console.log(this.router.url)
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.env = environment;
    var that = this;

    await this.ngrxAccessGroup.subscribe(message => { 
      console.log(message);
      if(message) {
        that.email_verified = true;
        that.menu_links = message;
      }  
      this.breadcrumbFor = 'FS Admin';

      this.obj_container = [
        {
          title:'FourSight U',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/my_teams.png`,
          active: true,
          lm_id:'',
          routerlink: `17d5d88b-e7b3-407f-a390-b54d16cdb88a`
        },{
          title:'Team Insights & Tools',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/allteammates.png`,
          active: true,
          lm_id:``,
          routerlink: `training`
        }
        ,{
          title:'Promotional Materials',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/my_teams.png`,
          active: true,
          lm_id:'',
          routerlink: `/presenter-resources/841db1c0-e266-462c-b564-10576f7a42dd`
        }
      ]
      console.log(that.obj_container)
    })
  }
}
