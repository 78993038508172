import { Component } from '@angular/core';
import { AccessGroupService } from '../../access-group.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pr-video-based-workshops',
  templateUrl: './pr-video-based-workshops.component.html',
  styleUrls: ['./pr-video-based-workshops.component.scss']
})
export class PrVideoBasedWorkshopsComponent {
  obj_container:any;
  env:any;
  breadcrumbFor:string = '';
  menu_links:any;
  email_verified:boolean = false;
  ngrxAccessGroup:any;

  constructor(public router:Router,public accessgroup:AccessGroupService,public store:Store<any>) { }

  async ngOnInit() {
    console.log(this.router.url)
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.env = environment;
    var that = this;

    await this.ngrxAccessGroup.subscribe(message => { 
      console.log(message);
      if(message) {
        that.email_verified = true;
        that.menu_links = message;
      }  
      this.breadcrumbFor = 'FS Admin';

      this.obj_container = [
        {
          title:'Mindset Reveal Presentation',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/my_teams.png`,
          active: true,
          lm_id:'',
          routerlink: `48031191-9217-4a53-8085-d206c0ebdfe9`
        },{
          title:'Mindset Reveal Presenter Guide',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/allteammates.png`,
          active: true,
          lm_id:``,
          routerlink: `training`
        }
        ,{
          title:'Team Insights & Tools',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/my_teams.png`,
          active: true,
          lm_id:'',
          routerlink: `video-based-workshops`
        }
        ,{
          title:'Challenge Sprint',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/my_teams.png`,
          active: true,
          lm_id:'',
          routerlink: `video-based-workshops`
        }
      ]
      console.log(that.obj_container)
    })
  }
}
