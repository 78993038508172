import { Component } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { TeamReportServiceService } from '../../team-report/team-report-service.service';

@Component({
  selector: 'app-myteam-graph-popup',
  templateUrl: './myteam-graph-popup.component.html',
  styleUrls: ['./myteam-graph-popup.component.scss']
})
export class MyteamGraphPopupComponent {

  file_type:string = ""
  env = environment;
  constructor(private teamReportService: TeamReportServiceService){}

  ngOnInit() {
    console.log('gppdata from popup', this.teamReportService.args)
  }

  public SelectDownloadType(type) {
    this.file_type = type;
    this.teamReportService.pdfLink = ''
  }

  public close() {
    this.teamReportService.downloadGraphPopup = false;
    this.teamReportService.downloadPreferencePopup = false;
    this.teamReportService.pdfLink = '';
    this.teamReportService.wizardThinkingProfilePopup = false; // manage wizard component
  }
}
