import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccessGroupService } from '../access-group.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-presenter-resources-dashboard',
  templateUrl: './presenter-resources-dashboard.component.html',
  styleUrls: ['./presenter-resources-dashboard.component.scss']
})
export class PresenterResourcesDashboardComponent {
  obj_container:any;
  env:any;
  breadcrumbFor:string = '';
  menu_links:any;
  email_verified:boolean = false;
  ngrxAccessGroup:any;

  constructor(public router:Router,public accessgroup:AccessGroupService,public store:Store<any>) { }

  async ngOnInit() {
    console.log(this.router.url)
    this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
    this.env = environment;
    var that = this;

    await this.ngrxAccessGroup.subscribe(message => { 
      console.log(message);
      if(message) {
        that.email_verified = true;
        that.menu_links = message;
      }  
      this.breadcrumbFor = 'FS Admin';

      this.obj_container = [
        {
          title:'Certification',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/my_teams.png`,
          active: true,
          lm_id:'',
          routerlink: `certification`
        },{
          title:'Training',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/allteammates.png`,
          active: true,
          lm_id:``,
          routerlink: `training`
        }
        ,{
          title:'Video-Based Workshops',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/my_teams.png`,
          active: true,
          lm_id:'',
          routerlink: `video-based-workshops`
        },{
          title:'Additional Resources',
          img_link:`${this.env.s3_upload}/images/dashboard_images/teams/allteammates.png`,
          active: true,
          lm_id:``,
          routerlink: `additional-resources`
        }
      ]
      console.log(that.obj_container)
    })
  }
}

