import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import {CommonDataService} from './../common-data.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../environments/environment';
import {AccessGroupService} from './../access-group.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import swal from 'sweetalert2/dist/sweetalert2.js';
import { Store } from '@ngrx/store';
import * as fsProfileActions from '../../store/action/fs.action';
// import 'sweetalert2/src/sweetalert2.scss';

declare var jQuery:any;

@Component({
  selector: 'app-associated-emails',
  templateUrl: './associated-emails.component.html',
   encapsulation: ViewEncapsulation.None,
  styleUrls: ['./associated-emails.component.scss']
})
export class AssociatedEmailsComponent implements OnInit {

	public profiles:any;
	public modal:any;
	public modal1:any;
	public modal2:any;
	public profilefirstName:string;
	public profilelastName:string;
	public profilemail:string;
	public emails:any;
    claims_pending:any;
    cemail: string = localStorage.getItem('email');
    current_mail:any;
    data:any = [];
    isLoading: boolean = false;
    sent_mail: boolean = false;
	brand_identity: any = [];
	cb_profile: any;
	menu_links;
	ngrxAccessGroup:any
	storeNgrx:any;
	collabPfName:string;
	fieldEdit= 0;
	email_validation;
  	phone_validation;
	collabPfLoader = false;
	memberId:String;
	makeAccountEmailDisabledArr = [];

  	constructor(public accessgroup:AccessGroupService, public service:CommonDataService, private modalService: NgbModal, public http: HttpClient, public router: Router, public store:Store<any>) { }

	ngOnDestroy() {
		localStorage.removeItem('goToSetting');
	}

  	ngOnInit() {
		this.storeNgrx = this.store.select<any>((state) => state.profile?.account.default_profile);
		this.ngrxAccessGroup = this.store.select<any>((state) => state.profile.accessGroupData);
		this.ngrxAccessGroup.subscribe(message => { 
			if(message && message.length > 0){           
			  this.menu_links = message;
			}
		  },
		  err => {
			  // Log errors if any
			  console.log(err);
		  })

		// data for set account
		this.http.get(`${environment.profileURL}/api/v1/profiles.json`).subscribe(res => {

			this.profiles = res['profiles']
			console.log(this.profiles)
		});

		this.storeNgrx.subscribe((message) => {
			this.memberId = message.identity.member_id;
			this.http.get(`${environment.profileURL}/api/v1/members/${this.memberId}.json`).subscribe(result => {
				this.cb_profile = result["member"]
				if(localStorage.getItem('goToSetting') == 'true') {
					this.editCollabPf();
				}
			}, err => {
				console.log(err)
			})
		})

  		console.log(typeof(localStorage.getItem('email')))
  		// this.email = JSON.stringify(localStorage.getItem('email'));
        // it re-directs url to the dashboard page if access to this page is not permitted.  
        // this.accessgroup.data().subscribe(message => { 
        //     if(message){                
        //         this.emails = message['menu_account']?.settings;
        //         if(!message['menu_account']?.settings){
        //             this.router.navigate(['/dashboard']);
        //             return false;
        //         }
        //     }

        // },
        // err => {
        //     // Log errors if any
        //     console.log(err);
        // })

        this.http.get(`${environment.profileURL}/api/v1/identity_claims.json`).subscribe(data => {
           console.log(data);
           this.isLoading = true;
			let getVerifiedEmails = data['identity_claims'].filter(item => item.confirmed_at != null);
           this.data = data['identity_claims'];
		   
           console.log(this.data);
        },
            error => console.log('oops', error)
        )


        // this.http.get(`${environment.profileURL}/api/v1/verified_emails.json`).subscribe(data => {
        //    console.log(data);
        //    this.isLoading = true;
        //    this.data = data['verified_emails'];
        //    console.log(this.data);
        // },
        //     error => console.log('oops', error)
        // )
		// co-branding details
		this.http.get(`${environment.profileURL}/api/v1/users/cobranding_identity.json`).subscribe(result => {
            console.log(result);
            this.brand_identity = result;
        },
            error => console.log('oops', error)
        )
         
  	}
  	public open(content=''){
  		if(content=''){
  			// this.modal2.close();
			  $('.email-modal').css('display','block')
  		} else if(content = 'verifyEmail') {
			$('.link-email-modal').css('display','block')
		}
  		this.profilefirstName = '';
  		this.profilelastName = '';
  		this.profilemail = '';
  		// this.modal = this.modalService.open(content, { windowClass: 'in grey' });
  	}

  	public passwordModal(){
		$('.password-modal').css('display','block');
  		// this.modal1 = this.modalService.open(content, { windowClass: 'in grey' });
  	}

  	public emailModal(){
		$('.email-modal').css('display','block')
  		// this.modal2 = this.modalService.open(content, { windowClass: 'in grey' });
  	}

  	// To change default profile
  	public updateProfile(nmbr){
  		console.log(nmbr);
		$('.email-loader').css('display','block')
  		this.http.patch(`${environment.profileURL}/api/v1/profiles/${this.profiles[nmbr].id}/mark_default.json`,this.profiles[nmbr]).subscribe(res => {
            console.log(res);
			localStorage.setItem('member_id',this.profiles[nmbr].id);
			this.store.dispatch(fsProfileActions.getProfile());
  			setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
                    progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('Your profile preference has been updated successfully!', 'Nice!');
        	}, 1000);
			$('.email-loader').css('display','none')
        }, error => console.log('oops', error))
        
  	}

  	// To add profile
  	public addProfile(){
  		var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  		if(this.profilemail.match(mailformat)){
  			this.isLoading = false;
	  		var Data = {
	  			email: this.profilemail,
	  			first_name: this.profilefirstName,
	  			last_name: this.profilelastName 
	  		}
	  		if(this.profilemail){
	  			this.http.post(`${environment.profileURL}/api/v1/identity_claims.json`,Data).subscribe(data => {
		           this.isLoading = true;
		           this.data.push(data);
		        },
		            error => console.log('oops', error)
		        )
	  			// this.data.push({
	  			// 	email:this.profilemail,
	  			// 	status:'Verification sent',
	  			// 	default:false
	  			// })
	  			// this.modal.close();
				$('.link-email-modal').css('display','none');
	  			setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
	                    progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Please check your email for confirmation!', 'Nice!');
	        	}, 1000);
			} 
		}             
  	}

  	public updateProfileMail(mail){
  		this.profiles[0].identity.email = mail;
  		this.current_mail = mail;
  		if(mail.includes('+1')){
  			this.profiles[0].default = false;
  		}else{
  			this.profiles = [{
	  			clarifier_score: "Low",
				clarifier_sum: 37,
				default: true,
				developer_score: "High",
				developer_sum: 45,
				generated_at: "2016-08-09 11:36:22",
				high_contrast: "43.5",
				id: 86454,
				ideator_score: "Neutral",
				ideator_sum: 41,
				identity: {id: 64206, member_id: 64216, email: "testowner@foursightonline.com"},
				created_at: "2017-02-05T05:25:16.000-06:00",
				email: "testowner@foursightonline.com",
				first_name: "Test",
				last_name: "Owner1",
				implementer_score: "Neutral",
				implementer_sum: 41,
				low_contrast: "38.5",
				profile_title: "Developer"
			}]
  		}
  	}

    public resendConfirm(id){
        console.log(id);
        this.http.get(`${environment.profileURL}/api/v1/identity_claims/`+id+`/resend_confirmation_mail.json`).subscribe(data => {
           console.log(data);
           setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Please check your email for confirmation!', 'Nice!');
            }, 1000);
        },
        err =>{
                console.log(err.error);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(err.error.error, 'oops!');
                }, 1000);
            }
        )
    }

    public deleteIdentity(id){
        console.log(id);
        this.http.delete(`${environment.profileURL}/api/v1/identity_claims/`+id+`.json`).subscribe(data => {
           console.log(data);
           setTimeout(function() {
                this.toastr.options = {
                    closeButton: true,
                    progressBar: true,
                    timeOut: 2000
                };
                this.toastr.success('Your Identity Claim has been deleted!', 'Nice!');
            }, 1000);
            this.http.get(`${environment.profileURL}/api/v1/identity_claims.json`).subscribe(data => {
                console.log(data);
                this.claims_pending = data['identity_claims'];
            },
                error => console.log('oops', error)
            )
        },
        err =>{
                console.log(err.error);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(err.error.error, 'oops!');
                }, 1000);
            }
        )
    }

    public makeAcctEmail(id){
    	var that = this;
		this.makeAccountEmailDisabledArr.push(id);
    	this.http.get(`${environment.profileURL}/api/v1/identities/`+id+`/make_account_email.json`).subscribe(data => {
           console.log(data);
           if(data?.['result'] == 'contact_support'){
           		Swal.fire({
			        title: "Are you sure?",
			        text: "Another user account exists with the email and manual merge accounts needs to take place for account email change!",
			        type: "warning",
			        showCancelButton: true,
			        focusCancel: true,
			        confirmButtonColor: "#dc3545",
			        cancelButtonColor: '#28a745',
			        confirmButtonText: "Contact Support!"
					}).then((result) => {
				        if (result['value'] == true) {
				        	jQuery('.swal2-container').addClass('hide');
				    		jQuery('body').removeClass('swal2-shown');
				    		jQuery('html').removeClass('swal2-shown');
				    		this.isLoading = false;
						    	this.http.get(`${environment.profileURL}/api/v1/verified_emails/`+id+`/account_email_contact_support.json`).subscribe(data => {
						           	this.isLoading = true;	
						           	console.log(data);	
						           	jQuery('.swal2-container').addClass('hide');
						            jQuery('body').removeClass('swal2-shown');
						            jQuery('html').removeClass('swal2-shown');				           
						           	setTimeout(function() {
							            this.toastr.options = {
							                closeButton: true,
							                progressBar: true,
							                timeOut: 2000
							            };
							            this.toastr.success('Our representative will get in touch with you!', 'Thanks!');
							    	}, 1000);
						        },
						            err => {
						            	this.isLoading = true;
						            	console.log('oops', err)
						        		setTimeout(function() {
								            this.toastr.options = {
								                closeButton: true,
								                progressBar: true,
								                timeOut: 2000
								            };
								            this.toastr.error(err.error.error, 'oops!');
								    	}, 1000);
						            }
						        )

				        }else{
				            jQuery('.swal2-container').addClass('hide');
				            jQuery('body').removeClass('swal2-shown');
				            jQuery('html').removeClass('swal2-shown');
				        }
					},function(dismiss){
						    		// swal({});
						// swal.clickCancel();
						jQuery('.swal2-container').addClass('hide');
						jQuery('body').removeClass('swal2-shown');
						jQuery('html').removeClass('swal2-shown');
					});
           }
           if(data?.['result'] == 'no_merge_required'){
           		Swal.fire({
			        title: "Are you sure?",
			        text: "Do you want to merge this email!",
			        type: "warning",
			        showCancelButton: true,
			        focusCancel: true,
			        confirmButtonColor: "#dc3545",
			        cancelButtonColor: '#28a745',
			        confirmButtonText: "Yes, merge the email!"
					}).then((result) => {
				        if (result['value'] == true) {
				        	jQuery('.swal2-container').addClass('hide');
				    		jQuery('body').removeClass('swal2-shown');
				    		jQuery('html').removeClass('swal2-shown');
				    		this.isLoading = false;
						    	this.http.get(`${environment.profileURL}/api/v1/verified_emails/`+id+`/make_account_email.json`).subscribe(data => {
						           	this.isLoading = true;	
						           	jQuery('.swal2-container').addClass('hide');
						            jQuery('body').removeClass('swal2-shown');
						            jQuery('html').removeClass('swal2-shown');	
						            var timeleft = 20;
						            var downloadTimer = setInterval(function(){
						            	console.log(timeleft);
									  	if(timeleft <= 0){
									    	clearInterval(downloadTimer);
									    	that.router.navigate(['/logout']);
									  	}
									  	timeleft -= 1;
									}, 1000);
						            Swal.fire({
								        title: "You will be auto logout in "+timeleft+" seconds!",
								        text: "A verification mail has been sent to your new mail. Please verify it from your inbox to proceed!",
								        type: "warning",
								        showCancelButton: false,
								        focusCancel: true,
								        confirmButtonColor: "#dc3545",
								        cancelButtonColor: '#28a745',
								        confirmButtonText: "Logout!"
										}).then((result) => {
									        if (result['value'] == true) {
									        	jQuery('.swal2-container').addClass('hide');
									    		jQuery('body').removeClass('swal2-shown');
									    		jQuery('html').removeClass('swal2-shown');
									    		that.router.navigate(['/logout']);

									        }
										},function(dismiss){
											    		// swal({});
											// swal.clickCancel();
											jQuery('.swal2-container').addClass('hide');
											jQuery('body').removeClass('swal2-shown');
											jQuery('html').removeClass('swal2-shown');
										});				           
							      //      	setTimeout(function() {
								     //        this.toastr.options = {
								     //            closeButton: true,
								     //            progressBar: true,
								     //            timeOut: 20000
								     //        };
								     //        this.toastr.success('A verification mail has been ssent to your new mail. Please verrify it from your inbox to proceed!', 'Nice!');
								    	// }, 1000);
								    	setTimeout(function() {
								            that.router.navigate(['/logout']);
								    	}, 20000);
						        },
						            err => {
						            	this.isLoading = true;
						            	console.log('oops', err)
						        		setTimeout(function() {
								            this.toastr.options = {
								                closeButton: true,
								                progressBar: true,
								                timeOut: 2000
								            };
								            this.toastr.error(err.error.error, 'oops!');
								    	}, 1000);
						            }
						        )

				        }else{
				            jQuery('.swal2-container').addClass('hide');
				            jQuery('body').removeClass('swal2-shown');
				            jQuery('html').removeClass('swal2-shown');
				        }
					},function(dismiss){
						    		// swal({});
						// swal.clickCancel();
						jQuery('.swal2-container').addClass('hide');
						jQuery('body').removeClass('swal2-shown');
						jQuery('html').removeClass('swal2-shown');
					});
           }
           if(data?.['result'] == 'mergeable'){
           		Swal.fire({
			        title: "Are you sure?",
			        text: "Another user account exists with the email!",
			        type: "warning",
			        showCancelButton: true,
			        focusCancel: true,
			        confirmButtonColor: "#dc3545",
			        cancelButtonColor: '#28a745',
			        confirmButtonText: "Yes, merge the email!"
					}).then((result) => {
				        if (result['value'] == true) {
				        	jQuery('.swal2-container').addClass('hide');
				    		jQuery('body').removeClass('swal2-shown');
				    		jQuery('html').removeClass('swal2-shown');
				    		this.isLoading = false;
						    	this.http.get(`${environment.profileURL}/api/v1/verified_emails/`+id+`/make_account_email.json`).subscribe(data => {
						           	this.isLoading = true;	
						           	jQuery('.swal2-container').addClass('hide');
						            jQuery('body').removeClass('swal2-shown');
						            jQuery('html').removeClass('swal2-shown');					           
						           	setTimeout(function() {
							            this.toastr.options = {
							                closeButton: true,
							                progressBar: true,
							                timeOut: 2000
							            };
							            this.toastr.success('Email has been merged!', 'Nice!');
							    	}, 1000);
						        },
						            err => {
						            	this.isLoading = true;
						            	console.log('oops', err)
						        		setTimeout(function() {
								            this.toastr.options = {
								                closeButton: true,
								                progressBar: true,
								                timeOut: 2000
								            };
								            this.toastr.error(err.error.error, 'oops!');
								    	}, 1000);
						            }
						        )

				        }else{
				            jQuery('.swal2-container').addClass('hide');
				            jQuery('body').removeClass('swal2-shown');
				            jQuery('html').removeClass('swal2-shown');
				        }
					},function(dismiss){
						    		// swal({});
						// swal.clickCancel();
						jQuery('.swal2-container').addClass('hide');
						jQuery('body').removeClass('swal2-shown');
						jQuery('html').removeClass('swal2-shown');
					});
		    	
           }
           if(data?.['result'] == 'already_account_email'){
           		setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
		                progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.success('Email is already account email for the user!', 'Hey!');
		    	}, 1000);
           }
		   if(data == null) {
			setTimeout(function() {
				this.toastr.success('Email updated successfully!', 'Nice!');
			}, 1000);
		   }
           
        },
        err =>{
                console.log(err.error);
                setTimeout(function() {
                    this.toastr.options = {
                        closeButton: true,
                        progressBar: true,
                        timeOut: 2000
                    };
                    this.toastr.error(err.error.error, 'oops!');
                }, 1000);
            }
        )

   //  	var random_boolean = Math.random() < 0.5;
   //  	if(random_boolean == true){

   //  		swal({
	  //       title: "Email has an account!",
	  //       text: "There is another admin account associated with this email. Do you want all results and inventory merged into an account with this email "+mail+"?",
	  //       type: "warning",
	  //       showCancelButton: true,
	  //       focusCancel: true,
	  //       confirmButtonColor: "#dc3545",
	  //       cancelButtonColor: '#28a745',
	  //       confirmButtonText: "Yes, merge my accounts"
			// }).then((result) => {
		 //        if (result['value'] == true) {
		 //        	jQuery('.swal2-container').addClass('hide');
		 //    		jQuery('body').removeClass('swal2-shown');
		 //    		jQuery('html').removeClass('swal2-shown');
		 //    		swal({
			// 	        title: "Are you sure?",
			// 	        text: "Are you sure, you want to merge your Email?",
			// 	        type: "warning",
			// 	        showCancelButton: true,
			// 	        focusCancel: true,
			// 	        confirmButtonColor: "#dc3545",
			// 	        cancelButtonColor: '#28a745',
			// 	        confirmButtonText: "Yes, change it!"
			// 			}).then((result) => {
			// 		        if (result['value'] == true) {
			// 		        	for(var i=0; i<this.data.length;i++){
			// 		    			if(mail == this.data[i].email){
			// 		    				this.data[i].default = true;
			// 		    			}else{
			// 		    				this.data[i].default = false;
			// 		    			}
			// 		    		}
			// 		        	this.profiles[0].identity.email = mail;
			// 			  		this.current_mail = mail;
			// 			  		if(mail.includes('+1')){
			// 			  			this.profiles[0].default = false;
			// 			  		}else{
			// 			  			this.profiles = [{
			// 				  			clarifier_score: "Low",
			// 							clarifier_sum: 37,
			// 							default: true,
			// 							developer_score: "High",
			// 							developer_sum: 45,
			// 							generated_at: "2016-08-09 11:36:22",
			// 							high_contrast: "43.5",
			// 							id: 86454,
			// 							ideator_score: "Neutral",
			// 							ideator_sum: 41,
			// 							identity: {id: 64206, member_id: 64216, email: "testowner@foursightonline.com"},
			// 							created_at: "2017-02-05T05:25:16.000-06:00",
			// 							email: "testowner@foursightonline.com",
			// 							first_name: "Test",
			// 							last_name: "Owner1",
			// 							implementer_score: "Neutral",
			// 							implementer_sum: 41,
			// 							low_contrast: "38.5",
			// 							profile_title: "Developer"
			// 						}]
			// 			  		}
			// 		        	jQuery('.swal2-container').addClass('hide');
			// 		    		jQuery('body').removeClass('swal2-shown');
			// 		    		jQuery('html').removeClass('swal2-shown');
			// 			      	setTimeout(function() {
			// 			            this.toastr.options = {
			// 			                closeButton: true,
			// 			                progressBar: true,
			// 			                timeOut: 2000
			// 			            };
			// 			            this.toastr.success('A request has been sent to Customer Service and you will notified when this procedure is done!', 'Nice!');
			// 			        }, 5000);

			// 		        }else{
			// 		            jQuery('.swal2-container').addClass('hide');
			// 		            jQuery('body').removeClass('swal2-shown');
			// 		            jQuery('html').removeClass('swal2-shown');
			// 		        }
			// 			},function(dismiss){
			// 				    		// swal({});
			// 				// swal.clickCancel();
			// 				jQuery('.swal2-container').addClass('hide');
			// 				jQuery('body').removeClass('swal2-shown');
			// 				jQuery('html').removeClass('swal2-shown');
			// 			});

		 //        }else{
		 //            jQuery('.swal2-container').addClass('hide');
		 //            jQuery('body').removeClass('swal2-shown');
		 //            jQuery('html').removeClass('swal2-shown');
		 //        }
			// },function(dismiss){
			// 	    		// swal({});
			// 	// swal.clickCancel();
			// 	jQuery('.swal2-container').addClass('hide');
			// 	jQuery('body').removeClass('swal2-shown');
			// 	jQuery('html').removeClass('swal2-shown');
			// });

   //  	}else{
   //  		swal({
	  //       title: "Are you sure?",
	  //       text: "Do you want all transaction emails for this account to be now sent to "+mail+"?",
	  //       type: "warning",
	  //       showCancelButton: true,
	  //       focusCancel: true,
	  //       confirmButtonColor: "#dc3545",
	  //       cancelButtonColor: '#28a745',
	  //       confirmButtonText: "Yes, change the email!"
			// }).then((result) => {
		 //        if (result['value'] == true) {
		 //        	jQuery('.swal2-container').addClass('hide');
		 //    		jQuery('body').removeClass('swal2-shown');
		 //    		jQuery('html').removeClass('swal2-shown');
		 //    		for(var i=0; i<this.data.length;i++){
		 //    			if(mail == this.data[i].email){
		 //    				this.data[i].default = true;
		 //    			}else{
		 //    				this.data[i].default = false;
		 //    			}
		 //    		}
		 //    		this.profiles[0].identity.email = mail;
			//   		this.current_mail = mail;
			//   		if(mail.includes('+1')){
			//   			this.profiles[0].default = false;
			//   		}else{
			//   			this.profiles = [{
			// 	  			clarifier_score: "Low",
			// 				clarifier_sum: 37,
			// 				default: true,
			// 				developer_score: "High",
			// 				developer_sum: 45,
			// 				generated_at: "2016-08-09 11:36:22",
			// 				high_contrast: "43.5",
			// 				id: 86454,
			// 				ideator_score: "Neutral",
			// 				ideator_sum: 41,
			// 				identity: {id: 64206, member_id: 64216, email: "testowner@foursightonline.com"},
			// 				created_at: "2017-02-05T05:25:16.000-06:00",
			// 				email: "testowner@foursightonline.com",
			// 				first_name: "Test",
			// 				last_name: "Owner1",
			// 				implementer_score: "Neutral",
			// 				implementer_sum: 41,
			// 				low_contrast: "38.5",
			// 				profile_title: "Developer"
			// 			}]
			//   		}
			//       	setTimeout(function() {
			//             this.toastr.options = {
			//                 closeButton: true,
			//                 progressBar: true,
			//                 timeOut: 2000
			//             };
			//             this.toastr.success('Your email has been changed!', 'Nice!');
			//         }, 1000);

		 //        }else{
		 //            jQuery('.swal2-container').addClass('hide');
		 //            jQuery('body').removeClass('swal2-shown');
		 //            jQuery('html').removeClass('swal2-shown');
		 //        }
			// },function(dismiss){
			// 	    		// swal({});
			// 	// swal.clickCancel();
			// 	jQuery('.swal2-container').addClass('hide');
			// 	jQuery('body').removeClass('swal2-shown');
			// 	jQuery('html').removeClass('swal2-shown');
			// });
   //  	}
    }

    public deleteMail(id){
    	Swal.fire({
	        title: "Are you sure?",
	        text: "Do you want to remove this email!",
	        type: "warning",
	        showCancelButton: true,
	        focusCancel: true,
	        confirmButtonColor: "#dc3545",
	        cancelButtonColor: '#28a745',
	        confirmButtonText: "Yes, remove the email!"
			}).then((result) => {
		        if (result['value'] == true) {
		        	jQuery('.swal2-container').addClass('hide');
		    		jQuery('body').removeClass('swal2-shown');
		    		jQuery('html').removeClass('swal2-shown');
		    		this.isLoading = false;
				    	this.http.delete(`${environment.profileURL}/api/v1/identity_claims/`+id+`.json`).subscribe(data => {
				           	this.isLoading = true;
				           	console.log(data);
				           	for(var i=0; i< this.data.length; i++){
				           		if(this.data[i].id == id){
				           			this.data.splice(i,1);
				           		}
				           	}
				           	setTimeout(function() {
					            this.toastr.options = {
					                closeButton: true,
					                progressBar: true,
					                timeOut: 2000
					            };
					            this.toastr.success('Email has been removed!', 'Nice!');
					    	}, 1000);
				        },
				            err => {
				            	this.isLoading = true;
				            	console.log('oops', err)
				        		setTimeout(function() {
						            this.toastr.options = {
						                closeButton: true,
						                progressBar: true,
						                timeOut: 2000
						            };
						            this.toastr.error(err.error.error, 'oops!');
						    	}, 1000);
				            }
				        )

		        }else{
		            jQuery('.swal2-container').addClass('hide');
		            jQuery('body').removeClass('swal2-shown');
		            jQuery('html').removeClass('swal2-shown');
		        }
			},function(dismiss){
				    		// swal({});
				// swal.clickCancel();
				jQuery('.swal2-container').addClass('hide');
				jQuery('body').removeClass('swal2-shown');
				jQuery('html').removeClass('swal2-shown');
			});
    	
    }

    public resendVerification(id){
    	this.http.get(`${environment.profileURL}/api/v1/identity_claims/`+id+`/resend_confirmation_mail.json`).subscribe(data => {
           	setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('A verification mail has been sent!', 'Nice!');
	    	}, 1000);
        },
            err => {
            	this.isLoading = true;
            	console.log('oops', err)
        		setTimeout(function() {
		            this.toastr.options = {
		                closeButton: true,
		                progressBar: true,
		                timeOut: 2000
		            };
		            this.toastr.error(err.error.error, 'oops!');
		    	}, 1000);
            }
        )
    	
    }

    public sendPswrdReset(){
    	this.sent_mail = true;
        this.http.get(`${environment.profileURL}/api/v1/users/send_reset_password_email.json`).subscribe(result => {
            console.log(result);
            // this.modal1.close();
			$('.password-modal').css('display','none');
            this.sent_mail = false;
            setTimeout(function() {
	            this.toastr.options = {
	                closeButton: true,
	                progressBar: true,
	                timeOut: 2000
	            };
	            this.toastr.success('Change Password Email sent!', 'Nice!');
	    	}, 1000);
        },
        error => {
            console.log('oops', error);
            // this.modal1.close();
			$('.password-modal').css('display','none');
            this.sent_mail = false;
        }
        )
    }

    public refreshProfiles(){

    }

	public updateRoute(routePath){
		if(routePath.includes('branding')) {
			localStorage.setItem('branding','true');		
		} else {
			localStorage.setItem('settingToCollab','true');
		}
		this.router.navigate([`${routePath}`]);
	}

	editCollabPf() {
		this.collabPfLoader = false;
		this.fieldEdit = 1
	}

	public saveCollabPf() {
		var that = this;
		console.log('entered in saveCollabPF')
		// if (
		// //   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
		// // 	this.cb_profile?.email
		// //   ) &&
		//   /^[+]\d{1}?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
		// 	this.cb_profile?.phone
		//   )
		// ) {
		  this.collabPfLoader = true;
		  let attributesObj = {
			// identities_attributes: {
				first_name: this?.cb_profile?.first_name,
				last_name: this?.cb_profile?.last_name,
				email: this?.cb_profile.default_identity.email,
				phone: this?.cb_profile?.phone,
				website: this?.cb_profile?.website,
				organization: this?.cb_profile?.organization,
				works_for_me: this?.cb_profile?.works_for_me,
				does_not_works_for_me: this?.cb_profile?.does_not_works_for_me,
			// }
		  }
		  this.http
			.put(
			  `${environment.profileURL}/api/v1/members/${this.memberId}.json`,attributesObj
			)
			.subscribe((result) => {
			  console.log(result);
			  this.fieldEdit = 0;
			  setTimeout(function () {
				this.toastr.options = {
				  closeButton: true,
				  progressBar: true,
				  timeOut: 2000,
				};
				this.toastr.success("Profile updated successfully!", "Nice!");
				this.collabPfLoader = false;
			  }, 1000);
			});
		//   this.http
		// 	.post(
		// 	  `${environment.profileURL}/api/v1/members.json?,
		// 	  {
		// 		first_name: this?.cb_profile?.branding_identity?.first_name,
		// 		last_name: this?.cb_profile?.branding_identity?.last_name,
		// 		email: this?.cb_profile?.branding_identity.email,
		// 		phone: this?.cb_profile?.branding_identity.phone,
		// 		website: this?.cb_profile?.branding_identity?.website,
		// 		organization: this?.cb_profile?.branding_identity?.organization,
		// 		works_for_me: this?.cb_profile?.branding_identity?.works_for_me,
		// 		does_not_works_for_me: this?.cb_profile?.branding_identity?.does_not_works_for_me,
		// 	  }`
		// 	)
		// 	.subscribe((result) => {
		// 	  console.log(result);
		// 	  this.fieldEdit = 0;
		// 	  setTimeout(function () {
		// 		this.toastr.options = {
		// 		  closeButton: true,
		// 		  progressBar: true,
		// 		  timeOut: 2000,
		// 		};
		// 		this.toastr.success("Profile updated successfully!", "Nice!");
		// 		this.collabPfLoader = false;
		// 	  }, 1000);
		// 	});
		// } else {
		// 	console.log(this.cb_profile)
		// 	this.collabPfLoader = false;
		//   this.validatePhone();
		//   this.validateEmail();
		// }
	}
	
	public validateEmail() {
		if (
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
			this?.cb_profile.email
			)
		) {
			this.email_validation = true;
		} else {
			this.email_validation = false;
		}
	}
	
	public validatePhone() {
		console.log("phone validation", /^[+]\d{1}?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
			this?.cb_profile?.phone))
		if (
			!(/^[+]\d{1}?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
			this?.cb_profile?.phone
			))
		) {
			this.phone_validation = true;
		} else {
			this.phone_validation = false;
		}
	}

}
